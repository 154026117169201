import React from 'react';
import * as PropTypes from 'prop-types';
import classNames from 'classnames';

import FormPreloader from '../FormPreloader';

import './Form.scss';

const Form = ({
  className,
  name,
  action,
  children,
  isPreloading,
  ...rest
}) => {
  const _className = classNames(
    'Form',
    className && className,
  );

  return (
    <form
      name={name}
      action={action}
      className={_className}
      {...rest}
    >
      {children}
      <FormPreloader isVisible={isPreloading} />
    </form>
  );
};

Form.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  action: PropTypes.string,
  children: PropTypes.any,
  isPreloading: PropTypes.bool,
};

Form.defaultProps = {
  className: '',
  action: '',
  children: null,
  isPreloading: false,
};

export { Form };
