import React from 'react';
import * as PropTypes from 'prop-types';

import {
  BasicLabel,
  BasicMultiSelect,
} from '../../basic';

import './MultiSelectField.scss';
import { isExists, isFilledString } from '../../../../utils';

const MultiSelectField = ({
  id,
  name,
  label,
  validationMessage,
  disabled,
  ...rest
}) => {
  const _isInvalid = isExists(validationMessage)
    ? isFilledString(validationMessage)
    : validationMessage;

  return (
    <div className="MultiSelectField">
      <BasicLabel
        key="label"
        htmlFor={`${id}_input`}
        disabled={disabled}
      >
        {label}
      </BasicLabel>
      <BasicMultiSelect
        id={id}
        name={name}
        disabled={disabled}
        isInvalid={_isInvalid}
        {...rest}
      />
      <BasicLabel
        key="errorMessage"
        className="MultiSelectField__error-message"
      >
        {validationMessage}
      </BasicLabel>
    </div>
  );
};

MultiSelectField.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  validationMessage: PropTypes.string,
  disabled: PropTypes.bool,
};

MultiSelectField.defaultProps = {
  label: '',
  validationMessage: null,
  disabled: false,
};

export { MultiSelectField };
