import React from 'react';
import * as PropTypes from 'prop-types';

import BasicModal from '../BasicModal'
import Form from '../../Form';
import Fieldset from '../../Fieldset'
import Legend from '../../Legend';
import Grid from '../../Grid';
import FormToolbar from '../../FormToolbar';
import { BasicButton } from '../../controls';

import styles from './ErrorModal.module.scss';

const ErrorModal = ({
  message,
  status,
  dateTime,
  isVisible,
  onClose,
}) => {
  const handleClose = () => {
    onClose && onClose();
  };

  return isVisible && (
    <BasicModal
      className={styles.ErrorModal}
      onClick={handleClose}
    >
      <Form name="errorModalForm">
        <Fieldset>
          <Legend>
            Сообщение об ошибке
          </Legend>
          <Grid
            isContainer={true}
            direction="column"
            wrapping="nowrap"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Grid
              key="message"
              className={styles.errorMessage}
              isItem={true}
              lg="12"
            >
              {message}
            </Grid>
            <Grid
              key="metadata"
              isContainer={true}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid
                key="status"
                className={styles.errorStatus}
                isItem={true}
                lg="5"
              >
                {
                  status && `Статус: ${status}`
                }
              </Grid>
              <Grid
                key="dateTime"
                className={styles.errorDateTime}
                isItem={true}
                lg="5"
              >
                {new Date(dateTime).toLocaleDateString()}
              </Grid>
            </Grid>
          </Grid>
        </Fieldset>
        <FormToolbar>
          <BasicButton
            onClick={handleClose}
          >
            Закрыть
          </BasicButton>
        </FormToolbar>
      </Form>
    </BasicModal>
  )
};

ErrorModal.propTypes = {
  isVisible: PropTypes.bool,
  errObj: PropTypes.shape({}),
  onClose: PropTypes.func,
};

ErrorModal.defaultProps = {
  isVisible: true,
  errObj: {},
  onClose: () => {},
};

export { ErrorModal };
