import React, { useState, useEffect, useMemo } from 'react';
import * as PropTypes from 'prop-types';

import CentralSection from '../../CentralSection';
import CentralSectionToolbar from '../../CentralSectionToolbar';
import CentralSectionForm from '../../CentralSectionForm';
import Fieldset from '../../Fieldset';
import Legend from '../../Legend';
import Grid from '../../Grid';
import {
  InputField,
  DateInputField,
  SelectField,
  ToolbarButton,
} from '../../controls';
import { ChangePasswordModal } from '../../modal';

import { getTimestamp, isExists } from '../../../utils';

import { USER_PROFILE } from '../../../domain/entities/entities-id';
import { focusById, getFullYearsDifference } from '../../../utils';
import { sexOptions } from '../../../consts';

import styles from './UserProfilePageContent.module.scss';

const UserProfilePageContent = ({
  pageId,
  item,
  validation,
  token,
  loadingStatus,
  data,
  isEditing,
  getEntityData,
  onEditing,
  onCancelEditing,
  onFieldChange,
  onSave,
  reloadEntity,
}) => {
  const [requests, setRequests] = useState(0);
  const [changePasswordModalIsVisible, setChangePasswordModalIsVisible] = useState(false);

  useEffect(() => {
    if (!requests || (!data && (loadingStatus === 2))) {
      reloadEntity(USER_PROFILE);
      setRequests(requests + 1);
    }
  }, [data, loadingStatus, requests, reloadEntity]);

  useEffect(() => {
    if (loadingStatus === 0) {
      getEntityData(USER_PROFILE, token);
    }
  }, [loadingStatus, getEntityData, token]);

  const fullYears = useMemo(() => {
    let result = '';
    if (item && item.dob && item.created_ts) {
      const itemDob = new Date(item.dob);
      const itemCreatedTs = new Date(item.created_ts);
      result = `${getFullYearsDifference(itemDob, itemCreatedTs)}`;
    }
    return result;
  }, [item]);

  const prepareItem = (v) => {
    return {
      ...v,
      dob: getTimestamp(v.dob)
    }
  };

  const handleFieldChange = (e) => {
    onFieldChange(pageId, e);
  };

  const buttonsProps = {
    save: {
      isVisible: item && isEditing,
      onClick: () => { onSave({ entityId: USER_PROFILE, token, pageId, item: prepareItem(item) }) },
    },
    cancel: {
      isVisible: item && isEditing,
      onClick: () => { onCancelEditing(pageId) },
    },
    edit: {
      isVisible: item && !isEditing,
      onClick: () => { onEditing(pageId, item) },
    },
  };

  const getValidationMessage = (fieldName) => {
    return validation && isExists(validation[fieldName]) ? validation[fieldName] : null
  };

  return (
    <section className={styles.UserProfilePageContent}>
      <CentralSection className={styles.centralSection}>
        <CentralSectionToolbar buttonsProps={buttonsProps} />
        <CentralSectionForm
          name="rolesItemForm"
          isVisible={Boolean(item)}
        >
          <Fieldset>
            <Legend>
              Личный кабинет
            </Legend>

            <Grid
              key="userName"
              isContainer={true}
              direction="row"
              wrapping="wrap"
              justifyContent="space-between"
              alignItems="flex-start"
            >
              <Grid
                key="lastName"
                isItem={true}
                lg="4"
              >
                <InputField
                  label="Фамилия"
                  id="last_name"
                  name="last_name"
                  type="text"
                  fullWidth={true}
                  disabled={!isEditing}
                  value={(item && item.last_name) || ''}
                  onChange={handleFieldChange}
                  onEnterDown={() => focusById('first_name')}
                  validationMessage={getValidationMessage('last_name')}
                />
              </Grid>
              <Grid
                key="firstName"
                isItem={true}
                lg="4"
              >
                <InputField
                  label="Имя"
                  id="first_name"
                  name="first_name"
                  type="text"
                  fullWidth={true}
                  disabled={!isEditing}
                  value={(item && item.first_name) || ''}
                  onChange={handleFieldChange}
                  onEnterDown={() => focusById('middle_name')}
                  validationMessage={getValidationMessage('first_name')}
                />
              </Grid>
              <Grid
                key="middleName"
                isItem={true}
                lg="4"
              >
                <InputField
                  label="Отчество"
                  id="middle_name"
                  name="middle_name"
                  type="text"
                  fullWidth={true}
                  disabled={!isEditing}
                  value={(item && item.middle_name) || ''}
                  onChange={handleFieldChange}
                  onEnterDown={() => focusById('dob')}
                  validationMessage={getValidationMessage('middle_name')}
                />
              </Grid>
            </Grid>

            <Grid
              key="dobFullYears"
              isContainer={true}
              direction="row"
              wrapping="wrap"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid
                key="dob"
                isItem={true}
                lg="4"
              >
                <DateInputField
                  id="dob"
                  name="dob"
                  label="Дата рождения"
                  value={item && item.dob}
                  fullWidth={true}
                  disabled={!isEditing}
                  onChange={handleFieldChange}
                  validationMessage={getValidationMessage('dob')}
                />
              </Grid>

              <Grid
                key="fullYears"
                isItem={true}
                lg="4"
              >
                <InputField
                  label="Полных лет"
                  id="fullYears"
                  name="fullYears"
                  type="text"
                  fullWidth={true}
                  disabled={true}
                  value={fullYears}
                  onChange={() => {}}
                />
              </Grid>

              <Grid
                key="sex"
                isItem={true}
                lg="4"
              >
                <SelectField
                  id="sex"
                  name="sex"
                  label="Пол"
                  disabled={!isEditing}
                  fullWidth={true}
                  items={sexOptions}
                  firstIsEmpty={false}
                  value={item && item.sex}
                  onChange={handleFieldChange}
                  validationMessage={getValidationMessage('sex')}
                />
              </Grid>
            </Grid>

            <Grid
              key="contacts"
              isContainer={true}
              direction="row"
              wrapping="wrap"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid
                key="phone"
                isItem={true}
                lg="6"
              >
                <InputField
                  label="Телефон"
                  id="phone"
                  name="phone"
                  type="text"
                  fullWidth={true}
                  disabled={!isEditing}
                  value={item && item.phone}
                  onChange={handleFieldChange}
                  onEnterDown={() => focusById('email')}
                  validationMessage={getValidationMessage('phone')}
                />
              </Grid>

              <Grid
                key="email"
                isItem={true}
                lg="6"
              >
                <InputField
                  label="E-mail"
                  id="email"
                  name="email"
                  type="text"
                  fullWidth={true}
                  disabled={!isEditing}
                  value={item && item.email}
                  onChange={handleFieldChange}
                  onEnterDown={() => focusById('address')}
                  validationMessage={getValidationMessage('email')}
                />
              </Grid>
            </Grid>

            <Grid
              key="address"
              isItem={true}
              lg="12"
            >
              <InputField
                label="Адрес проживания"
                id="address"
                name="address"
                type="text"
                fullWidth={true}
                disabled={!isEditing}
                value={item && item.address}
                onChange={handleFieldChange}
                validationMessage={getValidationMessage('address')}
              />
            </Grid>

            <Grid
              key="formToolbar"
              isContainer={true}
              wrapping="nowrap"
              direction="row"
              justifyContent="flex-end"
              alignItems="flex-start"
            >
              <Grid
                key="changePasswordBtn"
                isItem={true}
                lg="4"
              >
                <ToolbarButton
                  isVisible={true}
                  disabled={!isEditing}
                  style={{ width: '100%', margin: 0 }}
                  onClick={() => { setChangePasswordModalIsVisible(true); }}
                >
                  Изменить пароль
                </ToolbarButton>
              </Grid>
            </Grid>

            <Grid
              key="dates"
              isContainer={true}
              direction="row"
              wrapping="wrap"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid
                key="createdTs"
                isItem={true}
                lg="6"
              >
                <DateInputField
                  id="createdTs"
                  name="createdTs"
                  label="Дата создания"
                  value={item && item.created_ts}
                  fullWidth={true}
                  disabled={true}
                  onChange={() => {}}
                />
              </Grid>

              <Grid
                key="updatedTs"
                isItem={true}
                lg="6"
              >
                <DateInputField
                  id="updatedTs"
                  name="updatedTs"
                  label="Дата редактирования"
                  value={item && item.updated_ts}
                  fullWidth={true}
                  disabled={true}
                  onChange={() => {}}
                />
              </Grid>
            </Grid>
          </Fieldset>
        </CentralSectionForm>
      </CentralSection>
      <ChangePasswordModal
        isVisible={changePasswordModalIsVisible}
        onClose={() => setChangePasswordModalIsVisible(false)}
      />
    </section>
  )
};

UserProfilePageContent.propTypes = {
  pageId: PropTypes.string,
  item: PropTypes.shape({}),
  validation: PropTypes.shape({}),
  loadingStatus: PropTypes.number,
  data: PropTypes.shape({}),
  isEditing: PropTypes.bool,
  onEditing: PropTypes.func,
  onCancelEditing: PropTypes.func,
  onFieldChange: PropTypes.func,
  onSave: PropTypes.func,
  getEntityData: PropTypes.func,
};

UserProfilePageContent.defaultProps = {
  pageId: '',
  item: null,
  validation: {},
  loadingStatus: 2,
  isEditing: false,
  onEditing: () => {},
  onCancelEditing: () => {},
  onFieldChange: () => {},
  onSave: () => {},
};

export { UserProfilePageContent };
