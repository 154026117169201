import React from 'react';
import * as PropTypes from 'prop-types';
import classNames from 'classnames';

import './BasicTextArea.scss';
import { isFilledString } from '../../../../utils';

const BasicTextArea = ({
  className,
  name,
  disabled,
  fullWidth,
  isInvalid,
  style,
  value,
  onChange,
  ...rest
}) => {
  const handleChange = (e) => {
    onChange && onChange(e);
  };

  const _className = classNames(
    'BasicTextArea',
    className && className,
    isInvalid && 'BasicTextArea__invalid',
    (isInvalid === false) && 'BasicTextArea__valid',
    disabled && 'BasicTextArea__disabled',
  );

  let _style = style ? { ...style } : {};
  if (fullWidth) { _style.width = '100%'; }

  const _value = isFilledString(value) ? value : '';

  return (
    <textarea
      className={_className}
      style={_style}
      name={name}
      disabled={disabled}
      onChange={handleChange}
      value={_value}
      {...rest}
    />
  )
};

BasicTextArea.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  isInvalid: PropTypes.bool,
  style: PropTypes.shape({}),
  value: PropTypes.string,
  onChange: PropTypes.func,
};

BasicTextArea.defaultProps = {
  className: '',
  disabled: false,
  fullWidth: false,
  style: {},
  value: '',
  onChange: () => {},
};

export { BasicTextArea };
