import React from 'react';
import * as PropTypes from 'prop-types';

import {
  BasicLabel,
  BasicInput,
} from '../../basic'

import './InputField.scss';
import { isExists, isFilledString } from '../../../../utils';

const InputField = ({
  id,
  name,
  label,
  validationMessage,
  disabled,
  ...rest
}) => {
  const _isInvalid = isExists(validationMessage)
    ? isFilledString(validationMessage)
    : validationMessage;

  return (
    <div className="InputField">
      <BasicLabel
        key="label"
        htmlFor={id}
        disabled={disabled}
      >
        {label}
      </BasicLabel>
      <BasicInput
        id={id}
        name={name}
        disabled={disabled}
        isInvalid={_isInvalid}
        {...rest}
      />
      <BasicLabel
        key="errorMessage"
        className="InputField__error-message"
      >
        {validationMessage}
      </BasicLabel>
    </div>
  )
};

InputField.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  validationMessage: PropTypes.string,
  disabled: PropTypes.bool,
};

InputField.defaultProps = {
  label: '',
  validationMessage: null,
  disabled: false,
};

export { InputField };
