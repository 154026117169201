import {
  USERS,
  USER_PROFILE,
  ROLES,
  LOCALIZATIONS,
  DIAGNOSIS,
  CONCOMITANT_DIAGNOSIS,
  OPERATIONS,
  TREATMENT,
  CARDS,
  VISIT_TYPES,
} from './entities-id';
import { acDataLoadingSuccess } from '../../store/actions';

export default {
  [USERS]: {
    prefix: 'users',
  },

  [USER_PROFILE]: {
    prefix: 'userProfile',
    onSaveSuccess: item => acDataLoadingSuccess({ entityId: USER_PROFILE, item }),
  },

  [ROLES]: {
    prefix: 'roles',
  },

  [LOCALIZATIONS]: {
    prefix: 'localizations',
  },

  [DIAGNOSIS]: {
    prefix: 'diagnosis',
  },

  [CONCOMITANT_DIAGNOSIS]: {
    prefix: 'concomitantDiagnosis',
  },

  [OPERATIONS]: {
    prefix: 'operations',
  },

  [TREATMENT]: {
    prefix: 'treatment',
  },

  [CARDS]: {
    prefix: 'cards',
  },

  [VISIT_TYPES]: {
    prefix: 'visitTypes',
  },
};
