import { connect } from 'react-redux';

import { ErrorModal } from './ErrorModal';
import {
  errorDateTimeSelector,
  errorMessageSelector, errorModalIsVisibleSelector,
  errorStatusSelector
} from '../../../store/selectors/error-selectors';
import { acErrorShift } from '../../../store/actions';

const mapStateToProps = state => ({
  message: errorMessageSelector(state),
  status: errorStatusSelector(state),
  dateTime: errorDateTimeSelector(state),
  isVisible: errorModalIsVisibleSelector(state),
});

const mapDispatchToProps = {
  onClose: acErrorShift,
};

export default connect(mapStateToProps, mapDispatchToProps)(ErrorModal);
