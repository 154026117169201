import React from 'react';
import * as PropTypes from 'prop-types';
import { ConnectedRouter } from 'connected-react-router';
import { Switch, Route } from 'react-router-dom';

import PrivateRoute from './PrivateRoute';
import Page from '../Page';
import { PAGES } from '../../domain/pages';
import {
  ADMIN_ROUTE,
  HOME_ROUTE,
  LOGIN_ROUTE,
  PATIENTS_ROUTE,
  PATIENTS_ID_ROUTE,
  PROFILE_ROUTE,
  REGISTRATION_ROUTE,
} from '../../consts';

const getNestedRoutes = (rootRoute) => {
  return Object
    .keys(PAGES)
    .filter(route => route.indexOf(rootRoute) === 0);
};

const Router = ({ history }) => {
  return (
    <ConnectedRouter history={history}>
      <Switch>

        <Route exact path={[HOME_ROUTE, LOGIN_ROUTE]}>
          <Page />
        </Route>

        <Route exact path={REGISTRATION_ROUTE} render={() => <div>Registration page</div>} />

        <PrivateRoute exact path={[PROFILE_ROUTE, PATIENTS_ROUTE, ...getNestedRoutes(ADMIN_ROUTE)]}>
          <Page />
        </PrivateRoute>

        <PrivateRoute path={PATIENTS_ID_ROUTE}>
          <Page />
        </PrivateRoute>

        <Route render={() => <div>Error 404: Resource was not found</div>} />

      </Switch>
    </ConnectedRouter>
  )
};

Router.propTypes = {
  history: PropTypes.shape({}),
};

export { Router };
