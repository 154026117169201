import { getEntity } from '../../utils';
import {
  DIAGNOSIS,
  LOCALIZATIONS,
  USERS,
  ROLES,
  OPERATIONS,
  CARDS,
  CONCOMITANT_DIAGNOSIS,
  TREATMENT, VISIT_TYPES, USER_PROFILE,
} from '../../domain/entities/entities-id';

const entityFieldSelectorCreator = (entityId, field, defaultValue) => ({ data }) => {
  const { [field]: fieldName } = getEntity(entityId);
  return data[fieldName] || defaultValue;
};

const usersLoadingStatusSelector = (
  entityFieldSelectorCreator(USERS, 'loadingStatus', 0)
);

const usersDataSelector = (
  entityFieldSelectorCreator(USERS, 'data')
);

const rolesLoadingStatusSelector = (
  entityFieldSelectorCreator(ROLES, 'loadingStatus', 0)
);

const rolesDataSelector = (
  entityFieldSelectorCreator(ROLES, 'data')
);

const visitTypesLoadingStatusSelector = (
  entityFieldSelectorCreator(VISIT_TYPES, 'loadingStatus', 0)
);

const visitTypesDataSelector = (
  entityFieldSelectorCreator(VISIT_TYPES, 'data')
);

const localizationsLoadingStatusSelector = (
  entityFieldSelectorCreator(LOCALIZATIONS, 'loadingStatus', 0)
);

const localizationsDataSelector = (
  entityFieldSelectorCreator(LOCALIZATIONS, 'data')
);

const operationsLoadingStatusSelector = (
  entityFieldSelectorCreator(OPERATIONS, 'loadingStatus', 0)
);

const operationsDataSelector = (
  entityFieldSelectorCreator(OPERATIONS, 'data')
);

const treatmentLoadingStatusSelector = (
  entityFieldSelectorCreator(TREATMENT, 'loadingStatus', 0)
);

const treatmentDataSelector = (
  entityFieldSelectorCreator(TREATMENT, 'data')
);

const diagnosisLoadingStatusSelector = (
  entityFieldSelectorCreator(DIAGNOSIS, 'loadingStatus', 0)
);

const diagnosisDataSelector = (
  entityFieldSelectorCreator(DIAGNOSIS, 'data')
);

const concomitantDiagnosisLoadingStatusSelector = (
  entityFieldSelectorCreator(CONCOMITANT_DIAGNOSIS, 'loadingStatus', 0)
);

const concomitantDiagnosisDataSelector = (
  entityFieldSelectorCreator(CONCOMITANT_DIAGNOSIS, 'data')
);

const cardsLoadingStatusSelector = (
  entityFieldSelectorCreator(CARDS, 'loadingStatus', 0)
);

const cardsDataSelector = (
  entityFieldSelectorCreator(CARDS, 'data')
);

const userProfileLoadingStatusSelector = (
  entityFieldSelectorCreator(USER_PROFILE, 'loadingStatus', 0)
);

const userProfileDataSelector = (
  entityFieldSelectorCreator(USER_PROFILE, 'data')
);

export {
  usersLoadingStatusSelector,
  usersDataSelector,
  rolesLoadingStatusSelector,
  rolesDataSelector,
  visitTypesLoadingStatusSelector,
  visitTypesDataSelector,
  localizationsLoadingStatusSelector,
  localizationsDataSelector,
  diagnosisLoadingStatusSelector,
  diagnosisDataSelector,
  concomitantDiagnosisLoadingStatusSelector,
  concomitantDiagnosisDataSelector,
  operationsLoadingStatusSelector,
  operationsDataSelector,
  treatmentLoadingStatusSelector,
  treatmentDataSelector,
  cardsLoadingStatusSelector,
  cardsDataSelector,
  userProfileLoadingStatusSelector,
  userProfileDataSelector,
};
