const SEX = {
  MALE: 'MALE',
  FEMALE: 'FEMALE',
};

const sexOptions = [
  {
    id: SEX.MALE,
    name: 'Мужской',
  },
  {
    id: SEX.FEMALE,
    name: 'Женский',
  },
];

const ULCER_ETIOLOGY = {
  VENOUS: 'VENOUS',
  ARTERIAL: 'ARTERIAL',
  DIABETES: 'DIABETES',
  TRAUMATIC: 'TRAUMATIC',
  NEUROTROPHIC: 'NEUROTROPHIC',
};

const ulcerEtiologyOptions = [
  {
    id: ULCER_ETIOLOGY.VENOUS,
    name: 'Венозная',
    code: 1,
  },
  {
    id: ULCER_ETIOLOGY.ARTERIAL,
    name: 'Артериальная',
    code: 2,
  },
  {
    id: ULCER_ETIOLOGY.DIABETES,
    name: 'Диабетическая',
    code: 4,
  },
  {
    id: ULCER_ETIOLOGY.TRAUMATIC,
    name: 'Посттравматическая',
    code: 8,
  },
  {
    id: ULCER_ETIOLOGY.NEUROTROPHIC,
    name: 'Нейротрофическая',
    code: 16,
  },
];

export {
  SEX,
  sexOptions,
  ULCER_ETIOLOGY,
  ulcerEtiologyOptions,
};
