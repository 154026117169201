import { connect } from 'react-redux';

import { AdminPageContent } from './AdminPageContent';
import { adminItemsUiSelector } from '../../../store/selectors';

const mapStateToProps = state => ({
  items: adminItemsUiSelector(state),
});

export default connect(mapStateToProps)(AdminPageContent);
