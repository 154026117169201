import React, { useMemo, useState } from 'react';
import * as PropTypes from 'prop-types';

import Form from '../../Form'
import Fieldset from '../../Fieldset';
import Legend from '../../Legend';
import FormToolbar from '../../FormToolbar';
import Grid from '../../Grid';
import { BasicModal } from '../index';
import { BasicLabel } from '../../controls/basic';
import ToolbarButton from '../../controls/special/ToolbarButton';
import {
  NumericInputField,
  DateInputField,
  CheckboxField,
  SelectField,
} from '../../controls/complex';

import styles from './UlcerDetailsItemModal.module.scss';

const UlcerDetailsItemModal = ({
  value,
  visitTypesData,
  onClose,
  onChange,
}) => {
  const getVisitTs = v => (v && v.visit_ts ? v.visit_ts : new Date().toISOString());

  const [state, setState] = useState({ ...(value && { ...value, visit_ts: getVisitTs(value) }) });

  const handleFieldChange = ({ target }) => {
    const { name, value: fieldValue } = target;
    setState({ ...state, [name]: fieldValue });
  };

  const buttonProps = [
    {
      key: 'btnOk',
      id: 'btnOk',
      label: 'Готово',
      onClick: () => {
        onChange && onChange(state);
        onClose && onClose();
      },
      isVisible: true,
    },
    {
      key: 'btnCancel',
      id: 'btnCancel',
      label: 'Отменить',
      onClick: onClose,
      isVisible: true,
    },
  ];

  const buttons = useMemo(() => {
    return buttonProps.map((btn) => {
      const {label, ...rest} = btn;
      return (
        <ToolbarButton {...rest}>
          {label}
        </ToolbarButton>
      );
    });
  }, [buttonProps]);

  return(
    <BasicModal
      className={styles.UlcerDetailsItemModal}
      onClick={(e) => {
        e.stopPropagation();
        onClose && onClose();
      }}
    >
      <Form
        name="ulcerDetailsItemForm"
        onClick={(e) => { e.stopPropagation(); }}
      >
        <Fieldset>
          <Legend>
            Характеристика язвы при посещении
          </Legend>

          <Grid
            key="visit"
            isContainer={true}
            direction="row"
            wrapping="wrap"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Grid key="visitLabel" isItem={true} lg="12">
              <BasicLabel>Посещение</BasicLabel>
            </Grid>

            <Grid key="visit_ts" isItem={true} lg="6">
              <DateInputField
                id="visit_ts"
                name="visit_ts"
                label="Дата"
                value={state.visit_ts}
                fullWidth={true}
                disabled={false}
                onChange={handleFieldChange}
              />
            </Grid>

            <Grid key="visit_type_id" isItem={true} lg="6">
              <SelectField
                id="visit_type_id"
                name="visit_type_id"
                label="Тип визита"
                value={state.visit_type_id}
                items={visitTypesData}
                asId="id"
                asName="name"
                disabled={false}
                fullWidth={true}
                onChange={handleFieldChange}
              />
            </Grid>
          </Grid>

          <Grid
            key="ulcerDetails"
            isContainer={true}
            direction="row"
            wrapping="wrap"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Grid key="sizesLabel" isItem={true} lg="12">
              <BasicLabel>
                Размеры
              </BasicLabel>
            </Grid>

            <Grid key="sizes" isContainer={true}>
              <Grid key="length" isItem={true} lg="4">
                <NumericInputField
                  id="length"
                  name="length"
                  label="Длина (мм)"
                  value={state.length}
                  fullWidth={true}
                  disabled={false}
                  onChange={handleFieldChange}
                />
              </Grid>

              <Grid key="width" isItem={true} lg="4">
                <NumericInputField
                  id="width"
                  name="width"
                  label="Ширина (мм)"
                  value={state.width}
                  fullWidth={true}
                  disabled={false}
                  onChange={handleFieldChange}
                />
              </Grid>

              <Grid key="square" isItem={true} lg="4">
                <NumericInputField
                  id="square"
                  name="square"
                  label="Площадь (мм кв.)"
                  value={state.square}
                  fullWidth={true}
                  disabled={false}
                  onChange={handleFieldChange}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid
            key="surgery"
            isContainer={true}
            direction="row"
            wrapping="wrap"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Grid key="surgeryLabel" isItem={true} lg="12">
              <BasicLabel>Характер вмешательства</BasicLabel>
            </Grid>

            <Grid key="surgeryDetails" isContainer={true}>
              <Grid key="ablation" isItem={true} lg="6">
                <CheckboxField
                  id="ablation"
                  name="ablation"
                  label="Абляция"
                  value={state.ablation}
                  disabled={false}
                  onChange={handleFieldChange}
                  withValidationMessage={false}
                />
              </Grid>

              <Grid key="sma" isItem={true} lg="6">
                <CheckboxField
                  id="sma"
                  name="sma"
                  label="SMA"
                  value={state.sma}
                  disabled={false}
                  onChange={handleFieldChange}
                  withValidationMessage={false}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid
            key="result"
            isContainer={true}
            direction="row"
            wrapping="wrap"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Grid key="resultLabel" isItem={true} lg="12">
              <BasicLabel>
                Результат
              </BasicLabel>
            </Grid>

            <Grid key="recoverInfo" isContainer={true}>
              <Grid key="isRecovered" isItem={true} lg="6">
                <CheckboxField
                  id="is_recovered"
                  name="is_recovered"
                  label="Заживление"
                  value={state.is_recovered}
                  disabled={false}
                  onChange={handleFieldChange}
                  withValidationMessage={true}
                />
              </Grid>
            </Grid>
          </Grid>
        </Fieldset>
        <FormToolbar>
          {buttons}
        </FormToolbar>
      </Form>
    </BasicModal>
  )
};

UlcerDetailsItemModal.propTypes = {
  value: PropTypes.shape({}),
  visitTypesData: PropTypes.arrayOf(PropTypes.shape({})),
  onClose: PropTypes.func,
  onChange: PropTypes.func,
};

UlcerDetailsItemModal.defaultProps = {
  value: null,
  onClose: () => {},
  onChange: () => {},
};

export { UlcerDetailsItemModal };