import { connect } from 'react-redux';

import { AdminDiagnosisPageContent } from './AdminDiagnosisPageContent';
import { acUiSetPageState, acUiSetPageStateItem } from '../../../store/actions';
import {
  activeIdPageStateUiSelector,
  diagnosisDataSelector,
  diagnosisItemUiSelector,
  diagnosisLoadingStatusSelector,
  isEditingPageStateUiSelector,
  tokenAuthSelector,
  validationPageStateUiSelector
} from '../../../store/selectors';
import { reloadEntity, saveEntityDispatcher } from '../../../store/dispatchers';
import { getEntityData } from '../../../network';

const onItemSelect = (pageId, activeId) => {
  return acUiSetPageState({ pageId, pageState: { activeId } });
};

const onEditing = (pageId, item) => {
  return acUiSetPageState({ pageId, pageState: { isEditing: true, item } })
};

const onCancelEditing = (pageId) => {
  return acUiSetPageState({ pageId, pageState: { isEditing: false, item: null, validation: {} } });
};

const onFieldChange = (pageId, { target }) => {
  const { name, value } = target;
  return acUiSetPageStateItem({ pageId, name, value });
};

const mapStateToProps = state => ({
  loadingStatus: diagnosisLoadingStatusSelector(state),
  data: diagnosisDataSelector(state),
  item: diagnosisItemUiSelector(state),
  activeId: activeIdPageStateUiSelector(state),
  validation: validationPageStateUiSelector(state),
  token: tokenAuthSelector(state),
  isEditing: isEditingPageStateUiSelector(state),
});

const mapDispatchToProps = {
  getEntityData,
  onItemSelect,
  onEditing,
  onCancelEditing,
  onFieldChange,
  onSave: saveEntityDispatcher,
  reloadEntity,
};

export default connect(mapStateToProps,mapDispatchToProps)(AdminDiagnosisPageContent);