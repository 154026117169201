import React from 'react';
import * as PropTypes from 'prop-types';
import classNames from 'classnames';

import './FormToolbar.scss';

const FormToolbar = ({
  className,
  children,
}) => {
  const _className = classNames(
    'FormToolbar',
    className && className,
  );

  return (
    <div className={_className}>
      {children}
    </div>
  )
};

FormToolbar.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
};

FormToolbar.defaultProps = {
  className: '',
  children: null,
};

export { FormToolbar };
