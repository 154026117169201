import React from 'react';
import * as PropTypes from 'prop-types';
import classNames from 'classnames';

import './BasicIconButton.scss';

const BasicIconButton = ({
  icon: Icon,
  className,
  disabled,
  iconProps,
  type,
  onClick,
}) => {
  const _className = classNames(
    'BasicIconButton',
    className && className,
    disabled && 'BasicIconButton__disabled',
  );

  const _iconProps = iconProps
    ? { ...BasicIconButton.defaultProps.iconProps, ...iconProps }
    : { ...BasicIconButton.defaultProps.iconProps };

  return(
    <button
      className={_className}
      type={type}
      onClick={onClick}
    >
      { Icon && <Icon {..._iconProps} /> }
    </button>
  )
};

BasicIconButton.propTypes = {
  icon: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  iconProps: PropTypes.shape({}),
  type: PropTypes.string,
  onClick: PropTypes.func,
};

BasicIconButton.defaultProps = {
  icon: null,
  className: '',
  disabled: false,
  iconProps: {},
  type: 'button',
  onClick: () => {},
};

export { BasicIconButton };
