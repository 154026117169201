import React from 'react';
import * as PropTypes from 'prop-types';

const LessThanIcon = ({
  width,
  height,
  stroke,
  ...rest
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      preserveAspectRatio="xMidYMid meet"
      {...rest}
    >
      <path
        d="M10 3 L4 8 L10 13"
        fill="none"
        stroke={stroke}
        strokeWidth={1.5}
      />
    </svg>
  )
};

LessThanIcon.propTypes = {
  width: PropTypes.oneOfType([
    PropTypes.number, PropTypes.string,
  ]),
  height: PropTypes.oneOfType([
    PropTypes.number, PropTypes.string,
  ]),
  stroke: PropTypes.oneOfType([
    PropTypes.number, PropTypes.string,
  ]),
};

LessThanIcon.defaultProps = {
  width: '100%',
  height: '100%',
  stroke: 'rgba(0, 0, 0, 1)',
};

export default LessThanIcon;
