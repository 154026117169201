import React from 'react';
import * as PropTypes from 'prop-types';

const CrossIcon = ({
  width,
  height,
  stroke,
  hasCircle,
  circleStroke,
  circleFill,
  ...rest
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      preserveAspectRatio="xMidYMid meet"
      {...rest}
    >
      <g>
        {
          hasCircle && (
            <circle
              cx="8" cy="8" r="7"
              stroke={circleStroke}
              fill={circleFill}
            />
          )
        }
        <path
          d="M8 4 V12 M4 8 H12"
          fill="none"
          stroke={stroke}
          strokeWidth={1.5}
        />
      </g>

    </svg>
  )
};

CrossIcon.propTypes = {
  width: PropTypes.oneOfType([
    PropTypes.number, PropTypes.string,
  ]),
  height: PropTypes.oneOfType([
    PropTypes.number, PropTypes.string,
  ]),
  stroke: PropTypes.oneOfType([
    PropTypes.number, PropTypes.string,
  ]),
  hasCircle: PropTypes.bool,
  circleStroke: PropTypes.string,
  circleFill: PropTypes.string,
};

CrossIcon.defaultProps = {
  width: '100%',
  height: '100%',
  stroke: 'rgba(0, 0, 0, 1)',
  hasCircle: true,
  circleStroke: 'rgba(255, 255, 255, 1)',
  circleFill: 'rgba(255, 255, 255, 1)',
};

export default CrossIcon;
