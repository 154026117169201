import React, { PureComponent } from 'react';
import * as PropTypes from 'prop-types';
import classNames from 'classnames';
import get from 'lodash/get';

import { BasicList, BasicListItem, BasicInput } from '../../basic';
import { LessThanIcon } from '../../../icons';

import { isExists, isFilledArray } from '../../../../utils';

import styles from './AsideList.module.scss';

class AsideList extends PureComponent {
  static propTypes = {
    pageId: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.number, PropTypes.string,
    ]),
    items: PropTypes.arrayOf(PropTypes.shape({})),
    empty: PropTypes.oneOfType([
      PropTypes.string, PropTypes.node,
    ]),
    filter: PropTypes.shape({
      name: PropTypes.string,
    }),
    onFilterChange: PropTypes.func,
    onSelect: PropTypes.func,
  };

  static defaultProps = {
    value: -1,
    items: null,
    empty: 'Нет данных для отображения',
    filter: {},
    onSelect: () => {},
    onFilterChange: () => {},
  };

  constructor(props) {
    super(props);
    this.state = {
      opened: [],
    };
    this.list = null;
    this.selected = null;
  }

  componentDidMount() {
    this.updateStyles();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.updateStyles();
  }

  updateStyles = () => {
    if (this.selected && this.list) {
      const { scrollTop, offsetHeight } = this.list;
      const { offsetTop: selTop, offsetHeight: selHeight } = this.selected;
      const listBottom = scrollTop + offsetHeight;
      const selBottom = selTop + selHeight;

      if ((selBottom > listBottom) || (selTop < scrollTop)) {
        this.list.scrollTop = selTop - offsetHeight / 2;
      }
    }
  };

  handleFilterChange = (e) => {
    const { onFilterChange, filter, pageId } = this.props;
    const { value, name } = e.target;
    onFilterChange && onFilterChange(pageId, { ...filter, [name]: value });
  };

  filterItems = (items, filter) => {
    return isFilledArray(items)
      ? items.filter(item => {
        return !filter.name || (item.name.toLowerCase().indexOf(filter.name.toLowerCase()) > -1);
      })
      : items;
  };

  getItemsObj = (itemsList) => {
    let result = null;
    if (isFilledArray(itemsList)) {
      itemsList.forEach((item) => {
        const { parent_id } = item;
        if (isExists(parent_id)) {
          result = result || {};
          if (isFilledArray(result[parent_id])) {
            result[parent_id].push({ ...item });
          } else {
            result[parent_id] = [{ ...item }];
          }
        }
      });
    }
    return result;
  };

  getOpenedList = (itemsList, parentId, opened, itemsObj, listLevel = 0) => {
    let result;
    if (isFilledArray(itemsList)) {
      result = [];
      itemsList.forEach((item) => {
        const { id: itemId, parent_id } = item;
        if (parentId === parent_id) {
          const hasChildren = isFilledArray(itemsObj[itemId]);
          result.push({ ...item, ...(hasChildren && { isGroup: true }), listLevel});
          if (hasChildren && opened.includes(itemId)) {
            const children = this.getOpenedList(itemsList, itemId, opened, itemsObj, listLevel + 1);
            if (isFilledArray(children)) {
              result = result.concat(children);
            }
          }
        }
      });
    }
    return result;
  };

  getItemsList = ({ items, value, empty, disabled, onSelect, opened, filter }) => {
    const filteredItems = this.filterItems(items, filter);
    const itemsObj = this.getItemsObj(filteredItems);
    let _items;
    if (itemsObj) {
      _items = this.getOpenedList(filteredItems, 0, opened, itemsObj);
    } else {
      _items = isFilledArray(filteredItems) ? filteredItems.slice() : [];
    }

    return isFilledArray(_items)
      ? _items.map((item) => {
        const { id: itemId, name, isGroup, listLevel = 0, disabled: itemDisabled } = item;
        const isSelected = itemId === value;
        const isOpened = opened.includes(itemId);
        const Icon = !isGroup
          ? null
          : isOpened
            ? LessThanIcon
            : LessThanIcon;
        return (
          <BasicListItem
            key={itemId}
            className={classNames(
              styles.listItem,
              isGroup && styles.groupItem,
              isOpened && styles.listItemOpened,
              itemDisabled && styles.itemInactive,
            )}
            style={{ paddingLeft: 16 + listLevel * 16 }}
            disabled={disabled}
            isButton={true}
            isSelected={isSelected}
            onSelect={() => onSelect(itemId)}
            getRef={(elm) => {
              if (isSelected) { this.selected = elm; }
            }}
          >
            {name}
            {
              Icon && (
                <span className={classNames(
                  styles.itemIconBox,
                  isOpened && styles.itemIconBoxOpened
                )}>
                  <Icon
                    onClick={disabled ? null : () => {
                      const newOpened = isOpened
                        ? opened.filter(openedId => openedId !== itemId)
                        : [...opened, itemId];
                      const { scrollTop } = this.list;
                      this.setState({ opened: newOpened }, () => { this.list.scrollTop = scrollTop });
                    }}
                    stroke={isSelected ? 'rgba(255, 255, 255, 1)' : 'rgba(31, 31, 31, 1)'}
                  />
                </span>
              )
            }
          </BasicListItem>
        );
      })
      : (
        <BasicListItem>
          {empty}
        </BasicListItem>
      );
  };

  render() {
    const {
      value,
      items,
      empty,
      disabled,
      onSelect,
      filter,
      ...rest
    } = this.props;

    const filterName = get(filter, 'name', '');

    const { opened } = this.state;

    const itemsList = this.getItemsList({ items, value, empty, disabled, onSelect, opened, filter });

    return (
      <div className={styles.AsideList}>
        <div key="filter" className={styles.filterContainer}>
          <BasicInput
            name="name"
            disabled={disabled}
            fullWidth={true}
            value={filterName}
            onChange={this.handleFilterChange}
          />
        </div>
        <div key="list" className={styles.listContainer}>
          <BasicList
            className={styles.list}
            getRef={(elm) => { this.list = elm; }}
            {...rest}
          >
            {
              itemsList
            }
          </BasicList>
        </div>
      </div>
    );
  }
}

export { AsideList };
