import React from 'react';
import * as PropTypes from 'prop-types';

const MenuIcon = ({
  width,
  height,
  stroke,
  ...rest
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      preserveAspectRatio="xMidYMid meet"
      {...rest}
    >
      <path
        d="M1 2 H15 M1 6 H15 M 1 10 H15 M1 14 H15"
        fill="none"
        stroke={stroke}
        strokeWidth={1.5}
      />
    </svg>
  )
};

MenuIcon.propTypes = {
  width: PropTypes.oneOfType([
    PropTypes.number, PropTypes.string,
  ]),
  height: PropTypes.oneOfType([
    PropTypes.number, PropTypes.string,
  ]),
  stroke: PropTypes.oneOfType([
    PropTypes.number, PropTypes.string,
  ]),
};

MenuIcon.defaultProps = {
  width: '100%',
  height: '100%',
  stroke: 'rgba(0, 0, 0, 1)',
};

export default MenuIcon;
