import { get } from 'lodash';
import NetworkError from './NetworkError';
import { acAuthLogout, acErrorAdd } from '../store/actions';

export default function({
  token,
  url,
  method = 'GET',
  headers = {},
  body = null,
  cbError,
  cbSuccess,
}) {
  const _headers = headers ? headers : {};

  if (token) {
    _headers['auth-token'] = token;
  }

  if (_headers['content-type'] === null) {
    delete _headers['content-type'];
  } else if (_headers['content-type'] === undefined) {
    _headers['content-type'] = 'application/json';
  }

  const _body = body
    && (_headers['content-type'] === 'application/json')
    && (typeof body !== 'string')
    ? JSON.stringify(body)
    : body;

  let status;
  let ok;

  const fetchError = (err) => {
    cbError && cbError(err);
  };

  const fetchSuccess = (loadedData) => {
    cbSuccess
      ? cbSuccess(loadedData)
      : console.log(loadedData);
  };

  return (dispatch) => {
    return fetch(
      url,
      {
        method,
        headers: _headers,
        body: _body,
      }
    )
      .then((response) => {
        ({ status, ok } = response);
        const contentType = response.headers.get('content-type');
        if (contentType.indexOf('json') > -1) {
          return response.json();
        } else if (contentType.indexOf('text') > -1) {
          return response.text();
        } else {
          return Promise.resolve(response)
        }
      })
      .then((loadedData) => {
        if (!ok) {
          const message = get(loadedData, 'message');
          const status = get(loadedData, 'status');
          const dateTime = get(loadedData, 'dateTime');
          throw new NetworkError(message, status, dateTime);
        }
        fetchSuccess(loadedData);
      })
      .catch((err) => {
        let _err = err;
        const isNetworkError = err instanceof(NetworkError);
        if (!isNetworkError) {
          const message = err && err.message
            ? _err.message
            : 'Network connection error';
          _err = new NetworkError(message);
        }
        dispatch(acErrorAdd({ error: _err }));
        if (status === 401) {
          dispatch(acAuthLogout());
        }
        fetchError(_err);
      });
  };
}
