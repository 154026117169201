import { useCallback } from 'react';
import { isFilledArray } from '../utils';

export default (data, item) => useCallback(() => {
  const emptyOption = { id: 0, name: 'Не выбрано' };
  let result = [emptyOption];
  if (isFilledArray(data)) {
    const itemId = item && item.id;
    result = result.concat(data.filter((option) => option.id !== itemId));
  }

  return result;
}, [data, item])();
