import React from 'react';
import * as PropTypes from 'prop-types';
import classNames from 'classnames';

import './BasicList.scss';

const BasicList = ({
  className,
  children,
  onWheelEnd,
  getRef,
}) => {
  const handleWheel = ({ deltaY, target }) => {
    const { scrollTop, scrollHeight, offsetHeight } = target.parentElement;
    const scrollBottom = scrollHeight - scrollTop - offsetHeight;
    if ((deltaY < 0 && scrollTop === 0)
      || (deltaY > 0 && scrollBottom === 0)) {
      onWheelEnd && onWheelEnd(deltaY);
    }
  };

  const _className = classNames(
    'BasicList',
    className && className,
  );

  return (
    <ul
      className={_className}
      ref={getRef}
      onWheel={handleWheel}
    >
      {children}
    </ul>
  )
};

BasicList.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  getRef: PropTypes.func,
  onWheelEnd: PropTypes.func,
};

BasicList.defaultProps = {
  className: '',
  children: null,
  getRef: () => {},
  onWheelEnd: () => {},
};

export { BasicList };
