export const ADMIN_ROUTE = '/admin';

export const ADMIN_USERS_ROUTE = '/admin/users';

export const ADMIN_ROLES_ROUTE = '/admin/roles';

export const ADMIN_LOCALIZATION_ROUTE = '/admin/localization';

export const ADMIN_DIAGNOSIS_ROUTE = '/admin/diagnosis';

export const ADMIN_CONCOMITANT_DIAGNOSIS_ROUTE = '/admin/concomitant-diagnosis';

export const ADMIN_OPERATIONS_ROUTE = '/admin/operations';

export const ADMIN_TREATMENT_ROUTE = '/admin/treatment';

export const ADMIN_CARDS_ROUTE = '/admin/cards';

export const ADMIN_VISIT_TYPES_ROUTE = '/admin/visit-types';

export const PATIENTS_ROUTE = '/patients';

export const PATIENTS_ID_ROUTE = '/patients/:id';

export const PROFILE_ROUTE = '/profile';

export const LOGIN_ROUTE = '/login';

export const REGISTRATION_ROUTE = '/registration';

export const HOME_ROUTE = '/';
