import { createAction } from 'redux-actions';
import {
  AC_DATA_SHOULD_BE_RELOADED,
  AC_DATA_LOADING_ERROR,
  AC_DATA_LOADING_START,
  AC_DATA_LOADING_SUCCESS,
  AC_DATA_UPSERT_ITEM,
} from './action-types';

export const acDataShouldBeReloaded = createAction(AC_DATA_SHOULD_BE_RELOADED);

export const acDataLoadingStart = createAction(AC_DATA_LOADING_START);

export const acDataLoadingError = createAction(AC_DATA_LOADING_ERROR);

export const acDataLoadingSuccess = createAction(AC_DATA_LOADING_SUCCESS);

export const acDataUpsertItem = createAction(AC_DATA_UPSERT_ITEM);
