import React from 'react';
import * as PropTypes from 'prop-types';
import classNames from 'classnames';

import { Switch, Route } from 'react-router-dom';
import PrivateRoute from '../../Router/PrivateRoute';
import {
  LoginPageContent,
  AdminPageContent,
  AdminUsersPageContent,
  AdminRolesPageContent,
  AdminLocalizationPageContent,
  AdminDiagnosisPageContent,
  AdminConcomitantDiagnosisPageContent,
  AdminOperationsPageContent,
  AdminTreatmentPageContent,
  AdminCardsPageContent,
  AdminVisitTypesPageContent,
  UserProfilePageContent,
} from '../../pageContent';

import styles from './PageContent.module.scss';

const PageContent = ({
  isNavBarOpen,
  pageState,
}) => {
  const _className = classNames(
    styles.PageContent,
    isNavBarOpen && styles.withNavBar,
  );

  const _pageState = pageState || {};

  return (
    <div className={_className}>
      <Switch>
        <Route path="/login">
          <LoginPageContent {..._pageState} />
        </Route>

        <PrivateRoute path="/profile" exact>
          <UserProfilePageContent {..._pageState} />
        </PrivateRoute>

        <PrivateRoute path="/admin" exact>
          <AdminPageContent {..._pageState} />
        </PrivateRoute>

        <PrivateRoute path="/admin/users" exact>
          <AdminUsersPageContent {..._pageState} />
        </PrivateRoute>

        <PrivateRoute path="/admin/roles" exact>
          <AdminRolesPageContent {..._pageState} />
        </PrivateRoute>

        <PrivateRoute path="/admin/localization" exact>
          <AdminLocalizationPageContent {..._pageState} />
        </PrivateRoute>

        <PrivateRoute path="/admin/diagnosis" exact>
          <AdminDiagnosisPageContent {..._pageState} />
        </PrivateRoute>

        <PrivateRoute path="/admin/concomitant-diagnosis" exact>
          <AdminConcomitantDiagnosisPageContent {..._pageState} />
        </PrivateRoute>

        <PrivateRoute path="/admin/operations" exact>
          <AdminOperationsPageContent {..._pageState} />
        </PrivateRoute>

        <PrivateRoute path="/admin/treatment" exact>
          <AdminTreatmentPageContent {..._pageState} />
        </PrivateRoute>

        <PrivateRoute path="/admin/visit-types" exact>
          <AdminVisitTypesPageContent {..._pageState} />
        </PrivateRoute>

        <PrivateRoute path="/admin/cards" exact>
          <AdminCardsPageContent {..._pageState} />
        </PrivateRoute>
      </Switch>
    </div>
  )
};

PageContent.propTypes = {
  isNavBarOpen: PropTypes.bool,
  pageState: PropTypes.shape({}),
};

PageContent.defaultProps = {
  isNavBarOpen: false,
  pageState: null,
};

export { PageContent };
