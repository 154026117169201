import { createSelector } from 'reselect';
import { isFilledArray } from '../../utils';

const errorsSelector = ({ errors }) => errors.errors || [];

const lastErrorSelector = createSelector(
  [errorsSelector],
  (errors) => {
    return isFilledArray(errors) ? errors[0] : null;
  },
);

const errorModalIsVisibleSelector = createSelector(
  [lastErrorSelector],
  (error) => {
    return Boolean(error);
  },
);

const errorFieldSelectorCreator = (name, defaultValue, formatter) => createSelector(
  [lastErrorSelector],
  (error) => {
    const result =  (error && error[name]) || defaultValue;
    return formatter ? formatter(result) : result;
  },
);

const dateTimeFormatter = (v) => {
  const date = new Date(v);
  const dStr = date.toLocaleDateString();
  const tStr = date.toLocaleTimeString();
  return `${dStr} ${tStr}`;
};

const errorMessageSelector = errorFieldSelectorCreator('message', 'Неизвестная ошибка');

const errorStatusSelector = errorFieldSelectorCreator('status');

const errorDateTimeSelector = errorFieldSelectorCreator('dateTime', new Date().getTime(), dateTimeFormatter);

export {
  errorMessageSelector,
  errorStatusSelector,
  errorDateTimeSelector,
  errorModalIsVisibleSelector,
};
