import { handleActions } from 'redux-actions';
import { acAppReset, acErrorAdd, acErrorShift } from '../actions';
import { isFilledArray } from '../../utils';

const initialState = {
  errors: [],
};

export default handleActions({
  [acErrorAdd]: (state, { payload }) => {
    let errors = [payload.error, ...state.errors];
    return {
      ...state,
      errors,
    };
  },

  [acErrorShift]: (state) => {
    let errors = state.errors;
    return {
      ...state,
      errors: isFilledArray(errors)
        ? errors.slice(1)
        : errors,
    };
  },

  [acAppReset]: () => ({
    ...initialState,
  }),
}, { ...initialState });
