import PRIVILEGES_ID from './privileges-id';

import PRIVILEGES_CODES from './privileges-codes';

const {
  USERS,
  ROLES,
  LOCALIZATIONS,
  DIAGNOSIS,
  CONCOMITANT_DIAGNOSIS,
  OPERATIONS,
  TREATMENT,
  CARDS,
  VISIT_TYPES,
  // PATIENTS,
  // DOCTORS, // surgeons
  ACCOUNT,
} = PRIVILEGES_ID;

const {
  READ,
  UPDATE,
  CREATE,
  DELETE,
  BLOCK,
  ACTIVATE,
  RESET,
  CHANGE_ACCOUNT_PASSWORD,
} = PRIVILEGES_CODES;

const read = {
  label: 'Чтение',
  code: READ,
};

const update = {
  label: 'Редактирование',
  code: UPDATE,
};

const create = {
  label: 'Создание',
  code: CREATE,
};

const deleteItem = {
  label: 'Удаление',
  code: DELETE,
};

const block = {
  label: 'Блокирование',
  code: BLOCK,
};

const activate = {
  label: 'Активация',
  code: ACTIVATE,
};

const reset = {
  label: 'Сброс пароля',
  code: RESET,
};

const changeAccountPassword = {
  label: 'Смена пароля аккаунта',
  code: CHANGE_ACCOUNT_PASSWORD,
}

export {
  PRIVILEGES_ID,
  PRIVILEGES_CODES,
};

export default [
  {
    id: ACCOUNT,
    label: 'Аккаунт',
    items: [
      read,
      update,
      changeAccountPassword,
    ],
  },
  {
    id: USERS,
    label: 'Пользователи',
    items: [
      read,
      update,
      create,
      deleteItem,
      block,
      activate,
      reset,
    ],
  },
  {
    id: ROLES,
    label: 'Роли пользователей',
    items: [
      read,
      update,
      create,
      deleteItem,
    ],
  },
  {
    id: LOCALIZATIONS,
    label: 'Локализация',
    items: [
      read,
      update,
      create,
      deleteItem,
    ],
  },
  {
    id: DIAGNOSIS,
    label: 'Диагнозы',
    items: [
      read,
      update,
      create,
      deleteItem,
    ],
  },
  {
    id: CONCOMITANT_DIAGNOSIS,
    label: 'Сопутствующие диагнозы',
    items: [
      read,
      update,
      create,
      deleteItem,
    ],
  },
  {
    id: OPERATIONS,
    label: 'Операции',
    items: [
      read,
      update,
      create,
      deleteItem,
    ],
  },
  {
    id: TREATMENT,
    label: 'Терапия',
    items: [
      read,
      update,
      create,
      deleteItem,
    ],
  },
  {
    id: CARDS,
    label: 'Карты пациентов',
    items: [
      read,
      update,
      create,
      deleteItem,
    ],
  },
  {
    id: VISIT_TYPES,
    label: 'Тип визита',
    items: [
      read,
      update,
      create,
      deleteItem,
    ],
  },
];
