import React, { PureComponent } from 'react';
import ReactDom from 'react-dom';
import * as PropTypes from 'prop-types';
import classNames from 'classnames';

import './BasicModal.scss';

const modalRoot = document.getElementById('modalRoot');

class BasicModal extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.any,
    onClick: PropTypes.func,
  };

  static defaultProps = {
    className: '',
    children: null,
    onClick: () => {},
  };

  constructor(props) {
    super(props);
    this.el = document.createElement('div');
    modalRoot.appendChild(this.el);
  }

  componentWillUnmount() {
    modalRoot.removeChild(this.el);
  }

  handleClick = (e) => {
    const { onClick } = this.props;
    e.stopPropagation();
    onClick && onClick(e);
  };

  render() {
    const {
      className,
      children,
      ...rest
    } = this.props;

    const _className = classNames(
      'BasicModal',
      className && className,
    );

    return ReactDom.createPortal(
      <div
        className={_className}
        onClick={this.handleClick}
        {...rest}
      >
        {children}
      </div>,
      this.el,
    );
  }
}

export { BasicModal };
