import React, { useState, useEffect } from 'react';
import * as PropTypes from 'prop-types';

import Aside from '../../Aside';
import CentralSection from '../../CentralSection';
import CentralSectionToolbar from '../../CentralSectionToolbar';
import CentralSectionForm from '../../CentralSectionForm';
import Fieldset from '../../Fieldset';
import Legend from '../../Legend';
import Grid from '../../Grid';
import {
  AsideList,
  InputField,
  DateInputField,
  Privileges,
  CheckboxField,
} from '../../controls';

import { isExists } from '../../../utils';

import styles from './AdminRolesPageContent.module.scss';
import { ROLES } from '../../../domain/entities/entities-id';

const AdminRolesPageContent = ({
  pageId,
  activeId,
  item,
  validation,
  token,
  loadingStatus,
  data,
  isEditing,
  getEntityData,
  onItemSelect,
  onEditing,
  onCancelEditing,
  onFieldChange,
  onSave,
  reloadEntity,
}) => {
  const [requests, setRequests] = useState(0);

  useEffect(() => {
    if (!requests || (!data && (loadingStatus === 2))) {
      reloadEntity(ROLES);
      setRequests(requests + 1);
    }
  }, [data, loadingStatus, requests, reloadEntity]);

  useEffect(() => {
    if (loadingStatus === 0) {
      getEntityData(ROLES, token);
    }
  }, [loadingStatus, getEntityData, token]);

  const handleItemSelect = pageId => id => onItemSelect(pageId, id);

  const handleFieldChange = (e) => {
    onFieldChange(pageId, e);
  };

  const prepareItem = (sourceItem) => {
    return sourceItem
      ? { ...sourceItem, superadmin: sourceItem.superadmin ? 1 : 0 }
      : {};
  };

  const buttonsProps = {
    create: {
      isVisible: !isEditing,
      onClick: () => { onEditing(pageId, {}) },
    },
    save: {
      isVisible: item && isEditing,
      onClick: () => { onSave({ entityId: ROLES, token, pageId, item: prepareItem(item) }) },
    },
    cancel: {
      isVisible: item && isEditing,
      onClick: () => { onCancelEditing(pageId) },
    },
    edit: {
      isVisible: item && !isEditing,
      onClick: () => { onEditing(pageId, item) },
    },
  };

  const getValidationMessage = (fieldName) => {
    return validation && isExists(validation[fieldName]) ? validation[fieldName] : null
  };

  return (
    <section className={styles.AdminRolesPageContent}>
      <Aside>
        <AsideList
          pageId={pageId}
          items={data}
          value={activeId}
          disabled={isEditing}
          onSelect={handleItemSelect(pageId)}
        />
      </Aside>
      <CentralSection>
        <CentralSectionToolbar buttonsProps={buttonsProps} />
        <CentralSectionForm
          name="rolesItemForm"
          isVisible={Boolean(item)}
        >
          <Fieldset>
            <Legend>
              Роли пользователей
            </Legend>

            <Grid
              key="nameSuperadmin"
              isContainer={true}
              direction="row"
              wrapping="nowrap"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid
                key="name"
                isItem={true}
                lg="9"
              >
                <InputField
                  label="Название"
                  id="name"
                  name="name"
                  type="text"
                  fullWidth={true}
                  disabled={!isEditing}
                  value={(item && item.name) || ''}
                  onChange={handleFieldChange}
                  validationMessage={getValidationMessage('name')}
                />
              </Grid>

              <Grid
                key="superadmin"
                isItem={true}
                lg="3"
              >
                <CheckboxField
                  label="Суперадмин"
                  id="superadmin"
                  name="superadmin"
                  disabled={!isEditing}
                  value={Boolean(item && item.superadmin)}
                  validationMessage={getValidationMessage('superadmin')}
                  onChange={handleFieldChange}
                />
              </Grid>
            </Grid>



            <Grid
              key="privileges"
              isItem={true}
              lg="12"
            >
              <Privileges
                id="privileges"
                name="privileges"
                label="Привилегии пользователя"
                disabled={!isEditing}
                value={item && item.privileges}
                onChange={handleFieldChange}
              />
            </Grid>

            <Grid
              key="dates"
              isContainer={true}
              direction="row"
              wrapping="wrap"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid
                key="createdTs"
                isItem={true}
                lg="6"
              >
                <DateInputField
                  id="createdTs"
                  name="createdTs"
                  label="Дата создания"
                  value={item && item.created_ts}
                  fullWidth={true}
                  disabled={true}
                  onChange={() => {}}
                />
              </Grid>

              <Grid
                key="updatedTs"
                isItem={true}
                lg="6"
              >
                <DateInputField
                  id="updatedTs"
                  name="updatedTs"
                  label="Дата редактирования"
                  value={item && item.updated_ts}
                  fullWidth={true}
                  disabled={true}
                  onChange={() => {}}
                />
              </Grid>
            </Grid>
          </Fieldset>
        </CentralSectionForm>
      </CentralSection>
    </section>
  )
};

AdminRolesPageContent.propTypes = {
  pageId: PropTypes.string,
  activeId: PropTypes.number,
  item: PropTypes.shape({}),
  validation: PropTypes.shape({}),
  loadingStatus: PropTypes.number,
  data: PropTypes.arrayOf(PropTypes.shape({})),
  isEditing: PropTypes.bool,
  onItemSelect: PropTypes.func,
  onEditing: PropTypes.func,
  onCancelEditing: PropTypes.func,
  onFieldChange: PropTypes.func,
  onSave: PropTypes.func,
};

AdminRolesPageContent.defaultProps = {
  pageId: '',
  activeId: -1,
  item: null,
  validation: {},
  loadingStatus: 2,
  isEditing: false,
  onItemSelect: () => {},
  onEditing: () => {},
  onCancelEditing: () => {},
  onFieldChange: () => {},
  onSave: () => {},
};

export { AdminRolesPageContent };
