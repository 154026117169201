import React, { PureComponent } from 'react';
import * as PropTypes from 'prop-types';
import classNames from 'classnames';

import {
  BasicLabel,
  BasicList,
} from '../../basic';

import { PlatesListItem } from '../index';

import './PlatesList.scss';
import { isFilledArray, isFilledString } from '../../../../utils';

class PlatesList extends PureComponent {
  static propTypes = {
    asId: PropTypes.string,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    fullWidth: PropTypes.bool,
    label: PropTypes.string,
    value: PropTypes.arrayOf(PropTypes.shape({})),
    validationMessage: PropTypes.string,
    getItemContent: PropTypes.func.isRequired,
    onAddItem: PropTypes.func,
    onEditItem: PropTypes.func,
    onRemoveItem: PropTypes.func,
  }

  static defaultProps = {
    asId: 'id',
    className: '',
    disabled: false,
    fullWidth: false,
    label: '',
    value: null,
    onAddItem: () => {},
    onEditItem: () => {},
    onRemoveItem: () => {},
  }

  constructor(props) {
    super(props);
    this.state = {
      activeId: null,
    };
  }

  handleItemSelect = activeId => () => {
    this.setState({ activeId });
  };

  handleItemAdd = itemId => () => {
    const { onAddItem } = this.props;
    onAddItem && onAddItem(itemId);
  };

  handleItemEdit = itemId => () => {
    const { onEditItem } = this.props;
    onEditItem && onEditItem(itemId);
  };

  handleItemRemove = itemId => () => {
    const { onRemoveItem } = this.props;
    onRemoveItem && onRemoveItem(itemId);
  };

  getEmptyItem = () => (
    <PlatesListItem
      className="PlatesListField__empty_item"
      disabled={this.props.disabled}
      isEmpty={true}
      onAdd={this.handleItemAdd(null)}
    >
      Нет данных для отображения
    </PlatesListItem>
  );

  getListContent = () => {
    const { asId, disabled, value, getItemContent } = this.props;
    const { activeId } = this.state;
    return isFilledArray(value)
      ? value.map((item) => {
          const { [asId]: itemId } = item;
          return (
            <PlatesListItem
              key={itemId}
              disabled={disabled}
              selected={itemId === activeId}
              onAdd={this.handleItemAdd(itemId)}
              onEdit={this.handleItemEdit(itemId)}
              onRemove={this.handleItemRemove(itemId)}
              onSelect={this.handleItemSelect(itemId)}
            >
              {getItemContent(item)}
            </PlatesListItem>
          );
        })
      : this.getEmptyItem();
  };

  render() {
    const {
      className,
      disabled,
      id,
      fullWidth,
      label,
      validationMessage,
    } = this.props;

    const listContent = this.getListContent();

    const _style = { ...(fullWidth && { width: '100%' }) };

    const _rootClassName = classNames(
      'PlatesListField',
      className && className,
      (validationMessage === '') && 'PlatesListField__valid',
      isFilledString(validationMessage) && 'PlatesListField__invalid',
      disabled && 'PlatesListField__disabled',
    );

    return (
      <div
        id={id}
        className={_rootClassName}
        style={_style}
      >
        <BasicLabel
          key="label"
          htmlFor={id}
          disabled={disabled}
        >
          {label}
        </BasicLabel>

        <BasicList className="PlatesListField__list">
          {listContent}
        </BasicList>

        <BasicLabel
          key="errorMessage"
          className="PlatesListField__error-message"
        >
          {validationMessage}
        </BasicLabel>
      </div>
    )
  }
}

export { PlatesList };
