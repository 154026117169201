import React from 'react';
import * as PropTypes from 'prop-types';
import classNames from 'classnames';

import './BasicTr.scss';

const BasicTr = ({
  children,
  className,
  isHeader,
  disabled,
}) => {
  const _className = classNames(
    'BasicTr',
    isHeader && 'BasicTr__header',
    className && className,
    disabled && 'BasicTr__disabled',
  );

  return(
    <tr className={_className}>
      {children}
    </tr>
  )
};

BasicTr.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  isHeader: PropTypes.bool,
};

BasicTr.defaultProps = {
  children: null,
  className: '',
  disabled: false,
  isHeader: false,
};

export { BasicTr };
