import React from 'react';
import { Provider } from 'react-redux';

import Router from '../Router';
import { ErrorModal } from '../modal';

import { configureStore, history } from '../../store';

function App() {
  const store = configureStore();

  return (
    <Provider store={store}>
      <Router history={history} />
      <ErrorModal />
    </Provider>
  );
}

export { App };
