import { ENTITIES } from '../domain/entities';
import { capitalizeFirst } from './common-utils';

const getEntity = (entityId) => {
  const config = ENTITIES[entityId];
  if (!config) return null;
  const { prefix } = config;
  const result = {};
  [
    'loadingStatus',
    'data',
  ].forEach(postfix => { result[postfix] = `${prefix}${capitalizeFirst(postfix)}` });
  return result;
};

export {
  getEntity,
}
