import { connect } from 'react-redux';
import { ChangePasswordModal } from './ChangePasswordModal';
import { tokenAuthSelector } from '../../../store/selectors';
import { userProfileChangePassword } from '../../../network';

const mapStateToProps = state => ({
  token: tokenAuthSelector(state),
});

const mapDispatchToProps = {
  onSave: userProfileChangePassword,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordModal);
