import { connect } from 'react-redux';

import { AdminCardsPageContent } from './AdminCardsPageContent';

import { getEntityData, getEntityItem } from '../../../network';
import {
  activeIdPageStateUiSelector,
  cardsDataSelector,
  cardsItemUiSelector,
  cardsLoadingStatusSelector,
  isEditingPageStateUiSelector,
  tokenAuthSelector,
  validationPageStateUiSelector, visitTypesDataSelector, visitTypesLoadingStatusSelector
} from '../../../store/selectors';
import { reloadEntity, saveEntityDispatcher } from '../../../store/dispatchers';
import { acUiSetPageState, acUiSetPageStateItem } from '../../../store/actions';

const onItemSelect = (pageId, activeId) => {
  return acUiSetPageState({ pageId, pageState: { activeId } });
};

const onEditing = (pageId, item, isNew) => {
  return acUiSetPageState({ pageId, pageState: { isEditing: true, item, ...(isNew === true && { activeId: -1 }) } })
};

const onCancelEditing = (pageId) => {
  return acUiSetPageState({ pageId, pageState: { isEditing: false, item: null, validation: {} } });
};

const onFieldChange = (pageId, { target }) => {
  const { name, value } = target;
  return acUiSetPageStateItem({ pageId, name, value });
};

const mapStateToProps = state => ({
  loadingStatus: cardsLoadingStatusSelector(state),
  data: cardsDataSelector(state),
  activeId: activeIdPageStateUiSelector(state),
  item: cardsItemUiSelector(state),
  validation: validationPageStateUiSelector(state),
  token: tokenAuthSelector(state),
  isEditing: isEditingPageStateUiSelector(state),
  visitTypesLoadingStatus: visitTypesLoadingStatusSelector(state),
  visitTypesData: visitTypesDataSelector(state),
});

const mapDispatchToProps = {
  getEntityData,
  getEntityItem,
  onItemSelect,
  onEditing,
  onCancelEditing,
  onFieldChange,
  onSave: saveEntityDispatcher,
  reloadEntity,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminCardsPageContent);
