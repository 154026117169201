import React from 'react';
import { createSelector } from 'reselect';

import TabLink from '../../components/Page/NavBar/TabLink';

import { pathnameRouterSelector } from './router-selectors';
import { isLoggedSelector } from './auth-selectors';
import { PAGES } from '../../domain/pages';
import { ADMIN_ROUTE } from '../../consts';
import { PlateLink } from '../../components/PlateLink/PlateLink';
import { isExists, isFilledArray } from '../../utils';
import {
  cardsDataSelector,
  concomitantDiagnosisDataSelector,
  diagnosisDataSelector,
  localizationsDataSelector,
  operationsDataSelector,
  rolesDataSelector,
  treatmentDataSelector,
  userProfileDataSelector,
  usersDataSelector,
  visitTypesDataSelector
} from './data-selectors';

const pagesUiSelector = ({ ui }) => ui.pages;

const pageStateUiSelector = createSelector(
  [pathnameRouterSelector, pagesUiSelector],
  (pageId, pages) => {
    return pages.find(item => item.pageId === pageId);
  },
);

const fieldPageStateUiSelectorCreator = (key, defaultValue) => createSelector(
  [pageStateUiSelector],
  (pageState) => {
    const value = pageState && pageState[key];
    return isExists(value) ? value : defaultValue;
  },
);

const activeIdPageStateUiSelector = fieldPageStateUiSelectorCreator('activeId', -1);

const isEditingPageStateUiSelector = fieldPageStateUiSelectorCreator('isEditing', false);

const filterPageStateUiSelector = fieldPageStateUiSelectorCreator('filter', {});

const itemPageStateUiSelector = fieldPageStateUiSelectorCreator('item', null);

const validationPageStateUiSelector = fieldPageStateUiSelectorCreator('validation', {});

const entityDataItemUiSelectorCreator = entityDataSelector => createSelector(
  [entityDataSelector, activeIdPageStateUiSelector],
  (entityData, activeId) => {
    return isFilledArray(entityData)
      ? entityData.find(item => item.id === activeId)
      : null;
  },
);

const usersDataItemUiSelector = entityDataItemUiSelectorCreator(usersDataSelector);

const rolesDataItemUiSelector = entityDataItemUiSelectorCreator(rolesDataSelector);

const visitTypesDataItemUiSelector = entityDataItemUiSelectorCreator(visitTypesDataSelector);

const localizationsDataItemUiSelector = entityDataItemUiSelectorCreator(localizationsDataSelector);

const operationsDataItemUiSelector = entityDataItemUiSelectorCreator(operationsDataSelector);

const treatmentDataItemUiSelector = entityDataItemUiSelectorCreator(treatmentDataSelector);

const cardsDataItemUiSelector = entityDataItemUiSelectorCreator(cardsDataSelector);

const diagnosisDataItemUiSelector = entityDataItemUiSelectorCreator(diagnosisDataSelector);

const concomitantDiagnosisDataItemUiSelector = entityDataItemUiSelectorCreator(concomitantDiagnosisDataSelector);

const entityItemUiSelectorCreator = entityDataItemUiSelector => createSelector(
  [
    entityDataItemUiSelector,
    itemPageStateUiSelector,
    isEditingPageStateUiSelector,
  ],
  (
    dataItem,
    pageStateItem,
    isEditing,
  ) => isEditing ? pageStateItem : dataItem,
);

const usersItemUiSelector = entityItemUiSelectorCreator(usersDataItemUiSelector);

const rolesItemUiSelector = entityItemUiSelectorCreator(rolesDataItemUiSelector);

const visitTypesItemUiSelector = entityItemUiSelectorCreator(visitTypesDataItemUiSelector);

const localizationsItemUiSelector = entityItemUiSelectorCreator(localizationsDataItemUiSelector);

const operationsItemUiSelector = entityItemUiSelectorCreator(operationsDataItemUiSelector);

const treatmentItemUiSelector = entityItemUiSelectorCreator(treatmentDataItemUiSelector);

const diagnosisItemUiSelector = entityItemUiSelectorCreator(diagnosisDataItemUiSelector);

const concomitantDiagnosisItemUiSelector = entityItemUiSelectorCreator(concomitantDiagnosisDataItemUiSelector);

const cardsItemUiSelector = entityItemUiSelectorCreator(cardsDataItemUiSelector);

const userProfileUiSelector = entityItemUiSelectorCreator(userProfileDataSelector);

const navBarIsOpenUiSelector = ({ ui }) => ui.isNavBarOpen;

const navBarItemsUiSelector = createSelector(
  [() => PAGES, isLoggedSelector],
  (pages, isLogged) => {
    return Object.keys(pages)
      .filter(key => (
        pages[key].routeAsLink
        && (!pages[key].privileges || isLogged)
      ))
      .map(key => {
        const { linkLabel } = pages[key];
        return (
          <TabLink key={key} to={key} exact>
            {linkLabel}
          </TabLink>
        )
      });
  },
);

const adminItemsUiSelector = createSelector(
  [() => PAGES],
  (pages) => {
    return Object.keys(pages)
      .filter(key => pages[key].plateInRoute === ADMIN_ROUTE)
      .map(key => {
        const { linkLabel } = pages[key];
        return (
          <PlateLink key={key} to={key}>
            {linkLabel}
          </PlateLink>
        )
      });
  },
);

export {
  pageStateUiSelector,
  activeIdPageStateUiSelector,
  isEditingPageStateUiSelector,
  filterPageStateUiSelector,
  validationPageStateUiSelector,
  usersItemUiSelector,
  rolesItemUiSelector,
  visitTypesItemUiSelector,
  localizationsItemUiSelector,
  diagnosisItemUiSelector,
  concomitantDiagnosisItemUiSelector,
  operationsItemUiSelector,
  treatmentItemUiSelector,
  cardsItemUiSelector,
  navBarIsOpenUiSelector,
  navBarItemsUiSelector,
  adminItemsUiSelector,
  userProfileUiSelector,
};
