import React from 'react';
import * as PropTypes from 'prop-types';
import classNames from 'classnames';

import './BasicTable.scss';

const BasicTable = ({
  id,
  className,
  children,
  disabled,
  fullWidth,
}) => {
  const _className = classNames(
    'BasicTable',
    className && className,
    disabled && 'BasicTable__disabled',
  );

  const _style = { ...(fullWidth && { width: '100%' }) };

  return (
    <table
      id={id}
      className={_className}
      style={_style}
    >
      {children}
    </table>
  );
};

BasicTable.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.any,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
};

BasicTable.defaultProps = {
  id: '',
  className: '',
  disabled: false,
  fullWidth: false,
};

export { BasicTable };
