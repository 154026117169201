import { connect } from 'react-redux';
import { PrivateRoute } from './PrivateRoute';
import { tokenAuthSelector } from '../../../store/selectors';

const mapStateToProps = (state) => {
  return {
    token: tokenAuthSelector(state),
  }
};

export default connect(mapStateToProps)(PrivateRoute);
