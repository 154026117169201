import React, { useEffect } from 'react';
import * as PropTypes from 'prop-types';
import { useRouteMatch } from 'react-router-dom';
import classNames from 'classnames';

import Header from './Header';
import NavBar from './NavBar';
import PageContent from './PageContent';

import styles from './Page.module.scss';
import { PAGES } from '../../domain/pages';

const Page = ({
  pageId,
  isNew,
  setPageState,
  classes,
}) => {
  const { path } = useRouteMatch();

  const config = PAGES[path];

  useEffect(() => {
    if (isNew) {
      setPageState({
        pageId,
        temp: 'temp'
      });
    }
  }, [isNew, pageId, setPageState]);

  const root = classes && classes.root;

  const _className = classNames(
    styles.Page,
    root && root,
    isNew && styles.is__hidden,
  );
  return (
    <div className={_className}>
      <Header
        title={config ? config.title : ''}
        withLoginToolbar={config ? config.withLoginToolbar : false}
      />
      <NavBar />
      <PageContent />
    </div>
  )
};

Page.propTypes = {
  pageId: PropTypes.string.isRequired,
  isNew: PropTypes.bool,
  classes: PropTypes.shape({
    root: PropTypes.string,
  }),
  setPageState: PropTypes.func.isRequired,
};

Page.defaultProps = {
  isNew: true,
  classes: {
    root: '',
  },
};

export { Page };
