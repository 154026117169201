import React, { PureComponent } from 'react';
import * as PropTypes from 'prop-types';
import classNames from 'classnames';
import get from 'lodash/get';

import { LessThanIcon, CrossDiagonalIcon } from '../../../icons';
import { BasicModal } from '../../../modal';

import './BasicMultiSelect.scss';
import { isFilledArray } from '../../../../utils';

class BasicMultiSelect extends PureComponent {
  static propTypes = {
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    classes: PropTypes.shape({}),
    style: PropTypes.shape({}),
    value: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    ),
    items: PropTypes.arrayOf(PropTypes.shape({})),
    asId: PropTypes.string,
    asName: PropTypes.string,
    disabled: PropTypes.bool,
    isInvalid: PropTypes.bool,
    fullWidth: PropTypes.bool,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    id: '',
    classes: {},
    style: {},
    value: [],
    items: [],
    asId: 'id',
    asName: 'name',
    disabled: false,
    fullWidth: false,
    onChange: () => {},
  };

  constructor(props) {
    super(props);
    this.state = {
      isOpened: false,
    };
    this.root = null;
    this.optionsContainer = null;
    this.selectedItems = [];
  }

  componentDidMount() {
    this.updateStyles();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.updateStyles();
  }

  updateStyles = () => {
    if (this.optionsContainer && this.root) {
      const { top, left, width, height } = this.root.getBoundingClientRect();
      this.optionsContainer.style.top = `${top + height}px`;
      this.optionsContainer.style.left = `${left}px`;
      this.optionsContainer.style.left = `${left}px`;
      this.optionsContainer.style.width = `${width}px`;

      if (isFilledArray(this.selectedItems) && this.selectedItems[0] && this.options) {
        const { offsetHeight } = this.options;
        const { offsetTop: selTop, offsetHeight: selHeight } = this.selectedItems[0];
        const selBottom = selTop + selHeight;
        if (offsetHeight < selBottom) {
          this.options.scrollTop = selBottom - offsetHeight / 2;
        }
      }
    }
  };

  handleToggle = () => {
    const { isOpened } = this.state;
    this.setState({ isOpened: !isOpened });
  };

  handleChange = (itemId) => () => {
    let { value, onChange, name } = this.props;
    if (isFilledArray(value)) {
      value = value.includes(itemId)
        ? value.filter(item => item !== itemId)
        : value.concat(itemId);
    } else {
      value = [itemId];
    }
    onChange && onChange({ target: { name, value } });
  };

  getRootClassName = () => {
    const { disabled, classes, isInvalid } = this.props;
    const root = get(classes, 'root');

    return classNames(
      'BasicMultiSelect',
      root && root,
      isInvalid && 'BasicMultiSelect__invalid',
      (isInvalid === false) && 'BasicMultiSelect__valid',
      disabled && 'BasicMultiSelect__disabled',
    );
  };

  getOptions = () => {
    const { items, value, asName, asId } = this.props;
    this.selectedItems = new Array(0);
    let selectedIndex = -1;

    return isFilledArray(items)
      ? items.map((item) => {
        const { [asId]: itemId, [asName]: itemName } = item;
        const isSelected = isFilledArray(value) ? value.includes(itemId) : false;
        const _itemClassName = classNames(
          'BasicMultiSelect__item',
          isSelected && 'BasicMultiSelect__item__selected'
        );
        return (
          <li
            key={itemId}
            className={_itemClassName}
            ref={(elm) => {
              if (isSelected) {
                ++selectedIndex;
                this.selectedItems[selectedIndex] = elm;
              }
            }}
            onClick={this.handleChange(itemId)}
          >
            {itemName}
          </li>
        )
      })
      : [];
  };

  getValueItems = () => {
    const { value, items, asId, asName, disabled } = this.props;
    return isFilledArray(value) && isFilledArray(items) && (
      items
        .filter(item => value.includes(item[asId]))
        .map((item) => {
          const { [asId]: itemId, [asName]: itemName } = item;
          const _valueItemClassName = classNames(
            'BasicMultiSelect__value__item',
            disabled && 'BasicMultiSelect__value__item__disabled',
          );
          return (
            <span
              key={itemId}
              className={_valueItemClassName}
              onClick={(e) => { e.stopPropagation(); }}
            >
              {itemName}
              <span
                className="BasicMultiSelect__value__icon__container"
                onClick={disabled ? null : this.handleChange(itemId)}
              >
                <CrossDiagonalIcon
                  width="1rem"
                  height="1rem"
                />
              </span>
            </span>
          )
        })
    );
  };

  render() {
    const {
      id,
      disabled,
      style,
      fullWidth,
    } = this.props;

    const { isOpened } = this.state;

    const _className = this.getRootClassName();

    const _iconClassName = classNames(
      'BasicMultiSelect__icon',
      isOpened && 'BasicMultiSelect__icon__opened',
      disabled && 'BasicMultiSelect__icon__disabled',
    );

    let _style = style ? { ...style } : {};
    if (fullWidth) { _style.width = '100%'; }

    const _valueItems = this.getValueItems();
    const _options = this.getOptions();

    const rest = {
      ...(!disabled && { tabIndex: 0 }),
    };

    return (
      <div
        id={id}
        className={_className}
        style={_style}
        ref={(elm) => { this.root = elm; }}
        onClick={disabled ? null : this.handleToggle}
        {...rest}
      >
        <div
          key="contentBox"
          className="BasicMultiSelect__content__container"
        >
          {_valueItems}
        </div>
        <div
          key="iconBox"
          className="BasicMultiSelect__icon__container"
        >
          <LessThanIcon
            className={_iconClassName}
            stroke={disabled ? 'rgba(184, 184, 184, 1)' : 'rgba(120, 120, 120, 1)' }
            width="1rem"
            height="1rem"
            onClick={disabled ? null : this.handleToggle}
          />
        </div>
        {
          isOpened && (
            <BasicModal
              onClick={(e) => {
                e.stopPropagation();
                this.setState({ isOpened: false });
              }}
            >
              <div
                className="BasicMultiSelect__options__container"
                ref={(elm) => { this.optionsContainer = elm; }}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <ul
                  className="BasicMultiSelect__options"
                  ref={(elm) => { this.options = elm; }}
                >
                  {
                    isFilledArray(_options)
                      ? _options
                      : (
                          <li className="BasicMultiSelect__item__empty">
                            Нет данных для отображения
                          </li>
                        )
                  }
                </ul>
              </div>
            </BasicModal>
          )
        }
      </div>
    );
  }
}

export { BasicMultiSelect };
