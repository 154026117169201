import React from 'react';
import * as PropTypes from 'prop-types';

import {
  BasicLabel,
  BasicSelect,
} from '../../basic';

import './SelectField.scss';
import { isExists, isFilledString } from '../../../../utils';

const SelectField = ({
  id,
  name,
  label,
  validationMessage,
  disabled,
  ...rest
}) => {
  const _isInvalid = isExists(validationMessage)
    ? isFilledString(validationMessage)
    : validationMessage;

  return (
    <div className="SelectField">
      <BasicLabel
        key="label"
        htmlFor={`${id}_input`}
        disabled={disabled}
      >
        {label}
      </BasicLabel>
      <BasicSelect
        id={id}
        name={name}
        disabled={disabled}
        isInvalid={_isInvalid}
        {...rest}
      />
      <BasicLabel
        key="errorMessage"
        className="SelectField__error-message"
      >
        {validationMessage}
      </BasicLabel>
    </div>
  );
};

SelectField.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  validationMessage: PropTypes.string,
  disabled: PropTypes.bool,
};

SelectField.defaultProps = {
  label: '',
  validationMessage: null,
  disabled: false,
};

export { SelectField };
