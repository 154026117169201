import React, { useState, useEffect, useCallback } from 'react';
import * as PropTypes from 'prop-types';

import BasicModal from '../BasicModal'
import Form from '../../Form';
import Fieldset from '../../Fieldset'
import Legend from '../../Legend';
import Grid from '../../Grid';
import FormToolbar from '../../FormToolbar';
import {
  BasicButton,
  BasicList,
  BasicListItem,
} from '../../controls';

import styles from './PatientListModal.module.scss';
import { isFilledArray, isFilledString } from '../../../utils';

const PatientListModal = ({
  title,
  isVisible,
  token,
  loadPatients,
  onClose,
  onSelect,
}) => {
  const [state, setState] = useState({
    loadStatus: 0,
    items: null,
  });

  useEffect(() => {
    if (isVisible && state.loadStatus === 0) {
      setState({ ...state, loadStatus: 1 });
      loadPatients({
        token,
        cbSuccess: (loadedData) => {
          console.log('patients: ', loadedData);
          setState({
            ...state,
            loadStatus: 2,
            items: loadedData,
          });
        },
        cbError: (err) => {
          console.log('patients: Error: ', err);
          setState({
            ...state,
            loadStatus: 3,
            items: null,
          });
        },
      });
      console.log('PatientListModal: state: ', state);
    }
  }, [isVisible, state, setState, token, loadPatients]);

  const getPatients = useCallback(() => {
    return isFilledArray(state.items)
      ? state.items.map((item) => {
        const { login, patient_name } = item;
        const isSelected = login === state.active;
        const handleItemSelect = () => {
          setState({ ...state, active: login });
        };
        return (
          <BasicListItem
            key={login}
            isButton={true}
            isSelected={isSelected}
            onSelect={handleItemSelect}
          >
            {patient_name}
          </BasicListItem>
        )
      })
      : (
          <BasicListItem>Нет данных для отображения</BasicListItem>
        )
  }, [state, setState]);

  const handleClose = () => {
    new Promise((resolve, reject) => {
      onClose && onClose();
      resolve();
    })
      .then(() => {
        if (state.loadStatus === 3) {
          setState({
            ...state,
            loadStatus: 0,
          });
        }
      });
  };

  const handleSelect = () => {
    const { items, active } = state;
    const patient = isFilledArray(items) && isFilledString(active)
      ? items.find(item => item.login === active)
      : null;
    if (onSelect && patient) {
      onSelect(patient);
      handleClose();
    }
  };

  return isVisible && (
    <BasicModal
      className={styles.PatientListModal}
      onClick={handleClose}
    >
      <Form
        name="patienListModalForm"
        isPreloading={state.loadStatus < 2}
        onClick={(e) => { e.stopPropagation(); }}
      >
        <Fieldset>
          <Legend>
            {title}
          </Legend>
          <Grid
            isContainer={true}
            direction="column"
            wrapping="nowrap"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Grid
              key="patientsList"
              className={styles.list}
              isItem={true}
              lg="12"
            >
              <BasicList>
                {getPatients()}
              </BasicList>
            </Grid>
          </Grid>
        </Fieldset>
        <FormToolbar>
          <BasicButton
            key="btnSelect"
            disabled={!isFilledString(state.active)}
            onClick={handleSelect}
          >
            Выбрать
          </BasicButton>
          <BasicButton
            key="btnClose"
            onClick={handleClose}
          >
            Закрыть
          </BasicButton>
        </FormToolbar>
      </Form>
    </BasicModal>
  )
};

PatientListModal.propTypes = {
  isVisible: PropTypes.bool,
  title: PropTypes.string,
  token: PropTypes.string,
  loadPatients: PropTypes.func,
  onClose: PropTypes.func,
  onSelect: PropTypes.func,
};

PatientListModal.defaultProps = {
  isVisible: true,
  title: 'Список пациентов',
  token: '',
  loadPatients: () => {},
  onClose: () => {},
  onSelect: () => {},
};

export { PatientListModal };
