import React from 'react';
import * as PropTypes from 'prop-types';
import classNames from 'classnames';

import './BasicTCell.scss';

const BasicTCell = ({
  children,
  className,
  disabled,
  isHeader,
  ...rest
}) => {
  const Cell = props => (isHeader ? <th {...props} /> : <td {...props} />);

  const basicClassName = isHeader ? 'BasicTh' : 'BasicTd';

  const _className = classNames(
    'BasicTCell',
    basicClassName,
    className && className,
    disabled && 'BasicTCell__disabled',
    disabled && `${basicClassName}__disabled`,
  );

  return (
    <Cell className={_className} {...rest}>
      {children}
    </Cell>
  )
};

BasicTCell.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  isHeader: PropTypes.bool,
};

BasicTCell.defaultProps = {
  children: null,
  className: '',
  disabled: false,
  isHeader: false,
};

export { BasicTCell };
