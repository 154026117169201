import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import get from 'lodash/get';

import { BasicModal } from '../index';
import Form from '../../Form';
import Fieldset from '../../Fieldset';
import FormToolbar from '../../FormToolbar';
import Legend from '../../Legend';
import {
  ToolbarButton,
  InputField,
} from '../../controls';
import Grid from '../../Grid';

import styles from './ChangePasswordModal.module.scss';
import { focusById } from '../../../utils';

const defaultState = {
  currentPassword: '',
  newPassword: '',
  confirmationPassword: '',
  validationData: {},
};

const ChangePasswordModal = ({
  token,
  isVisible,
  onClose,
  onSave,
}) => {
  const [state, setState] = useState({ ...defaultState });

  const handleChange = (e) => {
    const { value, name } = e.target;
    setState({ ...state, [name]: value });
  };

  const getValidationMessage = name => {
    return get(state, ['validationData', name]);
  };

  const handleSave = () => {
    onSave && onSave({
      token,
      currentPassword: state.currentPassword,
      newPassword: state.newPassword,
      confirmationPassword: state.confirmationPassword,
      cbError: null,
      cbSuccess: (loadedData) => {
        if (loadedData && loadedData.isValid === false) {
          delete loadedData.isValid;
          setState({ ...state, validationData: { ...loadedData } });
        } else if (loadedData && loadedData.isValid === true) {
          new Promise(resolve => (
            resolve(setState({ ...defaultState }))
          ))
            .then(() => {
              onClose && onClose();
            });
        }
      },
    });
  };

  return isVisible && (
    <BasicModal
      className={styles.ChangePasswordModal}
      onClick={(e) => {
        e.stopPropagation();
        onClose && onClose();
      }}
    >
      <Form
        name="changePasswordForm"
        onClick={(e) => { e.stopPropagation(); }}
      >
        <Fieldset>
          <Legend>
            Изменить пароль
          </Legend>
          <Grid
            key="currentPassword"
            isItem={true}
            lg="12"
          >
            <InputField
              id="currentPassword"
              name="currentPassword"
              label="Текущий пароль"
              value={state.currentPassword}
              fullWidth={true}
              type="password"
              onChange={handleChange}
              onEnterDown={() => focusById('newPassword')}
              validationMessage={getValidationMessage('currentPassword')}
            />
          </Grid>
          <Grid
            key="newPassword"
            isItem={true}
            lg="12"
          >
            <InputField
              id="newPassword"
              name="newPassword"
              label="Новый пароль"
              value={state.newPassword}
              fullWidth={true}
              type="password"
              onChange={handleChange}
              onEnterDown={() => focusById('confirmationPassword')}
              validationMessage={getValidationMessage('newPassword')}
            />
          </Grid>
          <Grid
            key="confirmationPassword"
            isItem={true}
            lg="12"
          >
            <InputField
              id="confirmationPassword"
              name="confirmationPassword"
              label="Повтор нового пароля"
              value={state.confirmationPassword}
              type="password"
              fullWidth={true}
              onChange={handleChange}
              validationMessage={getValidationMessage('confirmationPassword')}
            />
          </Grid>
        </Fieldset>
        <FormToolbar>
          <ToolbarButton
            key="cancelBtn"
            onClick={onClose}
          >
            Отмена
          </ToolbarButton>
          <ToolbarButton
            key="saveBtn"
            onClick={handleSave}
          >
            Сохранить
          </ToolbarButton>
        </FormToolbar>
      </Form>
    </BasicModal>
  );
};

ChangePasswordModal.propTypes = {
  token: PropTypes.string,
  isVisible: PropTypes.bool,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
};

ChangePasswordModal.defaultProps = {
  token: '',
  isVisible: false,
  onClose: () => {},
  onSave: () => {},
};

export { ChangePasswordModal };
