import React from 'react';
import * as PropTypes from 'prop-types';

import styles from './AdminPageContent.module.scss';

const AdminPageContent = ({
  items,
}) => {
  return (
    <section className={styles.AdminPageContent}>
      <div className={styles.content}>
        {items}
      </div>
    </section>
  );
};

AdminPageContent.propTypes = {
  items: PropTypes.arrayOf(PropTypes.node),
};

AdminPageContent.defaultProps = {
  items: null,
};

export { AdminPageContent };
