import React from 'react';
import * as PropTypes from 'prop-types';

import Form from '../Form';

import styles from './CentralSectionForm.module.scss';

const CentralSectionForm = ({ isVisible, children, ...rest }) => isVisible && (
  <Form
    className={styles.CentralSectionForm}
    {...rest}
  >
    <div className={styles.sectionWrapper}>
      {children}
    </div>
  </Form>
);

CentralSectionForm.propTypes = {
  isVisible: PropTypes.bool,
  children: PropTypes.any,
};

CentralSectionForm.defaultProps = {
  isVisible: true,
  children: null,
};

export { CentralSectionForm };
