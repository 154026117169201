import React from 'react';
import * as PropTypes from 'prop-types';

import './Aside.scss';

const Aside = ({ children }) => {
  return (
    <aside className="Aside">
      {children}
    </aside>
  )
};

Aside.propTypes = {
  children: PropTypes.any,
};

Aside.defaultProps = {
  children: null,
};

export { Aside };
