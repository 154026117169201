export const USERS = 'USERS';

export const ROLES = 'ROLES';

export const LOCALIZATIONS = 'LOCALIZATIONS';

export const DIAGNOSIS = 'DIAGNOSIS';

export const CONCOMITANT_DIAGNOSIS = 'CONCOMITANT_DIAGNOSIS';

export const OPERATIONS = 'OPERATIONS';

export const TREATMENT = 'TREATMENT';

export const CARDS = 'CARDS';

export const VISIT_TYPES = 'VISIT_TYPES';

export const USER_PROFILE = 'USER_PROFILE';
