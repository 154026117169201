import { connect } from 'react-redux';

import { NavBar } from './NavBar';
import { navBarIsOpenUiSelector, navBarItemsUiSelector } from '../../../store/selectors';

const mapStateToProps = state => ({
  isOpen: navBarIsOpenUiSelector(state),
  items: navBarItemsUiSelector(state),
});

export default connect(mapStateToProps)(NavBar);
