import { connect } from 'react-redux';

import { PageContent } from './PageContent';

import {
  navBarIsOpenUiSelector,
  pageStateUiSelector,
} from '../../../store/selectors';

const mapStateToProps = state => ({
  isNavBarOpen: navBarIsOpenUiSelector(state),
  pageState: pageStateUiSelector(state),
});

export default connect(mapStateToProps)(PageContent);
