import React from 'react';
import * as PropTypes from 'prop-types';
import classNames from 'classnames';

import './BasicInput.scss';
import { isFilledString } from '../../../../utils';

const BasicInput = ({
  autoComplete,
  className,
  disabled,
  fullWidth,
  isInvalid,
  style,
  type,
  value,
  onChange,
  onEnterDown,
  ...rest
}) => {
  const _className = classNames(
    'BasicInput',
    className && className,
    isInvalid && 'BasicInput__invalid',
    (isInvalid === false) && 'BasicInput__valid',
    disabled && 'BasicInput__disabled',
  );

  const handleChange = (e) => {
    onChange && onChange(e);
  };

  const handleKeyDown = (e) => {
    const { target, keyCode, which } = e;
    const code = keyCode || which;
    if (code === 13) {
      handleChange(e);
      onEnterDown && onEnterDown(target);
    }
  };

  let _style = style ? { ...style } : {};
  if (fullWidth) { _style.width = '100%'; }

  const _value = isFilledString(value) ? value : '';

  return(
    <input
      className={_className}
      type={type}
      disabled={disabled}
      style={_style}
      value={_value}
      autoComplete={autoComplete}
      onChange={disabled ? null : handleChange}
      onKeyDown={disabled ? null : handleKeyDown}
      {...rest}
    />
  )
};

BasicInput.propTypes = {
  autoComplete: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  isInvalid: PropTypes.bool,
  style: PropTypes.shape({}),
  type: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onEnterDown: PropTypes.func,
};

BasicInput.defaultProps = {
  autoComplete: 'off',
  className: '',
  disabled: false,
  fullWidth: false,
  style: {},
  type: 'text',
  value: '',
  onChange: () => {},
  onEnterDown: () => {},
};

export { BasicInput };
