export default {
  READ: 1,
  UPDATE: 2,
  CREATE: 4,
  DELETE: 8,
  BLOCK: 16,
  ACTIVATE: 32,
  RESET: 64,
  CHANGE_ACCOUNT_PASSWORD: 128,
};
