import React, { useState, useEffect, useCallback } from 'react';
import * as PropTypes from 'prop-types';
import classNames from 'classnames';

import './BasicNumericInput.scss';
import { isExists, isFilledArray, isFilledString } from '../../../../utils';

const BasicNumericInput = ({
  autoComplete,
  className,
  disabled,
  fullWidth,
  isInvalid,
  style,
  valueType,
  negativeIsAllowed,
  value,
  onChange,
  onEnterDown,
  name,
  ...rest
}) => {
  const getInputValue = useCallback((v) => {
    let parsed;
    if (isExists(v)) {
      let re;
      switch (true) {
        case (valueType === 'float' && negativeIsAllowed):
          re = /^-?\d*\.?\d*$/gi;
          break;
        case (valueType === 'float' && !negativeIsAllowed):
          re = /^\d*\.?\d*$/gi;
          break;
        case (valueType === 'integer' && negativeIsAllowed):
          re = /^-?\d*$/gi;
          break;
        default:
          re = /^\d*$/gi;
      }

      parsed = re.exec(`${v}`);
    }
    return isFilledArray(parsed) ? parsed[0] : null;
  }, [valueType, negativeIsAllowed]);

  const [inputValue, setInputValue] = useState(getInputValue(value) || '');

  useEffect(() => {
    let newValue = isExists(value)
      ? getInputValue(`${value}`)
      : '';
    setInputValue(newValue);
  }, [value, getInputValue]);

  const _className = classNames(
    'BasicNumericInput',
    className && className,
    isInvalid && 'BasicNumericInput__invalid',
    (isInvalid === false) && 'BasicNumericInput__valid',
    disabled && 'BasicNumericInput__disabled',
  );

  let _style = style ? { ...style } : {};
  if (fullWidth) { _style.width = '100%'; }

  const change = () => {
    const newValue = valueType === 'integer'
      ? parseInt(inputValue, 10)
      : parseFloat(inputValue);
    if (newValue === value) {
      setInputValue(isNaN(newValue) ? '' : `${newValue}`);
    }
    onChange && onChange({
      target: {
        name,
        value: isNaN(newValue) ? undefined : newValue,
      },
    });
  };

  const insertIntoString = (string, selStart, selEnd, insString) => {
    return string.substring(0, selStart) + insString + string.substring(selEnd, string.length);
  };

  const handleBlur = (e) => {
    change();
    rest.onBlur && rest.onBlur(e);
  };

  const changeInputValue = (newInputValue, nextSelectionStart, target) => {
    if (isExists(newInputValue) && (newInputValue !== inputValue)) {
      setInputValue(newInputValue);
    } else {
      nextSelectionStart = target.selectionStart;
    }
    setTimeout(() => {
      target.selectionStart = nextSelectionStart;
      target.selectionEnd = nextSelectionStart;
    }, 0);
  };

  const handleKeyDown = (e) => {
    rest.onKeyDown && rest.onKeyDown(e);
    if (e.isComposing || e.keyCode === 229) {
      return;
    }
    const { key, target } = e;
    let { value: eValue, selectionStart: sStart, selectionEnd: sEnd } = target;
    let newInputValue;
    let nextSelStart = sStart;

    switch (true) {
      case (key.length === 1):
        newInputValue = getInputValue(insertIntoString(eValue, sStart, sEnd, key));
        nextSelStart++;
      break;

      case (key === 'Backspace'):
        if ((sStart === sEnd) && (sStart > 0)) {
          nextSelStart--;
        }
        newInputValue = getInputValue(insertIntoString(eValue, nextSelStart, sEnd, ''));
        break;

      case (key === 'Delete'):
        if ((sStart === sEnd) && (sEnd < eValue.length)) {
          sEnd++;
        }
        newInputValue = getInputValue(insertIntoString(eValue, sStart, sEnd, ''));
      break;

      case (key === 'Enter'):
        change();
        onEnterDown && onEnterDown(e.target);
        return;

      case (key === 'Escape'):
        newInputValue = isExists(value)
          ? getInputValue(`${value}`)
          : '';
        nextSelStart = newInputValue.length;
        break;

      default:
        return;
    }

    changeInputValue(newInputValue, nextSelStart, target);
  };

  const handleCut = (e) => {
    rest.onCut && rest.onCut(e);
    const { target } = e;
    const { value: targetValue, selectionStart: sStart, selectionEnd: sEnd } = target;
    const text = targetValue.substring(sStart, sEnd);
    navigator.clipboard.writeText(text);
    const newInputValue = getInputValue(insertIntoString(targetValue, sStart, sEnd, ''));

    changeInputValue(newInputValue, sStart, target);
  };

  const handlePast = (e) => {
    rest.onPast && rest.onPast();
    const { target, clipboardData } = e;
    const { value: targetValue, selectionStart: sStart, selectionEnd: sEnd } = target;
    let text = clipboardData.getData('text');
    text = isFilledString(text) ? text : '';
    const newInputValue = getInputValue(insertIntoString(targetValue, sStart, sEnd, text));
    const nextSelStart = sStart + text.length;

    changeInputValue(newInputValue, nextSelStart, target);
  };

  return (
    <input
      className={_className}
      autoComplete={autoComplete}
      type="text"
      disabled={disabled}
      style={_style}
      value={inputValue}
      {...rest}
      onChange={() => {}}
      onKeyDown={handleKeyDown}
      onPaste={handlePast}
      onCut={handleCut}
      onBlur={handleBlur}
    />
  )
};

BasicNumericInput.propTypes = {
  autoComplete: PropTypes.string,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  value: PropTypes.number,
  valueType: PropTypes.oneOf([ 'integer', 'float' ]),
  negativeIsAllowed: PropTypes.bool,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  isInvalid: PropTypes.bool,
  onEnterDown: PropTypes.func,
  onChange: PropTypes.func,
};

BasicNumericInput.defaultProps = {
  autoComplete: 'off',
  className: '',
  disabled: false,
  fullWidth: false,
  valueType: 'float',
  negativeIsAllowed: false,
  onEnterDown: () => {},
  onChange: () => {},
};

export { BasicNumericInput };
