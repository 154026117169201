import React from 'react';
import * as PropTypes from 'prop-types';
import classNames from 'classnames';

import { BasicIconButton } from '../../basic';
import {
  PencilIcon,
  CrossIcon,
  CrossDiagonalIcon,
} from '../../../icons';

import './PlatesListItem.scss';

const PlatesListItem = ({
  className,
  disabled,
  selected,
  children,
  isEmpty,
  onAdd,
  onEdit,
  onRemove,
  onSelect,
  ...rest
}) => {
  const handleClick = (e) => {
    e.stopPropagation();
    onSelect && onSelect();
  };

  const handleBtnClick = callback => (e) => {
    e.stopPropagation();
    callback && callback();
  };

  const _className = classNames(
    'PlatesListItem',
    className && className,
    selected && 'PlatesListItem__selected',
    disabled && 'PlatesListItem__disabled',
  );

  const _defaultIconProps = {
    style: { height: '2rem' },
  };

  return (
    <li
      className={_className}
      onClick={disabled ? null : handleClick}
      {...rest}
    >
      <span key="content" className="PlatesListItem__content_box">
        {children}
      </span>
      {
        !disabled && (
          <span key="toolbar" className="PlatesListItem__toolbar">
            {
              !isEmpty && (
                <BasicIconButton
                  key="btnEdit"
                  icon={PencilIcon}
                  iconProps={_defaultIconProps}
                  onClick={onEdit}
                />
              )
            }
            <BasicIconButton
              key="btnAdd"
              icon={CrossIcon}
              iconProps={_defaultIconProps}
              onClick={handleBtnClick(onAdd)}
            />
            {
              !isEmpty && (
                <BasicIconButton
                  key="btnRemove"
                  icon={CrossDiagonalIcon}
                  iconProps={_defaultIconProps}
                  onClick={handleBtnClick(onRemove)}
                />
              )
            }
          </span>
        )
      }
    </li>
  );
};

PlatesListItem.propTypes = {
  children: PropTypes.any,
  isEmpty: PropTypes.bool,
  onAdd: PropTypes.func,
  onEdit: PropTypes.func,
  onRemove: PropTypes.func,
};

PlatesListItem.defaultProps = {
  children: null,
  isEmpty: false,
  onAdd: () => {},
  onEdit: () => {},
  onRemove: () => {},
};

export { PlatesListItem };
