import { connect } from 'react-redux';

import { EntityMultiSelect } from './EntityMultiSelect';
import { getEntityData } from '../../../../network';

const mapDispatchToProps = {
  loadItems: getEntityData,
};

export default connect(null, mapDispatchToProps)(EntityMultiSelect);
