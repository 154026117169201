import React from 'react';
import * as PropTypes from 'prop-types';
import classNames from 'classnames';

import { BasicModal } from '../../../../modal';

import styles from './VisitTableActionMenu.module.scss';
import { isExists } from '../../../../../utils';

const VisitTableActionMenu = ({
  isOpen,
  position,
  itemId,
  onEdit,
  onCreate,
  onRemove,
  onClose,
}) => {
  const disabled = !isExists(itemId);

  return isOpen && (
    <BasicModal
      className={styles.VisitTableActionMenu}
      onClick={(e) => { e.stopPropagation(); onClose && onClose(); }}
    >
      <ul className={styles.listItems} style={{ left: position.x, top: position.y }}>
        <li
          key="itemCreate"
          className={styles.menuItem}
          onClick={() => onCreate(itemId)}
        >
          Новый визит
        </li>
        <li
          key="itemUpdate"
          className={classNames(styles.menuItem, disabled && styles.itemDisabled)}
          onClick={disabled
            ? (e) => { e.stopPropagation(); }
            : () => { onEdit(itemId); }
          }
        >
          Редактировать
        </li>
        <li
          key="itemRemove"
          className={classNames(styles.menuItem, disabled && styles.itemDisabled)}
          onClick={disabled
            ? (e) => { e.stopPropagation(); }
            : () => onRemove(itemId)
          }
        >
          Удалить
        </li>
      </ul>
    </BasicModal>
  )
};

VisitTableActionMenu.propTypes = {
  isOpen: PropTypes.bool,
  position: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
  }),
  itemId: PropTypes.oneOfType([
    PropTypes.number, PropTypes.string,
  ]),
  onEdit: PropTypes.func,
  onCreate: PropTypes.func,
  onRemove: PropTypes.func,
  onClose: PropTypes.func,
};

VisitTableActionMenu.defaultProps = {
  isOpen: false,
  position: { x: 0, y: 0 },
  onEdit: () => {},
  onCreate: () => {},
  onRemove: () => {},
  onClose: () => {},
};

export { VisitTableActionMenu };
