import React from 'react';
import * as PropTypes from 'prop-types';
import classNames from 'classnames';

import './CentralSection.scss';

const CentralSection = ({ children, className }) => {
  const _className = classNames('CentralSection', className && className);

  return (
    <section className={_className}>
      {children}
    </section>
  )
};

CentralSection.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
};

CentralSection.defaultProps = {
  children: null,
  className: '',
};

export { CentralSection };
