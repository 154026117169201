import React from 'react';
import * as PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './Grid.module.scss';

const Grid = ({
  className,
  isContainer,
  direction,
  wrapping,
  justifyContent,
  alignItems,
  isItem,
  xl,
  lg,
  sm,
  xs,
  children,
}) => {
  const _className = classNames(
    styles.Grid,
    isContainer && styles.container,
    isContainer && direction === 'column' && styles.column,
    isContainer && direction === 'row' && styles.row,
    isContainer && wrapping === 'wrap' && styles.wrap,
    isContainer && wrapping === 'nowrap' && styles.nowrap,
    isContainer && justifyContent === 'flex-center' && styles.justifyCenter,
    isContainer && justifyContent === 'flex-start' && styles.justifyStart,
    isContainer && justifyContent === 'flex-end' && styles.justifyEnd,
    isContainer && justifyContent === 'space-between' && styles.justifyBetween,
    isContainer && justifyContent === 'space-around' && styles.justifyAround,
    isContainer && justifyContent === 'space-evenly' && styles.justifyEvenly,
    isContainer && alignItems === 'flex-start' && styles.alignStart,
    isContainer && alignItems === 'flex-end' && styles.alignEnd,
    isContainer && alignItems === 'center' && styles.alignCenter,
    isContainer && alignItems === 'stretch' && styles.alignStretch,
    isItem && xl && styles[`col-xl-${xl}`],
    isItem && lg && styles[`col-lg-${lg}`],
    isItem && sm && styles[`col-sm-${sm}`],
    isItem && xs && styles[`col-xs-${xs}`],
    className && className,
  );

  return (
    <div
      className={_className}
    >
      {children}
    </div>
  )
};

Grid.propTypes = {
  isContainer: PropTypes.bool,
  direction: PropTypes.string,
  wrapping: PropTypes.string,
  justifyContent: PropTypes.string,
  alignItems: PropTypes.string,
  isItem: PropTypes.bool,
  xl: PropTypes.string,
  lg: PropTypes.string,
  sm: PropTypes.string,
  xs: PropTypes.string,
  children: PropTypes.any,
};

Grid.defaultProps = {
  children: null,
};

export { Grid };
