import React, { useState, useEffect, useCallback } from 'react';
import * as PropTypes from 'prop-types';

import Aside from '../../Aside';
import CentralSection from '../../CentralSection';
import CentralSectionToolbar from '../../CentralSectionToolbar';
import CentralSectionForm from '../../CentralSectionForm';
import Fieldset from '../../Fieldset';
import Legend from '../../Legend';
import Grid from '../../Grid';
import {
  AsideList,
  InputField,
  SelectField,
  DateInputField,
  ToolbarButton,
} from '../../controls';

import { focusById, getTimestamp, isExists, isFilledArray } from '../../../utils';
import { ROLES, USERS } from '../../../domain/entities/entities-id';

import styles from './AdminUsersPageContent.module.scss';
import { sexOptions } from '../../../consts';

const AdminUsersPageContent = ({
  pageId,
  activeId,
  item,
  validation,
  token,
  loadingStatus,
  data,
  rolesLoadingStatus,
  roles,
  isEditing,
  getEntityData,
  onItemSelect,
  onEditing,
  onCancelEditing,
  onFieldChange,
  onSave,
  reloadEntity,
  onUserBlock,
  onUserActivate,
  onPasswordReset,
}) => {
  const [requests, setRequests] = useState(0);

  useEffect(() => {
    if (!requests || (!data && (loadingStatus === 2))) {
      reloadEntity(USERS);
      setRequests(requests + 1);
    }
  }, [data, loadingStatus, requests, reloadEntity]);

  useEffect(() => {
    if (!requests || (!roles && (rolesLoadingStatus === 2))) {
      reloadEntity(ROLES);
      setRequests(requests + 1);
    }
  }, [roles, rolesLoadingStatus, requests, reloadEntity]);

  useEffect(() => {
    if (loadingStatus === 0) {
      getEntityData(USERS, token);
    }
  }, [loadingStatus, getEntityData, token]);

  useEffect(() => {
    if (rolesLoadingStatus === 0) {
      getEntityData(ROLES, token);
    }
  }, [rolesLoadingStatus, getEntityData, token]);

  const _asideItems = useCallback(() => {
    return isFilledArray(data)
      ? data.map((user) => {
        const {
          id,
          first_name,
          middle_name,
          last_name,
          is_active,
          is_blocked,
        } = user;
        return {
          id,
          name: `${last_name} ${first_name} ${middle_name}`,
          disabled: Boolean(is_blocked || !is_active),
        }
      })
      : null;
  }, [data])();

  const isActive = item && item.is_active && !item.is_blocked;

  const handleItemSelect = pageId => id => onItemSelect(pageId, id);

  const handleFieldChange = (e) => { onFieldChange(pageId, e); };

  const handleToggleUser = () => {
    const action = isActive ? onUserBlock : onUserActivate;
    action(token, item.id);
  };

  const handlePasswordReset = () => {
    onPasswordReset(token, item.id);
  };

  const prepareItem = (v) => ({
    ...v,
    dob: v.dob && getTimestamp(v.dob),
  });

  const buttonsProps = {
    create: {
      isVisible: !isEditing,
      onClick: () => { onEditing(pageId, { name: '', parent_id: 0 }) },
    },
    save: {
      isVisible: item && isEditing,
      onClick: () => { onSave({ entityId: USERS, token, pageId, item: prepareItem(item) }) },
    },
    cancel: {
      isVisible: item && isEditing,
      onClick: () => { onCancelEditing(pageId) },
    },
    edit: {
      isVisible: item && !isEditing,
      onClick: () => { onEditing(pageId, item) },
    },
  };

  const getValidationMessage = (fieldName) => {
    return validation && isExists(validation[fieldName]) ? validation[fieldName] : null
  };

  return (
    <section className={styles.AdminUsersPageContent}>
      <Aside>
        <AsideList
          items={_asideItems}
          pageId={pageId}
          value={activeId}
          disabled={isEditing}
          onSelect={handleItemSelect(pageId)}
        />
      </Aside>
      <CentralSection>
        <CentralSectionToolbar buttonsProps={buttonsProps} />
        <CentralSectionForm
          name="usersItemForm"
          isVisible={Boolean(item)}
        >
          <Fieldset>
            <Legend>
              Пользователь
            </Legend>
            <Grid
              key="fullName"
              isContainer={true}
              direction="row"
              wrapping="wrap"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid
                key="last_name"
                isItem={true}
                lg="4"
              >
                <InputField
                  label="Фамилия"
                  id="last_name"
                  name="last_name"
                  type="text"
                  fullWidth={true}
                  disabled={!isEditing}
                  value={(item && item.last_name) || ''}
                  onChange={handleFieldChange}
                  onEnterDown={() => focusById('first_name')}
                  validationMessage={getValidationMessage('last_name')}
                />
              </Grid>

              <Grid
                key="first_name"
                isItem={true}
                lg="4"
              >
                <InputField
                  label="Имя"
                  id="first_name"
                  name="first_name"
                  type="text"
                  fullWidth={true}
                  disabled={!isEditing}
                  value={(item && item.first_name) || ''}
                  onChange={handleFieldChange}
                  onEnterDown={() => focusById('middle_name')}
                  validationMessage={getValidationMessage('first_name')}
                />
              </Grid>

              <Grid
                key="middle_name"
                isItem={true}
                lg="4"
              >
                <InputField
                  label="Отчество"
                  id="middle_name"
                  name="middle_name"
                  type="text"
                  fullWidth={true}
                  disabled={!isEditing}
                  value={(item && item.middle_name) || ''}
                  onChange={handleFieldChange}
                  onEnterDown={() => focusById('dob__input_input')}
                  validationMessage={getValidationMessage('middle_name')}
                />
              </Grid>
            </Grid>

            <Grid
              key="dobSex"
              isContainer={true}
              direction="row"
              wrapping="wrap"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid
                key="dob"
                isItem={true}
                lg="6"
              >
                <DateInputField
                  id="dob"
                  name="dob"
                  label="Дата рождения"
                  value={item && item.dob}
                  fullWidth={true}
                  disabled={!isEditing}
                  onChange={handleFieldChange}
                  validationMessage={getValidationMessage('dob')}
                />
              </Grid>

              <Grid
                key="sex"
                isItem={true}
                lg="6"
              >
                <SelectField
                  id="sex"
                  name="sex"
                  label="Пол"
                  disabled={!isEditing}
                  fullWidth={true}
                  items={sexOptions}
                  firstIsEmpty={false}
                  value={item && item.sex}
                  validationMessage={getValidationMessage('sex')}
                  onChange={handleFieldChange}
                />
              </Grid>
            </Grid>

            <Grid
              key="emailPhone"
              isContainer={true}
              direction="row"
              wrapping="wrap"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid
                key="phone"
                isItem={true}
                lg="6"
              >
                <InputField
                  label="Телефон"
                  id="phone"
                  name="phone"
                  type="text"
                  fullWidth={true}
                  disabled={!isEditing}
                  value={(item && item.phone) || ''}
                  onChange={handleFieldChange}
                  onEnterDown={() => focusById('email')}
                  validationMessage={getValidationMessage('phone')}
                />
              </Grid>

              <Grid
                key="email"
                isItem={true}
                lg="6"
              >
                <InputField
                  label="E-mail"
                  id="email"
                  name="email"
                  type="text"
                  fullWidth={true}
                  disabled={!isEditing}
                  value={(item && item.email) || ''}
                  onChange={handleFieldChange}
                  onEnterDown={() => focusById('address')}
                  validationMessage={getValidationMessage('email')}
                />
              </Grid>
            </Grid>

            <Grid
              key="address"
              isItem={true}
              lg="12"
            >
              <InputField
                label="Адрес проживания"
                id="address"
                name="address"
                type="text"
                fullWidth={true}
                disabled={!isEditing}
                value={(item && item.address) || ''}
                onChange={handleFieldChange}
                onEnterDown={() => focusById('login')}
                validationMessage={getValidationMessage('address')}
              />
            </Grid>

            <Grid
              key="loginRole"
              isContainer={true}
              direction="row"
              wrapping="wrap"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid
                key="login"
                isItem={true}
                lg="6"
              >
                <InputField
                  label="Логин"
                  id="login"
                  name="login"
                  type="text"
                  fullWidth={true}
                  disabled={!isEditing}
                  value={(item && item.login) || ''}
                  onChange={handleFieldChange}
                  onEnterDown={() => focusById('role_id_input')}
                  validationMessage={getValidationMessage('login')}
                />
              </Grid>

              <Grid
                key="role_id"
                isItem={true}
                lg="6"
              >
                <SelectField
                  id="role_id"
                  name="role_id"
                  label="Роль"
                  disabled={!isEditing}
                  fullWidth={true}
                  items={roles}
                  firstIsEmpty={false}
                  value={item && item.role_id}
                  validationMessage={getValidationMessage('role_id')}
                  onChange={handleFieldChange}
                />
              </Grid>
            </Grid>

            {
              item && item.id && (
                <Grid
                  key="dates"
                  isContainer={true}
                  direction="row"
                  wrapping="wrap"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Grid
                    key="createdTs"
                    isItem={true}
                    lg="3"
                  >
                    <DateInputField
                      id="createdTs"
                      name="createdTs"
                      label="Дата создания"
                      value={item && item.created_ts}
                      fullWidth={true}
                      disabled={true}
                      onChange={() => {}}
                    />
                  </Grid>

                  <Grid
                    key="updatedTs"
                    isItem={true}
                    lg="3"
                  >
                    <DateInputField
                      id="updatedTs"
                      name="updatedTs"
                      label="Дата редактирования"
                      value={item && item.updated_ts}
                      fullWidth={true}
                      disabled={true}
                      onChange={() => {}}
                    />
                  </Grid>

                  <Grid
                    key="activeStatusBtn"
                    isItem={true}
                    lg="3"
                  >
                    <ToolbarButton
                      onClick={handleToggleUser}
                    >
                      {isActive ? 'Заблокировать' : 'Активировать'}
                    </ToolbarButton>
                  </Grid>

                  <Grid
                    key="passwordResetButton"
                    isItem={true}
                    lg="3"
                  >
                    <ToolbarButton
                      onClick={handlePasswordReset}
                    >
                      Сброс пароля
                    </ToolbarButton>
                  </Grid>
                </Grid>
              )
            }
          </Fieldset>
        </CentralSectionForm>
      </CentralSection>
    </section>
  )
};

AdminUsersPageContent.propTypes = {
  pageId: PropTypes.string,
  activeId: PropTypes.number,
  item: PropTypes.shape({}),
  validation: PropTypes.shape({}),
  loadingStatus: PropTypes.number,
  data: PropTypes.arrayOf(PropTypes.shape({})),
  rolesLoadingStatus: PropTypes.number,
  roles: PropTypes.arrayOf(PropTypes.shape({})),
  isEditing: PropTypes.bool,
  onItemSelect: PropTypes.func,
  onEditing: PropTypes.func,
  onCancelEditing: PropTypes.func,
  onFieldChange: PropTypes.func,
  onSave: PropTypes.func,
  onPasswordReset: PropTypes.func,
};

AdminUsersPageContent.defaultProps = {
  pageId: '',
  activeId: -1,
  item: null,
  validation: {},
  loadingStatus: 2,
  rolesLoadingStatus: 2,
  isEditing: false,
  onItemSelect: () => {},
  onEditing: () => {},
  onCancelEditing: () => {},
  onFieldChange: () => {},
  onSave: () => {},
  onPasswordReset: () => {},
};

export { AdminUsersPageContent };
