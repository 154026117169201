import {
  ADMIN_ROUTE,
  ADMIN_USERS_ROUTE,
  ADMIN_ROLES_ROUTE,
  ADMIN_LOCALIZATION_ROUTE,
  ADMIN_DIAGNOSIS_ROUTE,
  ADMIN_CONCOMITANT_DIAGNOSIS_ROUTE,
  ADMIN_OPERATIONS_ROUTE,
  ADMIN_TREATMENT_ROUTE,
  ADMIN_CARDS_ROUTE,
  ADMIN_VISIT_TYPES_ROUTE,
  HOME_ROUTE,
  LOGIN_ROUTE,
  PATIENTS_ID_ROUTE,
  PATIENTS_ROUTE,
  PROFILE_ROUTE,
} from '../../consts';

export default {
  [HOME_ROUTE]: {
    title: 'Клиника - главная страница',
    withLoginToolbar: true,
    routeAsLink: true,
    linkLabel: 'Главная',
  },

  [PATIENTS_ROUTE]: {
    title: 'Клиника - пациенты',
    withLoginToolbar: true,
    routeAsLink: true,
    linkLabel: 'Пациенты',
    privileges: 'p15',
  },

  [PATIENTS_ID_ROUTE]: {
    title: 'Клиника - пациент',
    withLoginToolbar: true,
  },

  [ADMIN_ROUTE]: {
    title: 'Клиника - администрирование',
    withLoginToolbar: true,
    routeAsLink: true,
    linkLabel: 'Администрирование',
    privileges: 'd15l15',
  },

  [ADMIN_USERS_ROUTE]: {
    title: 'Клиника - администрирование - пользователи',
    withLoginToolbar: true,
    routeAsPlate: true,
    plateInRoute: ADMIN_ROUTE,
    linkLabel: 'Пользователи',
    privileges: 'u15',
  },

  [ADMIN_ROLES_ROUTE]: {
    title: 'Клиника - администрирование - роли',
    withLoginToolbar: true,
    routeAsPlate: true,
    plateInRoute: ADMIN_ROUTE,
    linkLabel: 'Роли',
    privileges: 'r15',
  },

  [ADMIN_LOCALIZATION_ROUTE]: {
    title: 'Клиника - администрирование - локализация',
    withLoginToolbar: true,
    routeAsPlate: true,
    plateInRoute: ADMIN_ROUTE,
    linkLabel: 'Локализация',
    privileges: 'l15',
  },

  [ADMIN_DIAGNOSIS_ROUTE]: {
    title: 'Клиника - администрирование - диагноз',
    withLoginToolbar: true,
    routeAsPlate: true,
    plateInRoute: ADMIN_ROUTE,
    linkLabel: 'Диагноз',
    privileges: 'd15',
  },

  [ADMIN_CONCOMITANT_DIAGNOSIS_ROUTE]: {
    title: 'Клиника - администрирование - сопутствующий диагноз',
    withLoginToolbar: true,
    routeAsPlate: true,
    plateInRoute: ADMIN_ROUTE,
    linkLabel: 'Сопутствующий диагноз',
    privileges: 'f15',
  },

  [ADMIN_OPERATIONS_ROUTE]: {
    title: 'Клиника - администрирование - перенесенные операции',
    withLoginToolbar: true,
    routeAsPlate: true,
    plateInRoute: ADMIN_ROUTE,
    linkLabel: 'Перенесенные операции',
    privileges: 'o15',
  },

  [ADMIN_TREATMENT_ROUTE]: {
    title: 'Клиника - администрирование - терапия',
    withLoginToolbar: true,
    routeAsPlate: true,
    plateInRoute: ADMIN_ROUTE,
    linkLabel: 'Терапия',
    privileges: 't15',
  },

  [ADMIN_CARDS_ROUTE]: {
    title: 'Клиника - администрирование - карты пациентов',
    withLoginToolbar: true,
    routeAsPlate: true,
    plateInRoute: ADMIN_ROUTE,
    linkLabel: 'Карты пациентов',
    privileges: 'с15',
  },

  [ADMIN_VISIT_TYPES_ROUTE]: {
    title: 'Клиника - администрирование - тип визита',
    withLoginToolbar: true,
    routeAsPlate: true,
    plateInRoute: ADMIN_ROUTE,
    linkLabel: 'Тип визита',
    privileges: 'v15',
  },

  [PROFILE_ROUTE]: {
    title: 'Клиника - личный кабинет',
    withLoginToolbar: true,
    routeAsLink: true,
    linkLabel: 'Личный кабинет',
    privileges: 'a131',
  },

  [LOGIN_ROUTE]: {
    title: 'Клиника - вход в систему',
    withLoginToolbar: false,
  },
};
