import { handleActions } from 'redux-actions';
import {
  acAppReset,
  acAuthLogout,
  acUiSetPageState,
  acUiSetPageStateItem,
  acUiToggleNavBar,
} from '../actions';

const initialState = {
  pages: [],
  modals: [],
  isNavBarOpen: true,
};

export default handleActions({
  [acUiSetPageState]: (state, { payload }) => {
    const { pageId, pageState } = payload;
    let pages = state.pages.slice();
    const i = pages.findIndex(item => item.pageId === pageId);
    if (i < 0) {
      pages.push({ ...pageState, pageId });
    } else {
      pages[i] = { ...pages[i], ...pageState, pageId };
    }

    return {
      ...state,
      pages,
    };
  },

  [acUiSetPageStateItem]: (state, { payload }) => {
    const { pageId, name, value } = payload;
    let pages = state.pages;
    const i = pages.findIndex(item => item.pageId === pageId);
    if (i > -1) {
      pages = pages.slice();
      const itemField = { [name]: value };
      const item = pages[i].item
        ? { ...pages[i].item, ...itemField }
        : { ...itemField };
      pages[i] = { ...pages[i], item };
    }
    return {
      ...state,
      pages,
    };
  },

  [acUiToggleNavBar]: (state) => ({
    ...state,
    isNavBarOpen: !state.isNavBarOpen,
  }),

  [acAuthLogout]: (state) => ({
    ...initialState,
    isNavBarOpen: state.isNavBarOpen,
  }),

  [acAppReset]: () => ({
    ...initialState,
  }),
}, initialState);
