import { connect } from 'react-redux';

import { PatientListModal } from './PatientListModal';

import { loadPatients } from '../../../network';

const mapStateToProps = state => ({

});

const mapDispatchToProps = {
  loadPatients,
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientListModal);
