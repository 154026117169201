import request from './request';
import { URL } from './index';
import { acDataUpsertItem } from '../store/actions';
import { USERS } from '../domain/entities/entities-id';

const userBlock = (token, userId, cbError, cbSuccess) => (dispatch) => {
  const fetchSuccess = (item) => {
    dispatch(acDataUpsertItem({ entityId: USERS, item }));
    cbSuccess && cbSuccess(item);
  };

  return request({
    token,
    url: `${URL.USERS}/block`,
    method: 'PUT',
    body: JSON.stringify({ id: userId }),
    cbError,
    cbSuccess: fetchSuccess,
  })(dispatch);
};

const userActivate = (token, userId, cbError, cbSuccess) => (dispatch) => {
  const fetchSuccess = (item) => {
    dispatch(acDataUpsertItem({ entityId: USERS, item }));
    cbSuccess && cbSuccess(item);
  };

  return request({
    token,
    url: `${URL.USERS}/activate`,
    method: 'PUT',
    body: JSON.stringify({ id: userId }),
    cbError,
    cbSuccess: fetchSuccess,
  })(dispatch);
};

const userPasswordReset = (token, userId, cbError, cbSuccess) => (dispatch) => {
  const fetchSuccess = (item) => {
    dispatch(acDataUpsertItem({ entityId: USERS, item }));
    cbSuccess && cbSuccess(item);
  };

  return request({
    token,
    url: `${URL.USERS}/reset`,
    method: 'PUT',
    body: JSON.stringify({ id: userId }),
    cbError,
    cbSuccess: fetchSuccess,
  })(dispatch);
};

const userProfileChangePassword = ({
  token,
  currentPassword,
  newPassword,
  confirmationPassword,
  cbError,
  cbSuccess,
}) => (dispatch) => {
  return request({
    token,
    url: `${URL.USER_PROFILE}/change-password`,
    method: 'PUT',
    body: JSON.stringify({
      currentPassword,
      newPassword,
      confirmationPassword,
    }),
    cbError,
    cbSuccess,
  })(dispatch);
};

export {
  userBlock,
  userActivate,
  userPasswordReset,
  userProfileChangePassword,
};
