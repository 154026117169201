import React from 'react';
import * as PropTypes from 'prop-types';

import {
  BasicLabel,
  BasicDateInput,
} from '../../basic';

import './DateInputField.scss';
import { isExists, isFilledString } from '../../../../utils';

const DateInputField = ({
  id,
  name,
  className,
  label,
  validationMessage,
  disabled,
  ...rest
}) => {
  const _isInvalid = isExists(validationMessage)
    ? isFilledString(validationMessage)
    : validationMessage;

  return (
    <div id={id} className="DateInputField">
      <BasicLabel
        key="label"
        htmlFor={`${id}__input`}
        disabled={disabled}
      >
        {label}
      </BasicLabel>
      <BasicDateInput
        id={`${id}__input`}
        name={name}
        disabled={disabled}
        isInvalid={_isInvalid}
        {...rest}
      />
      <BasicLabel
        key="errorMessage"
        className="DateInputField__error-message"
      >
        {validationMessage}
      </BasicLabel>
    </div>
  );
};

DateInputField.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  validationMessage: PropTypes.string,
  disabled: PropTypes.bool,
};

DateInputField.defaultProps = {
  label: '',
  validationMessage: null,
  disabled: false,
};

export {DateInputField};
