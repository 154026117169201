import { replace } from 'connected-react-router';
import { get } from 'lodash';
import request from './request';
import URL from './url-const';
import { history } from '../store/configureStore';
import { acAuthErrorFetching, acAuthLogin, acAuthLogout, acAuthStartFetching } from '../store/actions';
import { isFilledString } from '../utils';

export function login(login, password, cbInvalid) {
  return (dispatch) => {
    dispatch(acAuthStartFetching());
    return request({
      url: URL.LOGIN,
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: { login, password },
      cbError: () => {
        dispatch(acAuthErrorFetching());
      },
      cbSuccess: (loadedData) => {
        if (loadedData) {
          const { isValid, token, login, password, privileges } = loadedData;
          if (isValid === false) {
            cbInvalid && cbInvalid({ login, password });
            dispatch(acAuthErrorFetching());
          } else if (isFilledString(token)) {
            dispatch(acAuthLogin({ login, token, privileges }));
            const from = get(
              history,
              'location.state.from',
              { pathname: '/' },
              );
            dispatch(replace(from));
          }
        }
      },
    })(dispatch);
  };
}

export function logout(token, dispatch) {
  dispatch(acAuthStartFetching());
  return request({
    url: URL.LOGOUT,
    method: 'PUT',
    token,
    cbError: () => {
      dispatch(acAuthErrorFetching());
    },
    cbSuccess: () => {
      dispatch(acAuthLogout());
    },
  })(dispatch);
}
