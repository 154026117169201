import React from 'react';
import * as PropTypes from 'prop-types';
import classNames from 'classnames';

import './Fieldset.scss';

const Fieldset = ({
  className,
  children
}) => {
  const _className = classNames(
    'Fieldset',
    className && className,
  );

  return (
    <fieldset className={_className}>
      {children}
    </fieldset>
  )
};

Fieldset.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
};

Fieldset.defaultProps = {
  className: '',
  children: null,
};

export { Fieldset };
