import { isExists } from '../../utils';
import { createEntity, updateEntity } from '../../network';
import { acDataShouldBeReloaded, acDataUpsertItem, acUiSetPageState } from '../actions';
import { ENTITIES } from '../../domain/entities';

const reloadEntity = (entityId) => acDataShouldBeReloaded({ entityId });

const saveEntityDispatcher = ({
  entityId,
  token,
  pageId,
  item,
}) => (dispatch) => {
  const action = isExists(item.id)
    ? updateEntity
    : createEntity;
  return action({
    entityId,
    token,
    item,
    cbSuccess: (loadedData) => {
      if (loadedData) {
        if (loadedData.isValid === false) {
          dispatch(acUiSetPageState({ pageId, pageState: { validation: loadedData } }));
        } else if (loadedData.isValid === true) {
          delete loadedData.isValid;
          const onSaveSuccess = ENTITIES[entityId].onSaveSuccess
            ? ENTITIES[entityId].onSaveSuccess
            : acDataUpsertItem;
          dispatch(onSaveSuccess({ entityId, item: loadedData }));
          dispatch(acUiSetPageState({
            pageId,
            pageState: {
              isEditing: false,
              item: null,
              validation: {},
              activeId: loadedData.id,
            },
          }));
        }
      }
    },
  })(dispatch);
};

export {
  reloadEntity,
  saveEntityDispatcher,
};
