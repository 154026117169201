import { createSelector } from 'reselect';
import { isFilledString } from '../../utils';

const tokenAuthSelector = ({ auth }) => auth.token || null;

const loginAuthSelector = ({ auth }) => auth.login || '';

const fetchingStatusSelector = ({ auth }) => auth.fetchingStatus || 2;

const isLoggedSelector = createSelector(
  [tokenAuthSelector],
  (token) => {
    return isFilledString(token)
  },
);

const isLoggingSelector = createSelector(
  [fetchingStatusSelector],
  (fetchingStatus) => {
    return fetchingStatus < 2;
  },
);

export {
  tokenAuthSelector,
  loginAuthSelector,
  isLoggedSelector,
  fetchingStatusSelector,
  isLoggingSelector,
};
