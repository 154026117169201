import { connect } from 'react-redux';

import { AdminVisitTypesPageContent } from './AdminVisitTypesPageContent';

import { getEntityData } from '../../../network';
import {
  activeIdPageStateUiSelector,
  isEditingPageStateUiSelector,
  tokenAuthSelector,
  validationPageStateUiSelector,
  visitTypesDataSelector,
  visitTypesItemUiSelector,
  visitTypesLoadingStatusSelector,
} from '../../../store/selectors';
import { reloadEntity, saveEntityDispatcher } from '../../../store/dispatchers';
import { acUiSetPageState, acUiSetPageStateItem } from '../../../store/actions';

const onItemSelect = (pageId, activeId) => {
  return acUiSetPageState({ pageId, pageState: { activeId } });
};

const onEditing = (pageId, item) => {
  return acUiSetPageState({ pageId, pageState: { isEditing: true, item } })
};

const onCancelEditing = (pageId) => {
  return acUiSetPageState({ pageId, pageState: { isEditing: false, item: null, validation: {} } });
};

const onFieldChange = (pageId, { target }) => {
  const { name, value } = target;
  return acUiSetPageStateItem({ pageId, name, value });
};

const mapStateToProps = state => ({
  loadingStatus: visitTypesLoadingStatusSelector(state),
  data: visitTypesDataSelector(state),
  activeId: activeIdPageStateUiSelector(state),
  item: visitTypesItemUiSelector(state),
  validation: validationPageStateUiSelector(state),
  token: tokenAuthSelector(state),
  isEditing: isEditingPageStateUiSelector(state),
});

const mapDispatchToProps = {
  getEntityData,
  onItemSelect,
  onEditing,
  onCancelEditing,
  onFieldChange,
  onSave: saveEntityDispatcher,
  reloadEntity,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminVisitTypesPageContent);
