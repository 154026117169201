import React from 'react';
import * as PropTypes from 'prop-types';
import classNames from 'classnames';

import './BasicButton.scss';

const BasicButton = ({
  className,
  children,
  disabled,
  onClick,
  type,
  ...rest
}) => {
  const _className = classNames(
    'BasicButton',
    className && className,
    disabled && 'BasicButton__disabled',
  );

  return (
    <button
      className={_className}
      disabled={disabled}
      type={type}
      onClick={disabled ? null : onClick}
      {...rest}
    >
      {children}
    </button>
  )
};

BasicButton.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  onClick: PropTypes.func,
};

BasicButton.defaultProps = {
  className: '',
  children: null,
  disabled: false,
  type: 'button',
  onClick: () => {},
};

export { BasicButton };
