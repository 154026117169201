import { handleActions } from 'redux-actions';
import {
  acAppReset,
  acAuthLogout,
  acDataLoadingError,
  acDataLoadingStart,
  acDataLoadingSuccess,
  acDataShouldBeReloaded,
  acDataUpsertItem,
} from '../actions';
import { getEntity } from '../../utils';

const initialState = {
  requestsCount: 0,
};

export default handleActions({
  [acDataShouldBeReloaded]: (state, { payload }) => {
    const { entityId } = payload;
    const { loadingStatus } = getEntity(entityId);
    return {
      ...state,
      [loadingStatus]: 0,
    };
  },

  [acDataLoadingStart]: (state, { payload }) => {
    const { entityId } = payload;
    const { loadingStatus } = getEntity(entityId);
    return {
      ...state,
      [loadingStatus]: 1,
      requestsCount: state.requestsCount++,
    };
  },

  [acDataLoadingError]: (state, { payload }) => {
    const { entityId } = payload;
    const { loadingStatus, data } = getEntity(entityId);
    return {
      ...state,
      [loadingStatus]: 3,
      [data]: null,
      requestsCount: state.requestsCount > 0 ? state.requestsCount-- : 0,
    };
  },

  [acDataLoadingSuccess]: (state, { payload }) => {
    const { entityId, loadedData } = payload;
    const { loadingStatus, data } = getEntity(entityId);
    return {
      ...state,
      [loadingStatus]: 2,
      [data]: loadedData,
      requestsCount: state.requestsCount > 0 ? state.requestsCount-- : 0,
    };
  },

  [acDataUpsertItem]: (state, { payload }) => {
    const { entityId, item } = payload;
    const { data } = getEntity(entityId);
    const entityData = Array.isArray(state[data])
      ? state[data].slice()
      : [];
    const i = entityData.findIndex(el => el.id === item.id);
    if (i > -1) {
      entityData[i] = { ...entityData[i], ...item };
    } else {
      entityData.push(item);
    }
    return {
      ...state,
      [data]: entityData,
    };
  },

  [acAuthLogout]: (state) => ({
    ...initialState,
    requestsCount: state.requestsCount,
  }),

  [acAppReset]: () => ({
    ...initialState,
  }),
}, initialState);
