import request from './request';
import { URL } from './index';

export const loadPatients = ({
  token,
  cbError,
  cbSuccess,
}) => (dispatch) => {
  return request({
    token,
    url: URL.PATIENTS,
    cbError,
    cbSuccess,
  })(dispatch);
};
