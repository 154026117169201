import React from 'react';
import * as PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './NavBar.module.scss';

const NavBar = ({
  className,
  isOpen,
  items,
}) => {
  const _className = classNames(
    styles.NavBar,
    isOpen && styles.isOpen,
    className && className,
  );

  return (
    <nav className={_className}>
      {items}
    </nav>
  );
};

NavBar.propTypes = {
  className: PropTypes.string,
};

NavBar.defaultProps = {
  className: '',
};

export { NavBar };
