import React from 'react';
import * as PropTypes from 'prop-types';

import BasicModal from '../BasicModal';
import Form from '../../Form';
import Fieldset from '../../Fieldset';
import Legend from '../../Legend';
import FormToolbar from '../../FormToolbar';
import { ToolbarButton } from '../../controls/special';
import Grid from '../../Grid';

import styles from './OperationConfirmationModal.module.scss';

const OperationConfirmationModal = ({
  title,
  message,
  onChange,
  onClose,
}) => {
  return (
    <BasicModal
      className={styles.OperationConfirmationModal}
      onClick={(e) => {
        e.stopPropagation();
        onClose && onClose();
      }}
    >
      <Form
        name="operationConfirmationForm"
        onClick={(e) => { e.stopPropagation(); }}
      >
        <Fieldset>
          <Legend>
            {title}
          </Legend>
          <Grid isItem={true} lg="12">
            {message}
          </Grid>
        </Fieldset>
        <FormToolbar>
          <ToolbarButton
            key="btnYes"
            id="btnYes"
            onClick={onChange}
          >
            Да
          </ToolbarButton>
          <ToolbarButton
            key="btnCancel"
            id="btnCancel"
            onClick={onClose}
          >
            Нет
          </ToolbarButton>
        </FormToolbar>
      </Form>
    </BasicModal>
  );
};

OperationConfirmationModal.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
};

OperationConfirmationModal.defaultProps = {
  title: '',
  message: '',
  onChange: () => {},
  onClose: () => {},
};

export { OperationConfirmationModal };
