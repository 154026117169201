import React from 'react';
import * as PropTypes from 'prop-types';

const UncheckedBoxIcon = ({
  width,
  height,
  stroke,
  fill,
  ...rest
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      preserveAspectRatio="xMidYMid meet"
      {...rest}
    >
      <rect
        x={0.5}
        y={0.5}
        rx={2}
        ry={2}
        width={15}
        height={15}
        fill={fill}
        stroke={stroke}
        strokeWidth={1}
      />
    </svg>
  )
};

UncheckedBoxIcon.propTypes = {
  width: PropTypes.oneOfType([
    PropTypes.number, PropTypes.string,
  ]),
  height: PropTypes.oneOfType([
    PropTypes.number, PropTypes.string,
  ]),
  stroke: PropTypes.oneOfType([
    PropTypes.number, PropTypes.string,
  ]),
  fill: PropTypes.oneOfType([
    PropTypes.number, PropTypes.string,
  ]),
};

UncheckedBoxIcon.defaultProps = {
  width: '100%',
  height: '100%',
  stroke: 'rgba(0, 0, 0, 1)',
  fill: 'none',
};

export default UncheckedBoxIcon;
