import React from 'react';
import * as PropTypes from 'prop-types';
import classNames from 'classnames';

import {
  UncheckedBoxIcon,
  CheckedBoxIcon,
} from '../../../icons';

import './BasicCheckbox.scss';

const BasicCheckbox = ({
  id,
  name,
  className,
  disabled,
  isInvalid,
  value,
  onChange,
  uncheckedIcon,
  checkedIcon,
  ...rest
}) => {
  const handleClick = () => {
    const e = { target: { name, value: !value } };
    onChange && onChange(e);
  };

  const _className = classNames(
    'BasicCheckbox',
    className && className,
    (isInvalid === true) && 'BasicCheckbox__invalid',
    (isInvalid === false) && 'BasicCheckbox__valid',
    disabled && 'BasicCheckbox__disabled',
  );

  const Icon = value
    ? checkedIcon
    : uncheckedIcon;

  let fill;
  let stroke;

  if (disabled === true) {
    fill = 'rgba(220, 220, 220, 1)';
    stroke = 'rgba(120, 120, 120, 1)';
  } else if (isInvalid === true) {
    fill = 'rgba(255, 0, 0, 0.15)';
    stroke = 'rgba(255, 0, 0, 1)';
  } else if (isInvalid === false) {
    fill = 'rgba(176, 224, 230, 1)';
    stroke = 'rgba(0, 139, 139, 1)';
  } else {
    fill = 'none';
    stroke = 'rgba(31, 31, 31, 1)';
  }

  return (
    <div
      id={id}
      className={_className}
      tabIndex={-1}
      onClick={handleClick}
    >
      <Icon
        id={`${id}_icon`}
        className="BasicCheckbox__icon"
        stroke={stroke}
        fill={fill}
        {...rest}
      />
    </div>
  );
};

BasicCheckbox.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  isInvalid: PropTypes.bool,
  value: PropTypes.any,
  onChange: PropTypes.func,
  uncheckedIcon: PropTypes.func,
  checkedIcon: PropTypes.func,
};

BasicCheckbox.defaultProps = {
  className: '',
  disabled: false,
  isInvalid: null,
  value: false,
  onChange: () => {},
  uncheckedIcon: UncheckedBoxIcon,
  checkedIcon: CheckedBoxIcon,
};

export { BasicCheckbox };
