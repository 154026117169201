import { connect } from 'react-redux';

import { LoginPageContent } from './LoginPageContent';

import { login } from '../../../network';
import { fetchingStatusSelector, isLoggingSelector } from '../../../store/selectors';

const mapStateToProps = state => ({
  isLogging: isLoggingSelector(state),
  authStatus: fetchingStatusSelector(state),
});

const mapDispatchToProps = {
  cbSubmit: login,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPageContent);
