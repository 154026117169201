import React from 'react';
import * as PropTypes from 'prop-types';
import classNames from 'classnames';

import { NavLink } from 'react-router-dom';

import styles from './TabLink.module.scss';

const TabLink = ({
  className,
  activeClassName,
  children,
  ...rest
}) => {
  const _className = classNames(
    styles.TabLink,
    className && className,
  );

  const _activeClassName = classNames(
    styles.isActive,
    activeClassName && activeClassName,
  );

  return (
    <NavLink
      className={_className}
      activeClassName={_activeClassName}
      {...rest}
    >
      {children}
    </NavLink>
  );
};

TabLink.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  activeClassName: PropTypes.string,
};

TabLink.defaultProps = {
  children: null,
  className: '',
  activeClassName: '',
};

export { TabLink };
