import { addFromLeft } from './common-utils';

function eitherDate(v) {
  return v instanceof Date ? v : new Date(v);
}

function getTimestamp(v) {
  const vDate = eitherDate(v);
  const yyyy = vDate.getUTCFullYear();
  const mm = addFromLeft(vDate.getMonth() + 1, 2);
  const dd = addFromLeft(vDate.getDate(), 2);
  const hh = addFromLeft(vDate.getHours(), 2);
  const min = addFromLeft(vDate.getMinutes(), 2);
  const sec = addFromLeft(vDate.getSeconds(), 2);
  const timestamp = `${yyyy}-${mm}-${dd} ${hh}:${min}:${sec}`;
  return timestamp;
}

function getFullYearsDifference(d1, d2) {
  let result;
  if (d1 && (d1 instanceof Date) && d2 && (d2 instanceof Date)) {
    const first = {
      year: d1.getFullYear(),
    };
    let yearStart = new Date(first.year, 0, 1).getTime();
    let dateTime = d1.getTime();
    first.fromStart = dateTime - yearStart;

    const second = {
      year: d2.getFullYear(),
    };
    yearStart = new Date(second.year, 0, 1).getTime();
    dateTime = d2.getTime();
    second.fromStart = dateTime - yearStart;

    const correction = (second.fromStart - first.fromStart) >= 0 ? 0 : -1;
    result = second.year - first.year + correction;
  }
  return result;
}

export {
  eitherDate,
  getTimestamp,
  getFullYearsDifference,
};
