import { connect } from 'react-redux';

import { Page } from './Page';
import {
  pathnameRouterSelector,
  pageStateUiSelector,
} from '../../store/selectors';
import { acUiSetPageState } from '../../store/actions';

const mapStateToProps = state => {
  const pageId = pathnameRouterSelector(state);
  const pageState = pageStateUiSelector(state);
  const isNew = !pageState;
  return  {
    pageId,
    isNew,
  };
};

const mapDispatchToProps = {
  setPageState: acUiSetPageState,
};

export default connect(mapStateToProps, mapDispatchToProps)(Page);
