import { createAction } from 'redux-actions';
import {
  AC_AUTH_LOGIN,
  AC_AUTH_LOGOUT,
  AC_AUTH_START_FETCHING,
  AC_AUTH_ERROR_FETCHING,
} from './action-types';

export const acAuthLogin = createAction(AC_AUTH_LOGIN);

export const acAuthLogout = createAction(AC_AUTH_LOGOUT);

export const acAuthStartFetching = createAction(AC_AUTH_START_FETCHING);

export const acAuthErrorFetching = createAction(AC_AUTH_ERROR_FETCHING);
