import React, { PureComponent } from 'react';
import * as PropTypes from 'prop-types';
import classNames from 'classnames';

import { BasicModal } from '../../../modal';
import Calendar from './Calendar';

import './BasicDateInput.scss';
import { CalendarIcon } from '../../../icons';
import { getDateObject, getMonthStringRu } from './BasicDate.utils';

class BasicDateInput extends PureComponent {
  static propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    fullWidth: PropTypes.bool,
    isInvalid: PropTypes.bool,
    style: PropTypes.shape({}),
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.objectOf(Date),
    ]),
    onChange: PropTypes.func,
  };

  static defaultProps = {
    id: '',
    name: '',
    className: '',
    disabled: false,
    fullWidth: false,
    isInvalid: null,
    style: {},
    value: null,
    onChange: () => {},
  };

  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  componentDidMount() {
    this.updateStyles();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.updateStyles();
  }

  updateStyles = () => {
    if (this.calendar && this.input) {
      const { top, left, height } = this.input.getBoundingClientRect();
      this.calendar.style.top = `${top + height}px`;
      this.calendar.style.left = `${left}px`;
      this.calendar.style.left = `${left}px`;
    }
  };

  handleCalendarToggle = () => {
    const { isOpen } = this.state;
    this.setState({ isOpen: !isOpen });
  };

  handleCalendarChange = (dt) => {
    const { onChange, name } = this.props;
    onChange && onChange({ target: { name, value: dt.toISOString() } });
  };

  getValue = () => {
    const { value } = this.props;
    let result = '';
    if (value) {
      const { year, month, date } = getDateObject(value);
      result = `${date} ${getMonthStringRu(month, 1)} ${year}`;
    }
    return result;
  };

  render() {
    const {
      id,
      className,
      style,
      fullWidth,
      disabled,
      isInvalid,
      value,
      onChange,
      ...rest
    } = this.props;

    const _value = this.getValue();

    const { isOpen } = this.state;

    let _style = style ? { ...style } : {};
    if (fullWidth) { _style.width = '100%'; }

    const _className = classNames(
      'BasicDateInput__input',
      className && className,
      isInvalid && 'BasicDateInput__input__invalid',
      (isInvalid === false) && 'BasicDateInput__input__valid',
      disabled && 'BasicDateInput__input__disabled',
    );

    const _iconClassName = classNames(
      'BasicDateInput__icon',
      isOpen && 'BasicDateInput__icon__opened',
      disabled && 'BasicDateInput__icon__disabled',
    );

    let _iconStroke = 'rgba(120, 120, 120, 1)';
    if (disabled) {
      _iconStroke = 'rgba(184, 184, 184, 1)';
    } else if (isOpen) {
      _iconStroke = 'rgba(28, 134, 238, 1)';
    }

    return (
      <div
        id={id}
        className="BasicDateInput"
        style={_style}
      >
        <input
          className={_className}
          id={`${id}_input`}
          autoComplete="off"
          type="text"
          readOnly={true}
          disabled={disabled}
          value={_value}
          ref={(elm) => { this.input = elm; }}
          onChange={() => {}}
          onClick={disabled ? null : this.handleCalendarToggle}
          {...rest}
        />
        <div
          className="BasicDateInput__icon__container"
        >
          <CalendarIcon
            className={_iconClassName}
            stroke={_iconStroke}
            width="1rem"
            height="1rem"
            onClick={disabled ? null : this.handleCalendarToggle}
          />
        </div>
        {
          isOpen && (
            <BasicModal
              className="BasicDateInput__modal"
              onClick={(e) => {
                e.stopPropagation();
                this.handleCalendarToggle();
              }}
            >
              <div
                className="BasicDateInput__calendar_container"
                ref={(elm) => { this.calendar = elm; }}
              >
                <Calendar
                  value={value}
                  onChange={this.handleCalendarChange}
                  onClose={this.handleCalendarToggle}
                />
              </div>
            </BasicModal>
          )
        }
      </div>
    );
  }
}

export { BasicDateInput };
