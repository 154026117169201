import React from 'react';
import * as PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { isFilledString } from '../../../utils';

const PrivateRoute = ({
  token,
  ...rest
}) => {
  const isLogged = isFilledString(token);

  return isLogged
    ? (
        <Route {...rest} />
      )
    : (
        <Redirect
          to={{
            pathname: '/login',
            state: {
              from: rest.location,
            }
          }}
        />
      )
};

PrivateRoute.propTypes = {
  token: PropTypes.string,
};

PrivateRoute.defaultProps = {
  token: null,
};

export { PrivateRoute };
