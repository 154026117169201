import React from 'react';
import * as PropTypes from 'prop-types';

const CheckedBoxIcon = ({
  width,
  height,
  stroke,
  fill,
  ...rest
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      preserveAspectRatio="xMidYMid meet"
      {...rest}
    >
      <g
        stroke={stroke}
      >
        <rect
          x={0.5}
          y={0.5}
          rx={2}
          ry={2}
          width={15}
          height={15}
          strokeWidth={1}
          fill={fill}
        />
        <path
          d="M3 7 L7 13 L13 3"
          strokeWidth={1.5}
          fill="none"
        />
      </g>
    </svg>
  )
};

CheckedBoxIcon.propTypes = {
  width: PropTypes.oneOfType([
    PropTypes.number, PropTypes.string,
  ]),
  height: PropTypes.oneOfType([
    PropTypes.number, PropTypes.string,
  ]),
  stroke: PropTypes.oneOfType([
    PropTypes.number, PropTypes.string,
  ]),
  fill: PropTypes.oneOfType([
    PropTypes.number, PropTypes.string,
  ]),
};

CheckedBoxIcon.defaultProps = {
  width: '100%',
  height: '100%',
  stroke: 'rgba(0, 0, 0, 1)',
  fill: 'none',
};

export default CheckedBoxIcon;
