import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import classNames from 'classnames';

import {
  BasicLabel,
  BasicTr,
  BasicTCell,
} from '../../basic';
import {
  SimpleTable
} from '../../complex';
import VisitTableActionMenu from './VisitTableActionMenu';
import { OperationConfirmationModal, UlcerDetailsItemModal } from '../../../modal';

import { isFilledArray } from '../../../../utils';

import styles from './VisitTable.module.scss';

const getVisitTypeName = (visitTypeId, visitTypesData) => {
  const visitTypeItem = isFilledArray(visitTypesData)
    ? visitTypesData.find(vt => vt.id === visitTypeId)
    : null;
  return visitTypeItem ? visitTypeItem.name : '';
};

const columns = [
  { id: 'visit_type_id', label: 'Тип визита', getValue: (row, _, visitTypesData) => getVisitTypeName(row.visit_type_id, visitTypesData) },
  { id: 'visit_ts', label: 'Дата визита', getValue: row => new Date(row.visit_ts).toLocaleDateString() },
  { id: 'ulcer_size', label: 'Размеры язвы (мм)', getValue: row => `${row.length} x ${row.width}` },
  { id: 'square', label: 'Площадь (мм кв.)' },
  {
    id: 'difference',
    label: 'Разница (%)',
    getValue: (row, rows) => {
      if (row.id === rows[0].id) {
        return 0;
      }
      const initialSquare = rows[0].square;
      const value = Math.round((row.square / initialSquare - 1) * 10000) / 100;
      return isNaN(value) ? '' : value;
    },
  },
  { id: 'ablation', label: 'Абляция', getValue: row => (row.ablation ? 'Да' : 'Нет') },
  { id: 'sma', label: 'SMA', getValue: row => (row.sma ? 'Да' : 'Нет') },
  { id: 'is_recovered', label: 'Заживление', getValue: row => (Boolean(row.is_recovered) ? 'Да' : 'Нет') },
];

const VisitTable = ({
  id,
  cardId,
  name,
  label,
  fullWidth,
  disabled,
  value,
  onChange,
  validationMessage,
  visitTypesData,
}) => {
  const initialState = {
    actionMenuIsOpen: false,
    actionMenuPosition: { x: 0, y: 0 },
    modalFormIsOpen: false,
    modalConfirmationIsOpen: false,
    item: null,
    previousItemId: null,
  };

  const [state, setState] = useState({ ...initialState });

  const actionMenuOpen = rowId => (e) => {
    const { clientX: x, clientY: y } = e;
    setState({
      ...state,
      actionMenuIsOpen: true,
      actionMenuPosition: { x, y },
      itemId: rowId,
    });
  };

  const actionMenuClose = () => {
    setState({
      ...state,
      actionMenuIsOpen: false,
      actionMenuPosition: { x: 0, y: 0, },
    });
  };

  const handleModalClose = () => {
    setState({
      ...state,
      modalFormIsOpen: false,
      modalConfirmationIsOpen: false,
      item: null,
      previousItemId: null,
    });
  };

  const correctVisitsData = (newValue) => {
    return newValue
      .map((item, index, arr) => ({
        ...item,
        visit_number: index + 1,
        difference: index > 0
          ? Math.round((item.square / arr[index - 1].square - 1) * 100)
          : 0,
      }));
  };

  const handleChange = (newItem) => {
    const { visit_ts } = newItem;
    const visitDateNum = new Date(visit_ts).getTime();
    let newValue;
    if (isFilledArray(value)) {
      newValue = value
        .filter(item => item.id !== newItem.id)
        .sort((item1, item2) => {
          const t1 = new Date(item1).getTime();
          const t2 = new Date(item2).getTime();
          return t1 < t2 ? -1 : 1;
        })
      let before = [];
      let after = [];
      newValue.forEach((item) => {
        const itemVisitDateNum = new Date(item.visit_ts).getTime();
        if (visitDateNum > itemVisitDateNum) {
          before.push(item);
        } else {
          after.push(item);
        }
      });
      newValue = correctVisitsData([...before, newItem, ...after]);
    } else {
      newValue = [{ ...newItem, visit_number: 1 }];
    }

    onChange && onChange({ target: { name, value: newValue } });
  };

  const getNewItemId = () => {
    let max = 0;
    if (isFilledArray(value)) {
      value.forEach((item) => {
        max = Math.max(item.id, max);
      });
    }
    max++;
    return max;
  };

  const handleAdd = (itemId) => {
    const item = {
      id: getNewItemId(),
      card_id: cardId,
      length: 0,
      width: 0,
      square: 0,
      difference: 0,
      is_recovered: false,
    };
    setTimeout(() => {
      setState({
        ...state,
        modalFormIsOpen: true,
        actionMenuIsOpen: false,
        modalConfirmationIsOpen: false,
        item,
        previousItemId: itemId,
      });
    }, 0);
  };

  const handleEdit = (itemId) => {
    const item = value.find(item => item.id === itemId);
    setTimeout(() => {
      setState({
        ...state,
        modalFormIsOpen: true,
        actionMenuIsOpen: false,
        modalConfirmationIsOpen: false,
        item,
        previousItemId: itemId,
      });
    }, 0);
  };

  const handleRemove = (itemId) => {
    setTimeout(() => {
      setState({
        ...state,
        modalFormIsOpen: false,
        actionMenuIsOpen: false,
        modalConfirmationIsOpen: true,
        previousItemId: itemId,
      });
    }, 0);
  };

  const handleConfirmRemoval = () => {
    const { previousItemId } = state;
    let newValue = value.slice();
    let i = newValue.findIndex(item => item.id === previousItemId);
    newValue.splice(i, 1);
    newValue = correctVisitsData(newValue);
    onChange && onChange({ target: { name, value: newValue } });
    handleModalClose();
  };

  const emptyRow = () => {
    return (
      <BasicTr disabled={disabled}>
        <BasicTCell
          disabled={disabled}
          colSpan="8"
          onClick={disabled ? null : actionMenuOpen()}
        >
          Нет данных для отображения
        </BasicTCell>
      </BasicTr>
    );
  };

  const getTBodyContent = (columnsArg, rowsArg) => {
    return isFilledArray(rowsArg)
      ? rowsArg.map((row) => {
        const { id: rowId } = row;
        const isSelected = !disabled && (rowId === state.itemId);

        return (
          <BasicTr
            key={rowId}
            className={classNames(styles.row, isSelected && styles.selectedRow)}
            disabled={disabled}
          >
            {
              columnsArg.map((col) => {
                const { id: colId, getValue } = col;
                const colValue = getValue
                  ? getValue(row, value, visitTypesData)
                  : row[colId];
                return (
                  <BasicTCell key={colId} onClick={disabled ? null : actionMenuOpen(rowId)}>
                    {colValue}
                  </BasicTCell>
                )
              })
            }
          </BasicTr>
        );
      })
      : emptyRow();
  };

  return (
    <div className={styles.VisitTable}>
      <BasicLabel
        key="label"
        htmlFor={`${id}_table`}
        disabled={disabled}
      >
        {label}
      </BasicLabel>

      <SimpleTable
        id={`${id}_table`}
        disabled={disabled}
        fullWidth={fullWidth}
        columns={columns}
        rows={value}
        getTBodyContent={getTBodyContent}
      />

      <BasicLabel
        key="errorMessage"
        className={styles.VisitTableErrorMessage}
      >
        {validationMessage}
      </BasicLabel>

      <VisitTableActionMenu
        isOpen={state.actionMenuIsOpen}
        position={state.actionMenuPosition}
        itemId={state.itemId}
        onCreate={handleAdd}
        onEdit={handleEdit}
        onRemove={handleRemove}
        onClose={actionMenuClose}
      />

      {
        state.modalFormIsOpen && (
          <UlcerDetailsItemModal
            value={state.item}
            visitTypesData={visitTypesData}
            onClose={handleModalClose}
            onChange={handleChange}
          />
        )
      }
      {
        state.modalConfirmationIsOpen && (
          <OperationConfirmationModal
            title="Подтверждение удаления"
            message="Удалить запись о посещении?"
            onClose={handleModalClose}
            onChange={handleConfirmRemoval}
          />
        )
      }
    </div>
  );
};

VisitTable.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  cardId: PropTypes.number,
  label: PropTypes.string,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  value: PropTypes.arrayOf(PropTypes.shape({})),
  onChange: PropTypes.func,
  validationMessage: PropTypes.string,
  visitTypesData: PropTypes.arrayOf(PropTypes.shape({})),
};

VisitTable.defaultProps = {
  id: '',
  label: '',
  fullWidth: false,
  disabled: false,
  value: null,
  onChange: () => {},
  visitTypesData: null,
};

export { VisitTable };
