import React from 'react';
import * as PropTypes from 'prop-types';
import classNames from 'classnames';

import './BasicListItem.scss';

const BasicListItem = ({
  className,
  isButton,
  isSelected,
  children,
  disabled,
  onSelect,
  getRef,
  ...rest
}) => {
  const handleSelect = (e) => {
    onSelect && onSelect(e);
  };

  const _className = classNames(
    'BasicListItem',
    className && className,
    isButton && 'BasicListItem__button',
    isSelected && 'BasicListItem__selected',
    disabled && 'BasicListItem__disabled',
  );

  return (
    <li
      className={_className}
      ref={getRef}
      onClick={disabled ? null : handleSelect}
      {...rest}
    >
      {children}
    </li>
  )
};

BasicListItem.propTypes = {
  className: PropTypes.string,
  isButton: PropTypes.bool,
  isSelected: PropTypes.bool,
  children: PropTypes.any,
  disabled: PropTypes.bool,
  onSelect: PropTypes.func,
  getRef: PropTypes.func,
};

BasicListItem.defaultProps = {
  className: '',
  isButton: false,
  isSelected: false,
  children: null,
  disabled: false,
  onSelect: () => {},
  getRef: () => {},
};

export { BasicListItem };
