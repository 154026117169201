import React from 'react';
import * as PropTypes from 'prop-types';
import classNames from 'classnames';

import './BasicTBody.scss';

const BasicTBody = ({
  children,
  className,
  disabled,
}) => {
  const _className = classNames(
    'BasicTBody',
    className && className,
    disabled && 'BasicTBody__disabled',
  );

  return (
    <tbody className={_className}>
      {children}
    </tbody>
  );
};

BasicTBody.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

BasicTBody.defaultProps = {
  children: null,
  className: '',
  disabled: false,
};

export { BasicTBody };
