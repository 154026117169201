import React from 'react';
import * as PropTypes from 'prop-types';
import classNames from 'classnames';

import {
  BasicLabel,
  BasicCheckbox,
} from '../../basic';

import { focusById, isExists, isFilledString } from '../../../../utils';

import './CheckboxField.scss';

const CheckboxField = ({
  id,
  name,
  label,
  value,
  withValidationMessage,
  validationMessage,
  disabled,
  classes,
  onChange,
  ...rest
}) => {
  const handleClick = () => {
    const e = { target: { name, value: !value } };
    onChange && onChange(e);
    focusById(id);
  };

  const _isInvalid = isExists(validationMessage)
    ? isFilledString(validationMessage)
    : validationMessage;

  let _rootClassName = classNames(
    'CheckboxField',
    (classes && classes.root) && classes.root,
  );

  let _labelClassName = classNames(
    'CheckboxField__label',
    (classes && classes.label) && classes.label,
    disabled && 'CheckboxField__label__disabled',
  );

  return (
    <div className={_rootClassName}>
      <div className="CheckboxField__input">
        <BasicLabel
          key="label"
          className={_labelClassName}
          htmlFor={id}
          disabled={disabled}
          onClick={disabled ? null : handleClick}
        >
          {label}
        </BasicLabel>
        <BasicCheckbox
          id={id}
          name={name}
          value={value}
          disabled={disabled}
          isInvalid={_isInvalid}
          onChange={onChange}
          {...rest}
        />
      </div>
      {
        withValidationMessage && (
          <BasicLabel
            key="errorMessage"
            className="CheckboxField__error-message"
          >
            {validationMessage}
          </BasicLabel>
        )
      }
    </div>
  );
};

CheckboxField.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.any,
  label: PropTypes.string,
  validationMessage: PropTypes.string,
  disabled: PropTypes.bool,
  classes: PropTypes.shape({
    label: PropTypes.string,
  }),
  withValidationMessage: PropTypes.bool,
  onChange: PropTypes.func,
};

CheckboxField.defaultProps = {
  label: '',
  value: false,
  validationMessage: null,
  disabled: false,
  classes: {},
  withValidationMessage: true,
  onChange: () => {},
};

export { CheckboxField };
