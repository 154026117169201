import React from 'react';
import * as PropTypes from 'prop-types';

const CalendarIcon = ({
  width,
  height,
  stroke,
  ...rest
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      preserveAspectRatio="xMidYMid meet"
      {...rest}
    >
      <g stroke={stroke} strokeWidth={1.5} fill="none">
        <rect x={0} y={2} width={16} height={14} />
        <path d="M1 3 H15 M3 3 V0 M13 3 V0" />
        <rect x={3} y={7} width={1.5} height={1.5} />
        <rect x={7} y={7} width={1.5} height={1.5} />
        <rect x={11} y={7} width={1.5} height={1.5} />
        <rect x={3} y={11} width={1.5} height={1.5} />
        <rect x={7} y={11} width={1.5} height={1.5} />
        <rect x={11} y={11} width={1.5} height={1.5} />
      </g>
    </svg>
  )
};

CalendarIcon.propTypes = {
  width: PropTypes.oneOfType([
    PropTypes.number, PropTypes.string,
  ]),
  height: PropTypes.oneOfType([
    PropTypes.number, PropTypes.string,
  ]),
  stroke: PropTypes.oneOfType([
    PropTypes.number, PropTypes.string,
  ]),
};

CalendarIcon.defaultProps = {
  width: '100%',
  height: '100%',
  stroke: 'rgba(0, 0, 0, 1)',
};

export default CalendarIcon;
