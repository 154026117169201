import React, { useState } from 'react';
import * as PropTypes from 'prop-types';

import Form from '../../Form';
import Fieldset from '../../Fieldset';
import Legend from '../../Legend';
import Grid from '../../Grid';
import { InputField } from '../../controls';
import { BasicButton } from '../../controls';
import FormToolbar from '../../FormToolbar';

import styles from './LoginPageContent.module.scss';
import { focusById } from '../../../utils';

const LoginPageContent = ({
  isLogging,
  authStatus,
  cbSubmit,
}) => {
  const [state, setState] = useState({
    login: '',
    password: '',
    validation: {},
  });

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setState({ ...state, [name]: value });
  };

  const handleInvalid = (validation) => {
    setState({ ...state, validation });
  };

  const handleSubmit = () => {
    cbSubmit && cbSubmit(state.login, state.password, handleInvalid);
    setState({ ...state, validation: {} });
  };

  const getValidationMessage = (fieldName) => {
    const defaultMessage = authStatus === 3
      ? 'Ошибка авторизации'
      : null;
    return state.validation[fieldName]
      ? state.validation[fieldName]
      : defaultMessage;
  };

  return (
    <section className={styles.pageContent}>
      <Form
        name="loginForm"
        className={styles.loginForm}
        isPreloading={isLogging}
      >
        <Fieldset>
          <Legend>Введите учетные данные</Legend>
          <Grid
            key="login"
            isContainer={true}
            direction="row"
            wrapping="wrap"
            justifyContent="space-between"
            alignItems="center"
          >
            <InputField
              key="login"
              label="Логин"
              id="login"
              name="login"
              type="text"
              autoFocus={true}
              fullWidth={true}
              value={state.login}
              validationMessage={getValidationMessage('login')}
              onChange={handleChange}
              onEnterDown={() => focusById('password')}
            />
          </Grid>

          <Grid
            key="password"
            isContainer={true}
            direction="row"
            wrapping="wrap"
            justifyContent="space-between"
            alignItems="center"
          >
            <InputField
              key="password"
              type="password"
              id="password"
              name="password"
              label="Пароль"
              fullWidth={true}
              value={state.password}
              validationMessage={getValidationMessage('password')}
              onChange={handleChange}
              onEnterDown={() => focusById('submitBtn')}
            />
          </Grid>
        </Fieldset>
        <FormToolbar>
          <BasicButton
            id="submitBtn"
            onClick={handleSubmit}
            style={{ width: '5rem' }}
          >
            Вход
          </BasicButton>
        </FormToolbar>
      </Form>
    </section>
  )
};

LoginPageContent.propTypes = {
  isLogging: PropTypes.bool,
  authStatus: PropTypes.number,
  cbSubmit: PropTypes.func,
};

LoginPageContent.defaultProps = {
  isLogging: false,
  fetchingStatus: 2,
  cbSubmit: () => {},
};

export { LoginPageContent };
