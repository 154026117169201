import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import { Header } from './Header';
import { LOGIN_ROUTE } from '../../../consts';
import { isLoggedSelector, loginAuthSelector, tokenAuthSelector } from '../../../store/selectors';
import { logout } from '../../../network';
import { acUiToggleNavBar } from '../../../store/actions';

const onLogin = () => {
  return push(LOGIN_ROUTE);
};

const onLogout = (token) => (dispatch) => {
  logout(token, dispatch);
};

const onNavBarToggle = () => {
  return acUiToggleNavBar();
};

const mapStateToProps = state => ({
  login: loginAuthSelector(state),
  token: tokenAuthSelector(state),
  isLogged: isLoggedSelector(state),
});

const mapDispatchToProps = {
  onNavBarToggle,
  onLogin,
  onLogout,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
