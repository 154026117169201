import React from 'react';
import * as PropTypes from 'prop-types';
import classNames from 'classnames';

import './BasicLabel.scss';

const BasicLabel = ({
  className,
  htmlFor,
  children,
  disabled,
  ...rest
}) => {
  const _className = classNames(
    'BasicLabel',
    className && className,
    disabled && 'BasicLabel__disabled',
  );

  return (
    <label
      className={_className}
      htmlFor={htmlFor}
      {...rest}
    >
      {children}
    </label>
  )
};

BasicLabel.propTypes = {
  className: PropTypes.string,
  htmlFor: PropTypes.string,
  children: PropTypes.any,
  disabled: PropTypes.bool,
};

BasicLabel.defaultProps = {
  className: '',
  htmlFor: '',
  children: null,
  disabled: false,
};

export { BasicLabel };
