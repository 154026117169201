import React, { useState, useEffect, useMemo } from 'react';
import * as PropTypes from 'prop-types';

import Aside from '../../Aside';
import CentralSection from '../../CentralSection';
import CentralSectionToolbar from '../../CentralSectionToolbar';
import CentralSectionForm from '../../CentralSectionForm';
import Fieldset from '../../Fieldset';
import Legend from '../../Legend';
import Grid from '../../Grid';
import {
  AsideList,
  InputField,
  NumericInputField,
  DateInputField,
  SelectField,
  UlcerEtiology,
  TextAreaField,
  EntityMultiSelect,
  VisitTable,
} from '../../controls';
import { PatientListModal } from '../../modal';

import { getTimestamp, isExists, isInteger } from '../../../utils';

import {
  CARDS,
  DIAGNOSIS,
  OPERATIONS,
  TREATMENT,
  LOCALIZATIONS,
  CONCOMITANT_DIAGNOSIS,
  VISIT_TYPES,
} from '../../../domain/entities/entities-id';
import { focusById, getFullYearsDifference, isFilledArray } from '../../../utils';
import { sexOptions } from '../../../consts';

import styles from './AdminCardsPageContent.module.scss';

const AdminCardsPageContent = ({
  pageId,
  activeId,
  item,
  validation,
  token,
  loadingStatus,
  data,
  isEditing,
  getEntityData,
  getEntityItem,
  onItemSelect,
  onEditing,
  onCancelEditing,
  onFieldChange,
  onSave,
  reloadEntity,
  visitTypesLoadingStatus,
  visitTypesData,
}) => {
  const [requests, setRequests] = useState(0);
  const [patientsListIsOpen, setPatientsListIsOpen] = useState(false);
  const [itemLoadStatus, setItemLoadStatus] = useState(2);

  const dataItem = useMemo(() => (
    isFilledArray(data) && isInteger(activeId) && (activeId > 0)
      ? data.find(dtItem => dtItem.id === activeId)
      : null
  ), [data, activeId]);

  useEffect(() => {
    if (!requests || (!data && (loadingStatus === 2))) {
      reloadEntity(CARDS);
      setRequests(requests + 1);
    }
  }, [data, loadingStatus, requests, reloadEntity]);

  useEffect(() => {
    if (loadingStatus === 0) {
      getEntityData(CARDS, token);
    }
  }, [loadingStatus, getEntityData, token]);

  useEffect(() => {
    const shouldBeUpdated = dataItem && (
      (dataItem.diagnosis === undefined)
      || (dataItem.previous_operations === undefined)
      || (dataItem.previous_treatment === undefined)
      || (dataItem.localizations === undefined)
      || (dataItem.concomitant_diagnosis === undefined)
      || (dataItem.ulcer_details === undefined)
    );
    if (shouldBeUpdated && (itemLoadStatus === 2)) {
      setItemLoadStatus(0);
    }
    if (itemLoadStatus === 0) {
      setItemLoadStatus(1);
      getEntityItem(
        activeId,
        CARDS,
        token,
        () => {
          setItemLoadStatus(3);
        },
        () => {
          setItemLoadStatus(2);
        },
      );
    }
  }, [dataItem, itemLoadStatus, getEntityItem, activeId, token]);

  useEffect(() => {
    if (visitTypesLoadingStatus === 0) {
      getEntityData(VISIT_TYPES, token);
    }
    if (!visitTypesData && visitTypesLoadingStatus === 2) {
      reloadEntity(VISIT_TYPES);
    }
  }, [visitTypesLoadingStatus, visitTypesData, getEntityData, reloadEntity, token]);

  const fullYears = useMemo(() => {
    let result = '';
    let ulcerDetails = item && isFilledArray(item.ulcer_details)
      ? item.ulcer_details
      : [];
    ulcerDetails.sort((ud1, ud2) => {
      const visitTs1 = new Date(ud1.visit_ts).getTime();
      const visitTs2 = new Date(ud2.visit_ts).getTime();
      return visitTs1 - visitTs2;
    });
    if (item && item.dob) {
      const itemDob = new Date(item.dob);
      let comparatorTs;
      if (isFilledArray(ulcerDetails)) {
        comparatorTs = ulcerDetails[0].visit_ts;
      } else if (item.created_ts) {
        comparatorTs = item.created_ts;
      }
      const comparatorDate = new Date(comparatorTs);
      result = `${getFullYearsDifference(itemDob, comparatorDate)}`;
    }

    return result;
  }, [item]);

  const prepareItem = (v) => {
    const ulcer_details = isFilledArray(v.ulcer_details)
      ? v.ulcer_details.map(ud => ({
          ...ud,
          visit_ts: getTimestamp(ud.visit_ts),
          ablation: ud.ablation ? 1 : 0,
          sma: ud.sma ? 1 : 0,
        }))
      : [];
    return {
      ...v,
      ulcer_details,
    };
  };

  const handleItemSelect = pageId => (id) => {
    setItemLoadStatus(2);
    onItemSelect(pageId, id);
  };

  const handleFieldChange = (e) => {
    onFieldChange(pageId, e);
  };

  const handleCardCreate = () => {
    setPatientsListIsOpen(true);
  };

  const handlePatientSelect = (patient) => {
    patient
      ? onEditing(pageId, { ...patient, created_ts: new Date().toISOString() }, true)
      : setPatientsListIsOpen(false);
  };

  const buttonsProps = {
    create: {
      isVisible: !isEditing,
      onClick: handleCardCreate,
    },
    save: {
      isVisible: item && isEditing,
      onClick: () => { onSave({ entityId: CARDS, token, pageId, item: prepareItem(item) }) },
    },
    cancel: {
      isVisible: item && isEditing,
      onClick: () => { onCancelEditing(pageId) },
    },
    edit: {
      isVisible: item && !isEditing,
      onClick: () => { onEditing(pageId, item) },
    },
  };

  const dataItems = isFilledArray(data)
    ? data.map(card => ({ name: card.patient_name, id: card.id }))
    : null;

  const getValidationMessage = (fieldName) => {
    return validation && isExists(validation[fieldName]) ? validation[fieldName] : null
  };

  return (
    <section className={styles.AdminCardsPageContent}>
      <Aside>
        <AsideList
          pageId={pageId}
          items={dataItems}
          value={activeId}
          disabled={isEditing}
          onSelect={handleItemSelect(pageId)}
        />
      </Aside>
      <CentralSection>
        <CentralSectionToolbar buttonsProps={buttonsProps} />
        <CentralSectionForm
          name="rolesItemForm"
          isVisible={Boolean(item)}
        >
          <Fieldset>
            <Legend>
              Карта пациента
            </Legend>

            <Grid
              key="patientName"
              isItem={true}
              lg="12"
            >
              <InputField
                label="Ф.И.О."
                id="patient_name"
                name="patient_name"
                type="text"
                fullWidth={true}
                disabled={true}
                value={(item && item.patient_name) || ''}
                onChange={() => {}}
              />
            </Grid>

            <Grid
              key="dobFullYears"
              isContainer={true}
              direction="row"
              wrapping="wrap"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid
                key="dob"
                isItem={true}
                lg="4"
              >
                <DateInputField
                  id="dob"
                  name="dob"
                  label="Дата рождения"
                  value={item && item.dob}
                  fullWidth={true}
                  disabled={true}
                  onChange={() => {}}
                />
              </Grid>

              <Grid
                key="fullYears"
                isItem={true}
                lg="4"
              >
                <InputField
                  label="Полных лет"
                  id="fullYears"
                  name="fullYears"
                  type="text"
                  fullWidth={true}
                  disabled={true}
                  value={fullYears}
                  onChange={() => {}}
                />
              </Grid>

              <Grid
                key="sex"
                isItem={true}
                lg="4"
              >
                <SelectField
                  id="sex"
                  name="sex"
                  label="Пол"
                  disabled={true}
                  fullWidth={true}
                  items={sexOptions}
                  firstIsEmpty={false}
                  value={item && item.sex}
                  onChange={() => {}}
                />
              </Grid>
            </Grid>

            <Grid
              key="contacts"
              isContainer={true}
              direction="row"
              wrapping="wrap"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid
                key="phone"
                isItem={true}
                lg="6"
              >
                <InputField
                  label="Телефон"
                  id="phone"
                  name="phone"
                  type="text"
                  fullWidth={true}
                  disabled={true}
                  value={item && item.phone}
                  onChange={() => {}}
                />
              </Grid>

              <Grid
                key="email"
                isItem={true}
                lg="6"
              >
                <InputField
                  label="E-mail"
                  id="email"
                  name="email"
                  type="text"
                  fullWidth={true}
                  disabled={true}
                  value={item && item.email}
                  onChange={() => {}}
                />
              </Grid>
            </Grid>

            <Grid
              key="address"
              isItem={true}
              lg="12"
            >
              <InputField
                label="Адрес проживания"
                id="address"
                name="address"
                type="text"
                fullWidth={true}
                disabled={true}
                value={item && item.address}
                onChange={() => {}}
              />
            </Grid>

            <Grid
              key="diagnosis"
              isItem={true}
              lg="12"
            >
              <EntityMultiSelect
                id="diagnosis"
                name="diagnosis"
                label="Диагноз"
                entityId={DIAGNOSIS}
                token={token}
                disabled={!isEditing}
                fullWidth={true}
                value={item && item.diagnosis}
                onChange={handleFieldChange}
                validationMessage={getValidationMessage('diagnosis')}
              />
            </Grid>

            <Grid
              key="concomitant_diagnosis"
              isItem={true}
              lg="12"
            >
              <EntityMultiSelect
                id="concomitant_diagnosis"
                name="concomitant_diagnosis"
                label="Сопутствующий диагноз"
                entityId={CONCOMITANT_DIAGNOSIS}
                token={token}
                disabled={!isEditing}
                fullWidth={true}
                value={item && item.concomitant_diagnosis}
                onChange={handleFieldChange}
                validationMessage={getValidationMessage('concomitant_diagnosis')}
              />
            </Grid>

            <Grid
              key="ulcer_etiology"
              isItem={true}
              lg="12"
            >
              <UlcerEtiology
                id="ulcer_etiology"
                name="ulcer_etiology"
                disabled={!isEditing}
                value={item && item.ulcer_etiology}
                onChange={handleFieldChange}
                validationMessage={getValidationMessage('ulcer_etiology')}
              />
            </Grid>

            <Grid
              key="anamnesisPersistance"
              isContainer={true}
              direction="row"
              wrapping="wrap"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid
                key="ulcer_anamnesis"
                isItem={true}
                lg="6"
              >
                <NumericInputField
                  label="Язвенный анамнез (мес)"
                  id="ulcer_anamnesis"
                  name="ulcer_anamnesis"
                  value={item && item.ulcer_anamnesis}
                  fullWidth={true}
                  valueType="integer"
                  disabled={!isEditing}
                  onChange={handleFieldChange}
                  onEnterDown={() => focusById('last_persistence')}
                  validationMessage={getValidationMessage('ulcer_anamnesis')}
                />
              </Grid>

              <Grid
                key="last_persistence"
                isItem={true}
                lg="6"
              >
                <NumericInputField
                  label="Длительность последнего незаживления (мес)"
                  id="last_persistence"
                  name="last_persistence"
                  value={item && item.last_persistence}
                  fullWidth={true}
                  valueType="integer"
                  disabled={!isEditing}
                  onChange={handleFieldChange}
                  validationMessage={getValidationMessage('last_persistence')}
                />
              </Grid>
            </Grid>

            <Grid
              key="previous_operations"
              isItem={true}
              lg="12"
            >
              <EntityMultiSelect
                id="previous_operations"
                name="previous_operations"
                label="Предшествующие операции"
                entityId={OPERATIONS}
                token={token}
                disabled={!isEditing}
                fullWidth={true}
                value={item && item.previous_operations}
                onChange={handleFieldChange}
                validationMessage={getValidationMessage('previous_operations')}
              />
            </Grid>

            <Grid
              key="previous_treatment"
              isItem={true}
              lg="12"
            >
              <EntityMultiSelect
                id="previous_treatment"
                name="previous_treatment"
                label="Предшествующее лечение"
                entityId={TREATMENT}
                token={token}
                disabled={!isEditing}
                fullWidth={true}
                value={item && item.previous_treatment}
                onChange={handleFieldChange}
                validationMessage={getValidationMessage('previous_treatment')}
              />
            </Grid>

            <Grid
              key="localizations"
              isItem={true}
              lg="12"
            >
              <EntityMultiSelect
                id="localizations"
                name="localizations"
                label="Локализация язвы"
                entityId={LOCALIZATIONS}
                token={token}
                disabled={!isEditing}
                fullWidth={true}
                value={item && item.localizations}
                onChange={handleFieldChange}
                validationMessage={getValidationMessage('localizations')}
              />
            </Grid>

            <Grid
              key="description"
              isItem={true}
              lg="12"
            >
              <TextAreaField
                id="description"
                name="description"
                label="Описание"
                value={item && item.description}
                disabled={!isEditing}
                fullWidth={true}
                maxLength={1024}
                style={{ resize: 'vertical' }}
                onChange={handleFieldChange}
                validationMessage={getValidationMessage('description')}
              />
            </Grid>

            <Grid key="visits" isItem={true} lg="12">
              <VisitTable
                id="ulcer_details"
                cardId={activeId}
                name="ulcer_details"
                label="Данные визитов"
                disabled={!isEditing}
                fullWidth={true}
                value={item && item.ulcer_details}
                visitTypesData={visitTypesData}
                onChange={handleFieldChange}
              />
            </Grid>

            <Grid
              key="dates"
              isContainer={true}
              direction="row"
              wrapping="wrap"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid
                key="createdTs"
                isItem={true}
                lg="6"
              >
                <DateInputField
                  id="createdTs"
                  name="createdTs"
                  label="Дата создания"
                  value={item && item.created_ts}
                  fullWidth={true}
                  disabled={true}
                  onChange={() => {}}
                />
              </Grid>

              <Grid
                key="updatedTs"
                isItem={true}
                lg="6"
              >
                <DateInputField
                  id="updatedTs"
                  name="updatedTs"
                  label="Дата редактирования"
                  value={item && item.updated_ts}
                  fullWidth={true}
                  disabled={true}
                  onChange={() => {}}
                />
              </Grid>
            </Grid>
          </Fieldset>
        </CentralSectionForm>
      </CentralSection>
      <PatientListModal
        isVisible={patientsListIsOpen}
        token={token}
        onClose={() => setPatientsListIsOpen(false)}
        onSelect={handlePatientSelect}
      />
    </section>
  )
};

AdminCardsPageContent.propTypes = {
  pageId: PropTypes.string,
  activeId: PropTypes.number,
  item: PropTypes.shape({}),
  validation: PropTypes.shape({}),
  loadingStatus: PropTypes.number,
  data: PropTypes.arrayOf(PropTypes.shape({})),
  isEditing: PropTypes.bool,
  visitTypesLoadingStatus: PropTypes.number,
  visitTypesData: PropTypes.arrayOf(PropTypes.shape({})),
  onItemSelect: PropTypes.func,
  onEditing: PropTypes.func,
  onCancelEditing: PropTypes.func,
  onFieldChange: PropTypes.func,
  onSave: PropTypes.func,
  getEntityData: PropTypes.func,
  getEntityItem: PropTypes.func.isRequired,
};

AdminCardsPageContent.defaultProps = {
  pageId: '',
  activeId: -1,
  item: null,
  validation: {},
  loadingStatus: 2,
  isEditing: false,
  visitTypesLoadingStatus: 0,
  onItemSelect: () => {},
  onEditing: () => {},
  onCancelEditing: () => {},
  onFieldChange: () => {},
  onSave: () => {},
};

export { AdminCardsPageContent };
