import React, { useState } from 'react';
import * as PropTypes from 'prop-types';

import { PlatesList } from '../../complex';
import {
  UlcerDetailsItemModal,
  OperationConfirmationModal,
} from '../../../modal';
import { isFilledArray } from '../../../../utils';

import './UlcerDetailsList.scss';

const UlcerDetailsList = ({
  cardId,
  asId,
  name,
  value,
  onChange,
  ...rest
}) => {
  const [state, setState] = useState({
    modalFormIsOpen: false,
    modalConfirmationIsOpen: false,
    item: null,
    previousItemId: null,
  });

  const getNewItemId = () => {
    let max = 0;
    if (isFilledArray(value)) {
      value.forEach((item) => {
        max = Math.max(item[asId], max);
      });
    }
    max++;
    return max;
  };

  const correctVisitsData = (newValue) => {
    return newValue
      .map((item, index, arr) => ({
        ...item,
        visit_number: index + 1,
        difference: index > 0
          ? Math.round((item.square / arr[index - 1].square - 1) * 100)
          : 0,
      }));
  };

  const handleModalClose = () => {
    setState({
      ...state,
      modalFormIsOpen: false,
      modalConfirmationIsOpen: false,
      item: null,
      previousItemId: null,
    });
  };

  const handleChange = (newItem) => {
    const { visit_ts } = newItem;
    const visitDateNum = new Date(visit_ts).getTime();
    let newValue;
    if (isFilledArray(value)) {
      newValue = value
        .filter(item => item[asId] !== newItem[asId])
        .sort((item1, item2) => {
          const t1 = new Date(item1).getTime();
          const t2 = new Date(item2).getTime();
          return t1 < t2 ? -1 : 1;
        })
      let before = [];
      let after = [];
      newValue.forEach((item) => {
        const itemVisitDateNum = new Date(item.visit_ts).getTime();
        if (visitDateNum > itemVisitDateNum) {
          before.push(item);
        } else {
          after.push(item);
        }
      });
      newValue = correctVisitsData([...before, newItem, ...after]);
    } else {
      newValue = [{ ...newItem, visit_number: 1 }];
    }

    onChange && onChange({ target: { name, value: newValue } });
  };

  const handleAdd = (itemId) => {
    const valueItem = itemId === null ? null : value.find(item => item[asId] === itemId);
    const item = {
      [asId]: getNewItemId(),
      visit_number: valueItem === null ? 1 : valueItem.visit_number + 1,
      card_id: cardId,
      length: 0,
      width: 0,
      square: 0,
      difference: 0,
    };
    setState({
      ...state,
      modalFormIsOpen: true,
      modalConfirmationIsOpen: false,
      item,
      previousItemId: itemId,
    });
  };

  const handleEdit = (itemId) => {
    console.log('edit: itemId: ', itemId);
    const item = value.find(item => item[asId] === itemId);
    setState({
      ...state,
      modalFormIsOpen: true,
      modalConfirmationIsOpen: false,
      item,
      previousItemId: itemId,
    });
  };

  const handleRemove = (itemId) => {
    setState({
      ...state,
      modalFormIsOpen: false,
      modalConfirmationIsOpen: true,
      previousItemId: itemId,
    });
  };

  const handleConfirmRemoval = () => {
    const { previousItemId } = state;
    let newValue = value.slice();
    let i = newValue.findIndex(item => item[asId] === previousItemId);
    newValue.splice(i, 1);
    newValue = correctVisitsData(newValue);
    onChange && onChange({ target: { name, value: newValue } });
    handleModalClose();
  };

  const getItemContent = (item) => {
    const {
      visit_number,
      length,
      width,
      square,
      difference,
    } = item;
    return(
      <>
        <span
          key="visitNumber"
          className="ulcerItemDetail visit_number"
        >
          Визит № {visit_number}
        </span>
        <span
          key="sizes"
          className="ulcerItemDetail sizes"
        >
          Размеры язвы (мм): {length} x {width}
        </span>
        <span
          key="square"
          className="ulcerItemDetail square"
        >
         Площадь (мм кв.): {square}
        </span>
        <span
          key="difference"
          className="ulcerItemDetail difference"
        >
          Разница (%): {difference}
        </span>
      </>
    )
  };

  return (
    <>
      <PlatesList
        asId={asId}
        className="UlcerDetailsList"
        name={name}
        value={value}
        getItemContent={getItemContent}
        onAddItem={handleAdd}
        onEditItem={handleEdit}
        onRemoveItem={handleRemove}
        {...rest}
      />
      {
        state.modalFormIsOpen && (
          <UlcerDetailsItemModal
            value={state.item}
            onClose={handleModalClose}
            onChange={handleChange}
          />
        )
      }
      {
        state.modalConfirmationIsOpen && (
          <OperationConfirmationModal
            title="Подтверждение удаления"
            message="Удалить запись о посещении?"
            onClose={handleModalClose}
            onChange={handleConfirmRemoval}
          />
        )
      }
    </>
  );
};

UlcerDetailsList.propTypes = {
  cardId: PropTypes.any,
  asId: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.arrayOf(PropTypes.shape({})),
  onChange: PropTypes.func,
};

UlcerDetailsList.defaultProps = {
  cardId: null,
  asId: 'id',
  value: null,
  onChange: () => {},
};

export { UlcerDetailsList };
