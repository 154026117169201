import React from 'react';
import * as PropTypes from 'prop-types';
import classNames from 'classnames';

import './BasicTHead.scss';

const BasicTHead = ({
  className,
  children,
  disabled,
  ...rest
}) => {
  const _className = classNames(
    'BasicTHead',
    className && className,
    disabled && 'BasicTHead__disabled',
  );

  return (
    <thead className={_className} {...rest}>
      {children}
    </thead>
  )
};

BasicTHead.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  disabled: PropTypes.bool,
};

BasicTHead.defaultProps = {
  className: '',
  disabled: false,
};

export { BasicTHead };
