import { createAction } from 'redux-actions';
import {
  AC_UI_SET_PAGE_STATE,
  AC_UI_SET_PAGE_STATE_ITEM,
  AC_UI_TOGGLE_NAV_BAR,
} from './action-types';

export const acUiSetPageState = createAction(AC_UI_SET_PAGE_STATE);

export const acUiSetPageStateItem = createAction(AC_UI_SET_PAGE_STATE_ITEM);

export const acUiToggleNavBar = createAction(AC_UI_TOGGLE_NAV_BAR);
