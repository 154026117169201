import React, { useState, useEffect } from 'react';
import * as PropTypes from 'prop-types';

import Aside from '../../Aside';
import CentralSection from '../../CentralSection';
import CentralSectionToolbar from '../../CentralSectionToolbar';
import CentralSectionForm from '../../CentralSectionForm';
import Fieldset from '../../Fieldset';
import Legend from '../../Legend';
import Grid from '../../Grid';
import {
  AsideList,
  InputField,
  SelectField,
  DateInputField,
} from '../../controls';

import { isExists } from '../../../utils';
import { CONCOMITANT_DIAGNOSIS } from '../../../domain/entities/entities-id';

import styles from './AdminConcomitantDiagnosisPageContent.module.scss';
import { useParentIdItems } from '../../../hooks';

const AdminConcomitantDiagnosisPageContent = ({
  pageId,
  token,
  activeId,
  item,
  validation,
  loadingStatus,
  data,
  isEditing,
  getEntityData,
  onItemSelect,
  onEditing,
  onCancelEditing,
  onFieldChange,
  onSave,
  reloadEntity,
}) => {
  const [requests, setRequests] = useState(0);

  useEffect(() => {
    if (!requests || (!data && (loadingStatus === 2))) {
      reloadEntity(CONCOMITANT_DIAGNOSIS);
      setRequests(requests + 1);
    }
  }, [data, loadingStatus, requests, reloadEntity]);

  useEffect(() => {
    if (loadingStatus === 0) {
      getEntityData(CONCOMITANT_DIAGNOSIS, token);
    }
  }, [loadingStatus, getEntityData, token]);

  const handleItemSelect = pageId => id => onItemSelect(pageId, id);

  const handleFieldChange = (e) => { onFieldChange(pageId, e); };

  const buttonsProps = {
    create: {
      isVisible: !isEditing,
      onClick: () => { onEditing(pageId, { name: '', parent_id: 0 }) },
    },
    save: {
      isVisible: item && isEditing,
      onClick: () => { onSave({ entityId: CONCOMITANT_DIAGNOSIS, token, pageId, item }) },
    },
    cancel: {
      isVisible: item && isEditing,
      onClick: () => { onCancelEditing(pageId) },
    },
    edit: {
      isVisible: item && !isEditing,
      onClick: () => { onEditing(pageId, item) },
    },
  };

  const getValidationMessage = (fieldName) => {
    return validation && isExists(validation[fieldName]) ? validation[fieldName] : null
  };

  const parentIdItems = useParentIdItems(data, item);

  return (
    <section className={styles.AdminConcomitantDiagnosisPageContent}>
      <Aside>
        <AsideList
          pageId={pageId}
          items={data}
          value={activeId}
          disabled={isEditing}
          onSelect={handleItemSelect(pageId)}
        />
      </Aside>
      <CentralSection>
        <CentralSectionToolbar buttonsProps={buttonsProps} />
        <CentralSectionForm
          name="diagnosisItemForm"
          isVisible={Boolean(item)}
        >
          <Fieldset>
            <Legend>
              Сопутствующий диагноз
            </Legend>
            <Grid
              key="name"
              isItem={true}
              lg="12"
            >
              <InputField
                label="Наименование"
                id="name"
                name="name"
                type="text"
                fullWidth={true}
                disabled={!isEditing}
                value={(item && item.name) || ''}
                onChange={handleFieldChange}
                validationMessage={getValidationMessage('name')}
              />
            </Grid>

            <Grid
              key="parentId"
              isItem={true}
              lg="12"
            >
              <SelectField
                id="parent_id"
                name="parent_id"
                label="Название группы"
                disabled={!isEditing}
                fullWidth={true}
                items={parentIdItems}
                firstIsEmpty={true}
                value={item && item.parent_id}
                validationMessage={getValidationMessage('parent_id')}
                onChange={handleFieldChange}
              />
            </Grid>

            <Grid
              key="dates"
              isContainer={true}
              direction="row"
              wrapping="wrap"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid
                key="createdTs"
                isItem={true}
                lg="6"
              >
                <DateInputField
                  id="created_ts"
                  name="created_ts"
                  label="Дата создания"
                  value={item && item.created_ts}
                  fullWidth={true}
                  disabled={true}
                  onChange={() => {}}
                />
              </Grid>

              <Grid
                key="updatedTs"
                isItem={true}
                lg="6"
              >
                <DateInputField
                  id="updated_ts"
                  name="updated_ts"
                  label="Дата редактирования"
                  value={item && item.updated_ts}
                  fullWidth={true}
                  disabled={true}
                  onChange={() => {}}
                />
              </Grid>
            </Grid>
          </Fieldset>
        </CentralSectionForm>
      </CentralSection>
    </section>
  )
};

AdminConcomitantDiagnosisPageContent.propTypes = {
  pageId: PropTypes.string,
  activeId: PropTypes.number,
  item: PropTypes.shape({}),
  validation: PropTypes.shape({}),
  loadingStatus: PropTypes.number,
  data: PropTypes.arrayOf(PropTypes.shape({})),
  isEditing: PropTypes.bool,
  onItemSelect: PropTypes.func,
  onEditing: PropTypes.func,
  onCancelEditing: PropTypes.func,
  onFieldChange: PropTypes.func,
  onSave: PropTypes.func,
  reloadEntity: PropTypes.func,
};

AdminConcomitantDiagnosisPageContent.defaultProps = {
  pageId: '',
  activeId: -1,
  item: null,
  validation: {},
  loadingStatus: 2,
  isEditing: false,
  // onItemSelect: () => {},
  // onEditing: () => {},
  // onCancelEditing: () => {},
  // onFieldChange: () => {},
  // onSave: () => {},
};

export { AdminConcomitantDiagnosisPageContent };