import React from 'react';
import * as PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import styles from './PlateLink.module.scss';

const PlateLink = ({
  children,
  ...rest
}) => {
  return (
    <div className={styles.PlateLink}>
      <Link
        {...rest}
      >
        {children}
      </Link>
    </div>
  );
};

PlateLink.propTypes = {
  children: PropTypes.any,
};

PlateLink.defaultProps = {
  children: null,
};

export { PlateLink };
