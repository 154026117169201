import { handleActions } from 'redux-actions';
import { acAppReset, acAuthErrorFetching, acAuthLogin, acAuthLogout, acAuthStartFetching } from '../actions';

const setSessionStorage = ({ privileges, login, token }) => {
  sessionStorage.setItem('privileges', privileges);
  sessionStorage.setItem('login', login);
  sessionStorage.setItem('token', token);
};

const getInitialState = () => {
  const token = sessionStorage.getItem('token') || null;
  if (!token) {
    sessionStorage.removeItem('privileges');
  }
  const privileges = sessionStorage.getItem('privileges') || null;
  const login = sessionStorage.getItem('login') || '';
  return {
    privileges,
    login,
    token,
    fetchingStatus: 2,
  };
};

export default handleActions({
  [acAuthStartFetching]: (state) => ({
    ...state,
    fetchingStatus: 1,
  }),

  [acAuthErrorFetching]: (state) => ({
    ...state,
    fetchingStatus: 3,
  }),

  [acAuthLogin]: (state, { payload }) => {
    const { privileges, login, token } = payload;
    setSessionStorage({ privileges, login, token });
    return {
      ...state,
      privileges,
      login,
      token,
      fetchingStatus: 2,
    };
  },

  [acAuthLogout]: (state) => {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('privileges');
    return {
      ...state,
      ...getInitialState(),
      fetchingStatus: 2,
    };
  },

  [acAppReset]: () => {
    return {
      ...getInitialState(),
    }
  },
}, getInitialState());
