import { connect } from 'react-redux';

import { AdminUsersPageContent } from './AdminUsersPageContent';

import {
  getEntityData,
  userActivate,
  userBlock,
  userPasswordReset,
} from '../../../network';
import {
  activeIdPageStateUiSelector,
  isEditingPageStateUiSelector,
  rolesDataSelector,
  rolesLoadingStatusSelector,
  tokenAuthSelector,
  usersDataSelector,
  usersItemUiSelector,
  usersLoadingStatusSelector,
  validationPageStateUiSelector
} from '../../../store/selectors';
import { reloadEntity, saveEntityDispatcher } from '../../../store/dispatchers';
import { acUiSetPageState, acUiSetPageStateItem } from '../../../store/actions';

const onItemSelect = (pageId, activeId) => {
  return acUiSetPageState({ pageId, pageState: { activeId } });
};

const onEditing = (pageId, item) => {
  return acUiSetPageState({ pageId, pageState: { isEditing: true, item } })
};

const onCancelEditing = (pageId) => {
  return acUiSetPageState({ pageId, pageState: { isEditing: false, item: null, validation: {} } });
};

const onFieldChange = (pageId, { target }) => {
  const { name, value } = target;
  return acUiSetPageStateItem({ pageId, name, value });
};

const onUserBlock = (token, id, cbError, cbSuccess) => (dispatch) => {
  userBlock(token, id, cbError, cbSuccess)(dispatch);
};

const onUserActivate = (token, id, cbError, cbSuccess) => (dispatch) => {
  userActivate(token, id, cbError, cbSuccess)(dispatch);
};

const mapStateToProps = state => ({
  loadingStatus: usersLoadingStatusSelector(state),
  data: usersDataSelector(state),
  rolesLoadingStatus: rolesLoadingStatusSelector(state),
  roles: rolesDataSelector(state),
  activeId: activeIdPageStateUiSelector(state),
  item: usersItemUiSelector(state),
  validation: validationPageStateUiSelector(state),
  token: tokenAuthSelector(state),
  isEditing: isEditingPageStateUiSelector(state),
});

const mapDispatchToProps = {
  reloadEntity,
  getEntityData,
  onItemSelect,
  onEditing,
  onCancelEditing,
  onFieldChange,
  onSave: saveEntityDispatcher,
  onUserBlock,
  onUserActivate,
  onPasswordReset: userPasswordReset,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminUsersPageContent);
