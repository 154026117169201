import React from 'react';
import * as PropTypes from 'prop-types';
import classNames from 'classnames';

import { BasicIconButton } from '../../controls';
import { MenuIcon } from '../../icons';

import styles from './Header.module.scss';

const Header = ({
  title,
  withLoginToolbar,
  login,
  token,
  isLogged,
  onLogin,
  onLogout,
  onNavBarToggle,
}) => {
  const calculatedClassName = classNames(
    styles.Header,
    withLoginToolbar && styles.withLoginToolbar,
  );

  const btnLabel = isLogged ? 'Logout' : 'Login';

  const handleClick = () => {
    isLogged ? onLogout(token) : onLogin();
  };

  return (
    <header className={calculatedClassName}>
      <BasicIconButton
        className={styles.menuButton}
        icon={MenuIcon}
        iconProps={{
          stroke: 'rgba(255, 255, 255, 1)',
          width: '1.5rem',
          height: '1.5rem',
        }}
        onClick={onNavBarToggle}
      />
      <h1 className={styles.title}>{title}</h1>
      {
        withLoginToolbar && (
          <span className={styles.loginToolbar}>
            {
              isLogged && (
                <span key="loggedLabel" className={styles.loggedLabel}>
                  Logged as: {login}
                </span>
              )
            }
            <span key="loginBtn" className={styles.headerToolbarButton}>
              <button onClick={handleClick}>
                {btnLabel}
              </button>
            </span>
          </span>
        )
      }
    </header>
  );
};

Header.propTypes = {
  title: PropTypes.string,
  withLoginToolbar: PropTypes.bool,
  login: PropTypes.string,
  token: PropTypes.string,
  isLogged: PropTypes.bool,
  onLogin: PropTypes.func,
  onLogout: PropTypes.func,
  onNavBarToggle: PropTypes.func,
};

Header.defaultProps = {
  title: '',
  withLoginToolbar: false,
  login: '',
  token: null,
  onLogin: () => {},
  onLogout: () => {},
  onNavBarToggle: () => {},
};

export { Header };
