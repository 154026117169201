import request from './request';
import { URL } from './index';
import {
  acDataLoadingStart,
  acDataLoadingError,
  acDataLoadingSuccess,
  acDataUpsertItem,
} from '../store/actions';

const getEntityData = (entityId, token, cbError, cbSuccess) => dispatch => {
  dispatch(acDataLoadingStart({ entityId }));

  return request({
    url: URL[entityId],
    token,
    cbError: (err) => {
      dispatch(acDataLoadingError({ entityId }));
      cbError && cbError(err);
    },
    cbSuccess: (loadedData) => {
      dispatch(acDataLoadingSuccess({ entityId, loadedData }));
      cbSuccess && cbSuccess(loadedData);
    },
  })(dispatch);
};

const updateEntity = ({
  entityId,
  token,
  item,
  cbError,
  cbSuccess,
}) => (dispatch) => {
  return request({
    url: URL[entityId],
    method: 'PUT',
    token,
    body: JSON.stringify(item),
    cbError: (err) => {
      cbError && cbError(err);
    },
    cbSuccess: (loadedData) => {
      cbSuccess && cbSuccess(loadedData);
    },
  })(dispatch);
};

const createEntity = ({
  entityId,
  token,
  item,
  cbError,
  cbSuccess,
}) => (dispatch) => {
  return request({
    url: URL[entityId],
    method: 'POST',
    token,
    cbError: (err) => {
      cbError && cbError(err);
    },
    cbSuccess: (loadedData) => {
      cbSuccess && cbSuccess(loadedData);
    },
    body: JSON.stringify(item),
  })(dispatch);
};

const getEntityItem = (itemId, entityId, token, cbError, cbSuccess) => (dispatch) => {
  const fetchSuccess = (item) => {
    dispatch(acDataUpsertItem({ entityId, item }));
    cbSuccess && cbSuccess(item);
  };

  return request({
    url: `${URL[entityId]}/${itemId}`,
    token,
    cbError,
    cbSuccess: fetchSuccess,
  })(dispatch);
};

export {
  getEntityData,
  updateEntity,
  createEntity,
  getEntityItem,
};
