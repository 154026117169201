export default {
  USERS: 'u',
  ROLES: 'r',
  LOCALIZATIONS: 'l',
  DIAGNOSIS: 'd',
  CONCOMITANT_DIAGNOSIS: 'f', // followed after
  OPERATIONS: 'o',
  TREATMENT: 't',
  CARDS: 'c',
  PATIENTS: 'p',
  DOCTORS: 's', // surgeons
  ACCOUNT: 'a',
  VISIT_TYPES: 'v',
};
