import React, { useState, useEffect } from 'react';
import * as PropTypes from 'prop-types';

import { MultiSelectField } from '../../complex';

const EntityMultiSelect = ({
  entityId,
  loadItems,
  token,
  ...rest
}) => {
  const [state, setState] = useState({
    loadStatus: 0,
    items: null,
  });

  useEffect(() => {
    const { loadStatus, items } = state;
    if (loadStatus === 0) {
      setState({ ...state, loadStatus: 1 });
      loadItems(
        entityId,
        token,
        () => {
          setState({
            ...state,
            items: null,
            loadStatus: 3,
          });
        },
        (loadedData) => {
          setState({
            ...state,
            items: loadedData,
            loadStatus: 2,
          });
        },
      );
    }
    if ((loadStatus === 2) && (!Array.isArray(items))) {
      setState({ ...state, loadStatus: 0 });
    }
  }, [state, entityId, token, loadItems]);

  return (
    <MultiSelectField {...rest} items={state.items} />
  )
};

EntityMultiSelect.propTypes = {
  entityId: PropTypes.string.isRequired,
  loadItems: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
};

export { EntityMultiSelect };
