class NetworkError {
  constructor(message, status, dateTime) {
    this.message = message || 'Network error: something went wrong';
    this.status = status || 500;
    this.dateTime = dateTime || new Date().getTime();

    this.toString = this.toString.bind(this);
  }

  toString() {
    return `Network error (${this.status}) - ${this.message}`;
  }
}

export default NetworkError;
