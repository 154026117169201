import React from 'react';
import * as PropTypes from 'prop-types';
import classNames from 'classnames';

import { BasicButton } from '../../basic';

import styles from './ToolbarButton.module.scss';

const ToolbarButton = ({
  className,
  children,
  isVisible,
  ...rest
}) => {
  const _className = classNames(
    styles.ToolbarButton,
    className && className,
  );

  return isVisible && (
    <BasicButton
      {...rest}
      className={_className}
    >
      { children }
    </BasicButton>
  );
};

ToolbarButton.propTypes = {
  isVisible: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.any,
};

ToolbarButton.defaultProps = {
  isVisible: true,
  className: '',
  children: null,
};

export { ToolbarButton };
