import React from 'react';
import * as PropTypes from 'prop-types';

import FormToolbar from '../FormToolbar';
import { ToolbarButton } from '../controls/special';

import styles from './CentralSectionToolbar.module.scss';

const CentralSectionToolbar = ({ buttonsProps }) => {
  const {
    create,
    save,
    cancel,
    edit,
  } = buttonsProps;

  return (
    <FormToolbar className={styles.CentralSectionToolbar}>
      {
        create && (
          <ToolbarButton
            key="createBtn"
            id="createBtn"
            isVisible={create.isVisible}
            onClick={create.onClick}
          >
            Создать
          </ToolbarButton>
        )
      }

      {
        save && (
          <ToolbarButton
            key="saveBtn"
            id="saveBtn"
            isVisible={save.isVisible}
            onClick={save.onClick}
          >
            Сохранить
          </ToolbarButton>
        )
      }

      {
        cancel && (
          <ToolbarButton
            key="cancelBtn"
            id="cancelBtn"
            isVisible={cancel.isVisible}
            onClick={cancel.onClick}
          >
            Отменить
          </ToolbarButton>
        )
      }

      {
        edit && (
          <ToolbarButton
            key="editBtn"
            id="editBtn"
            isVisible={edit.isVisible}
            onClick={edit.onClick}
          >
            Редактировать
          </ToolbarButton>
        )
      }
    </FormToolbar>
  )
};

CentralSectionToolbar.propTypes = {
  buttonProps: PropTypes.shape({
    create: PropTypes.shape({}),
    save: PropTypes.shape({}),
    cancel: PropTypes.shape({}),
    edit: PropTypes.shape({}),
  }),
};

CentralSectionToolbar.defaultProps = {
  buttonProps: {
    create: {},
    save: {},
    cancel: {},
    edit: {},
  },
};

export { CentralSectionToolbar };
