const AUTH = '/auth';

const API = '/api/v1';

export default {
  LOGIN: `${AUTH}/login`,
  LOGOUT: `${AUTH}/logout`,
  API_PING: `${API}/ping`,
  USERS: `${API}/users`,
  ROLES: `${API}/roles`,
  LOCALIZATIONS: `${API}/localizations`,
  DIAGNOSIS: `${API}/diagnosis`,
  CONCOMITANT_DIAGNOSIS: `${API}/concomitant-diagnosis`,
  OPERATIONS: `${API}/operations`,
  TREATMENT: `${API}/treatment`,
  CARDS: `${API}/cards`,
  VISIT_TYPES: `${API}/visit-types`,
  PATIENTS: `${API}/patients`,
  USER_PROFILE: `${API}/profile`,
};
