export const AC_APP_RESET = 'AC_APP_RESET';

export const AC_AUTH_START_FETCHING = 'AC_AUTH_START_FETCHING';

export const AC_AUTH_ERROR_FETCHING = 'AC_AUTH_ERROR_FETCHING';

export const AC_AUTH_LOGIN = 'AC_AUTH_LOGIN';

export const AC_AUTH_LOGOUT = 'AC_AUTH_LOGOUT';

export const AC_ERROR_ADD = 'AC_ERROR_ADD';

export const AC_ERROR_SHIFT = 'AC_ERROR_SHIFT';

export const AC_UI_SET_PAGE_STATE = 'AC_UI_SET_PAGE_STATE';

export const AC_UI_SET_PAGE_STATE_ITEM = 'AC_UI_SET_PAGE_STATE_ITEM';

export const AC_UI_TOGGLE_NAV_BAR = 'AC_UI_TOGGLE_NAV_BAR';

export const AC_DATA_SHOULD_BE_RELOADED = 'AC_DATA_SHOULD_BE_RELOADED';

export const AC_DATA_LOADING_START = 'AC_DATA_LOADING_START';

export const AC_DATA_LOADING_ERROR = 'AC_DATA_LOADING_ERROR';

export const AC_DATA_LOADING_SUCCESS = 'AC_DATA_LOADING_SUCCESS';

export const AC_DATA_UPSERT_ITEM = 'AC_DATA_UPSERT_ITEM';
