import React, { PureComponent } from 'react';
import * as PropTypes from 'prop-types';
import classNames from 'classnames';

import { BasicModal } from '../../../modal';
import { LessThanIcon } from '../../../icons';

import './BasicSelect.scss';
import { isExists, isFilledArray } from '../../../../utils';

class BasicSelect extends PureComponent {
  static propTypes = {
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    value: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    isInvalid: PropTypes.bool,
    items: PropTypes.arrayOf(PropTypes.shape({})),
    asId: PropTypes.string,
    asName: PropTypes.string,
    style: PropTypes.shape({}),
    fullWidth: PropTypes.bool,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    id: '',
    className: '',
    disabled: false,
    isInvalid: null,
    items: [],
    asId: 'id',
    asName: 'name',
    style: {},
    fullWidth: false,
    onChange: () => {},
  };

  constructor(props) {
    super(props);
    this.state = {
      isOpened: false,
    };
  }

  componentDidMount() {
    this.updateStyles();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.updateStyles();
  }

  updateStyles = () => {
    if (this.optionsContainer && this.input) {
      const { top, left, width, height } = this.input.getBoundingClientRect();
      this.optionsContainer.style.top = `${top + height}px`;
      this.optionsContainer.style.left = `${left}px`;
      this.optionsContainer.style.left = `${left}px`;
      this.optionsContainer.style.width = `${width}px`;

      if (this.selectedItem && this.options) {
        const { offsetHeight } = this.options;
        const { offsetTop: selTop, offsetHeight: selHeight } = this.selectedItem;
        const selBottom = selTop + selHeight;

        if (offsetHeight < selBottom) {
          this.options.scrollTop = selBottom - offsetHeight / 2;
        }
      }
    }
  };

  getInputValue = (items, value) => {
    const { asId, asName } = this.props;
    const item = isFilledArray(items) && isExists(value)
      ? items.find(elm => elm[asId] === value)
      : null;
    const result = item && item[asName];
    return result || '';
  };

  handleChange = (itemValue) => () => {
    const { onChange, name } = this.props;
    const e = { target: { value: itemValue, name } };
    onChange && onChange(e);
  };

  handleToggle = () => {
    const { isOpened } = this.state;
    this.setState({ isOpened: !isOpened });
  };

  getOptions = () => {
    const { items, value, asName, asId } = this.props;

    return isFilledArray(items)
      ? items.map((item) => {
          const { [asId]: itemId, [asName]: itemName } = item;
          const isSelected = itemId === value;
          const _itemClassName = classNames(
            'BasicSelect__item',
            isSelected && 'BasicSelect__item__selected'
          );
          return (
            <li
              key={itemId}
              className={_itemClassName}
              ref={(elm) => { if (isSelected) { this.selectedItem = elm; } }}
              onClick={this.handleChange(itemId)}
            >
              {itemName}
            </li>
          )
        })
      : [];
  };

  render() {
    const {
      id,
      className,
      disabled,
      name,
      items,
      value,
      isInvalid,
      style,
      fullWidth,
    } = this.props;

    const { isOpened } = this.state;

    const _inputValue = this.getInputValue(items, value);

    const _className = classNames(
      'BasicSelect__input',
      className && className,
      isInvalid && 'BasicSelect__input__invalid',
      (isInvalid === false) && 'BasicSelect__input__valid',
      disabled && 'BasicSelect__input__disabled',
    );

    const _iconClassName = classNames(
      'BasicSelect__icon',
      isOpened && 'BasicSelect__icon__opened',
      disabled && 'BasicSelect__icon__disabled',
    );

    let _style = style ? { ...style } : {};
    if (fullWidth) { _style.width = '100%'; }

    return (
      <div
        id={id}
        className="BasicSelect"
        style={_style}
      >
        <input
          className={_className}
          id={`${id}_input`}
          name={name}
          type="text"
          disabled={disabled}
          readOnly={true}
          value={_inputValue}
          ref={(elm) => { this.input = elm; }}
          onChange={() => {}}
          onClick={disabled ? null : this.handleToggle}
        />
        <div className="BasicSelect__icon__container">
          <LessThanIcon
            className={_iconClassName}
            stroke={disabled ? 'rgba(184, 184, 184, 1)' : 'rgba(120, 120, 120, 1)' }
            width="1rem"
            height="1rem"
            onClick={disabled ? null : this.handleToggle}
          />
        </div>
        {
          isOpened && (
            <BasicModal
              onClick={(e) => {
                e.stopPropagation();
                this.setState({ isOpened: false });
              }}
            >
              <div
                className="BasicSelect__options__container"
                ref={(elm) => { this.optionsContainer = elm; }}
              >
                <ul
                  className="BasicSelect__options"
                  ref={(elm) => { this.options = elm; }}
                >
                  { this.getOptions() }
                </ul>
              </div>
            </BasicModal>
          )
        }
      </div>
    );
  };
}

export { BasicSelect };
