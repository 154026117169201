import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import auth from './auth-reducer';
import ui from './ui-reducer';
import errors from './error-reducer';
import data from './data-reducer';

export default history => combineReducers({
  router: connectRouter(history),
  auth,
  ui,
  errors,
  data,
});
