import React from 'react';
import * as PropTypes from 'prop-types';

import './FormPreloader.scss';

const FormPreloader = ({
  isVisible,
  message,
}) => {
  return isVisible && (
    <div className="Form__preloader">
      {message}
    </div>
  )
};

FormPreloader.propTypes = {
  isVisible: PropTypes.bool,
  message: PropTypes.string,
};

FormPreloader.defaultProps = {
  isVisible: false,
  message: 'Подготовка данных...',
};

export { FormPreloader };
