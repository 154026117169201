import React from 'react';
import * as PropTypes from 'prop-types';
import classNames from 'classnames';

import { BasicLabel } from '../../basic';
import { CheckboxField } from '../../complex';
import Fieldset from '../../../Fieldset';
import Legend from '../../../Legend';

import { isFilledString, isInteger } from '../../../../utils';

import styles from './UlcerEtiology.module.scss';
import { ulcerEtiologyOptions } from '../../../../consts';

const UlcerEtiology = ({
  id,
  name,
  disabled,
  value,
  onChange,
  validationMessage,
}) => {
  const isInvalid = isFilledString(validationMessage);

  const getItems = () => {
    return ulcerEtiologyOptions.map((item) => {
      const { id, name: itemName, code } = item;
      const _value = isInteger(value) ? value : 0;
      const itemValue = Boolean(_value & code);
      const itemOnChange = () => {
        const newValue = _value ^ code;
        onChange && onChange({ target: { name, value: newValue } });
      };
      return (
        <CheckboxField
          key={id}
          id={id}
          name={name}
          label={itemName}
          value={itemValue}
          disabled={disabled}
          withValidationMessage={false}
          classes={{ root: styles.item }}
          onChange={itemOnChange}
        />
      )
    });
  };

  const _className = classNames(
    styles.UlcerEtiology,
    isInvalid && styles.invalid,
    (validationMessage === '') && styles.valid,
    disabled && styles.disabled,
  );

  return (
    <Fieldset
      id={id}
      className={_className}
    >
      <Legend className={styles.legend}>Этиология язвы</Legend>
      <div className={styles.content}>
        {getItems()}
      </div>
      <BasicLabel
        key="errorMessage"
        className={styles.errorMessage}
      >
        {validationMessage}
      </BasicLabel>
    </Fieldset>
  )
};

UlcerEtiology.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  value: PropTypes.number,
  onChange: PropTypes.func,
  validationMessage: PropTypes.string,
};

UlcerEtiology.defaultProps = {
  id: '',
  disabled: false,
  value: 0,
  onChange: () => {},
};

export { UlcerEtiology };
