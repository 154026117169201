import { connect } from 'react-redux';

import { AsideList } from './AsideList';
import { filterPageStateUiSelector } from '../../../../store/selectors';
import { acUiSetPageState } from '../../../../store/actions';

const onFilterChange = (pageId, filter) => {
  return acUiSetPageState({ pageId, pageState: { filter } });
};

const mapStateToProps = state => ({
  filter: filterPageStateUiSelector(state),
});

const mapDispatchToProps = {
  onFilterChange,
};

export default connect(mapStateToProps, mapDispatchToProps)(AsideList);
