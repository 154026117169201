import { capitalize } from 'lodash';

function isExists(v) {
  return (v !== undefined) && (v !== null);
}

function isInteger(v) {
  return typeof v === 'number' &&
    isFinite(v) &&
    Math.floor(v) === v;
}

function isFilledString(v) {
  return (typeof v === 'string') && (v.length > 0);
}

function isFilledArray(v) {
  return Array.isArray(v) && (v.length > 0);
}

function capitalizeFirst(v) {
  const arr = v.split('');
  return `${capitalize(arr[0])}${arr.slice(1).join('')}`
}

function addFromLeft(v, length, char = '0') {
  const strV = `${v}`;
  return new Array(length - strV.length)
    .fill(char)
    .concat(strV)
    .join('');
}

export {
  isExists,
  isInteger,
  isFilledString,
  isFilledArray,
  capitalizeFirst,
  addFromLeft,
};
