import React from 'react';
import * as PropTypes from 'prop-types';
import classNames from 'classnames';

import Fieldset from '../../../Fieldset';
import Legend from '../../../Legend';
import { CheckboxField } from '../../complex';

import PRIVILEGES from '../../../../domain/privileges';

import styles from './Privileges.module.scss';
import { isFilledArray } from '../../../../utils';

const Privileges = ({
  id,
  name,
  label,
  disabled,
  value,
  onChange,
}) => {
  const _className = classNames(
    styles.Privileges,
    disabled && styles.PrivilegesDisabled,
  );

  const getPrivilegesList = () => PRIVILEGES
    .map((privilege) => {
      const { id: privilegeId, label, items } = privilege;
      return (
        <Fieldset key={privilegeId} className={styles.group}>
          <Legend className={styles.groupLabel}>{label}</Legend>
          {
            items.map((item) => {
              const { label: itemLabel, code } = item;
              let itemValue = false;
              const key = `${privilegeId}_${code}`;
              const re = new RegExp(`${privilegeId}(\\d+)`);
              let fragmentValue = '';
              let parsed = 0;
              if (value) {
                parsed = re.exec(value);
                if (isFilledArray(parsed) && parsed.length > 1) {
                  fragmentValue = parsed[0];
                  parsed = parseInt(parsed[1], 10);
                  itemValue = !isNaN(parsed)
                    ? Boolean(parsed & code)
                    : itemValue;
                }
              }
              const onItemChange = () => {
                const newParsed = parsed ^ code;
                const _value = value || '';
                const splitValue = fragmentValue
                  ? _value.split(fragmentValue)
                  : [_value, ''];
                const newValue = splitValue.join(`${privilegeId}${newParsed}`);
                onChange && onChange({ target: { name, value: newValue } });
              };

              return (
                <CheckboxField
                  key={key}
                  id={key}
                  name={privilegeId}
                  disabled={disabled}
                  label={itemLabel}
                  value={itemValue}
                  withValidationMessage={false}
                  onChange={onItemChange}
                />
              )
            })
          }
        </Fieldset>
      )
    });

  return (
    <Fieldset
      id={id}
      className={_className}
    >
      <Legend className={styles.title}>{label}</Legend>
      <div className={styles.content}>
        { getPrivilegesList() }
      </div>
    </Fieldset>
  )
};

Privileges.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

Privileges.defaultProps = {
  disabled: false,
  value: 'r0u0l0d0p0',
  onChange: '',
};

export { Privileges };
