import React, { useMemo } from 'react';
import * as PropTypes from 'prop-types';

import {
  BasicTable,
  BasicTHead,
  BasicTBody,
  BasicTr,
  BasicTCell,
} from '../../basic';

import { isFilledArray } from '../../../../utils';

import './SimpleTable.scss';

const SimpleTable = ({
  id,
  classes,
  disabled,
  fullWidth,
  columns,
  rows,
  getTHeadContent,
  getTBodyContent,
}) => {
  const tHeadContent = useMemo(() => {
    if (!isFilledArray(columns)) {
      return null;
    }

    const _theadTrClassName = classes && classes.theadTr;
    const _theadThClassName = classes && classes.theadTh;

    return getTHeadContent
      ? getTHeadContent(columns)
      : (
        <BasicTr className={_theadTrClassName} disabled={disabled} isHeader={true}>
          {
            columns.map((column) => {
              const { id, label } = column;
              return (
                <BasicTCell key={id} id={id} className={_theadThClassName} isHeader={true} disabled={disabled}>
                  {label}
                </BasicTCell>
              )
            })
          }
        </BasicTr>
      );
  }, [getTHeadContent, columns, disabled, classes]);

  const tBodyContent = useMemo(() => {
    if (getTBodyContent) {
      return getTBodyContent(columns, rows);
    }

    const _tbodyTrClassName = classes && classes.tbodyTr;
    const _tbodyTdClassName = classes && classes.tbodyTd;

    return isFilledArray(columns) && isFilledArray(rows)
      ? rows.map((row) => {
        const { id: rowId } = row;
        return (
          <BasicTr key={rowId} className={_tbodyTrClassName} disabled={disabled}>
            {
              columns.map((col) => {
                const { id: colId } = col;
                return (
                  <BasicTCell key={colId} className={_tbodyTdClassName} disabled={disabled}>
                    {row[colId]}
                  </BasicTCell>
                )
              })
            }
          </BasicTr>
        )
      })
      : null;
  }, [getTBodyContent, columns, rows, disabled, classes]);

  const _tableClassName = classes && classes.root;

  return (
    <BasicTable
      id={id}
      className={_tableClassName}
      disabled={disabled}
      fullWidth={fullWidth}
    >
      {
        isFilledArray(columns) && (
          <BasicTHead disabled={disabled}>
            {tHeadContent}
          </BasicTHead>
        )
      }
      <BasicTBody disabled={disabled}>
        {tBodyContent}
      </BasicTBody>
    </BasicTable>
  );
};

SimpleTable.propTypes = {
  id: PropTypes.string,
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  rows: PropTypes.arrayOf(PropTypes.shape({})),
  getTHeadContent: PropTypes.func,
  getTBodyContent: PropTypes.func,
  fullWidth: PropTypes.bool,
};

SimpleTable.defaultProps = {
  id: '',
  rows: null,
  getTHeadContent: null,
  getTBodyContent: null,
  fullWidth: false,
};

export { SimpleTable };
