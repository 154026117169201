import React, { useState, useCallback } from 'react';
import * as PropTypes from 'prop-types';
import classNames from 'classnames';

import './Calendar.scss';
import { getDateObject, getDaysFromMonday, getMonthStringRu } from '../BasicDate.utils';

const MODE = {
  DAYS: 'DAYS',
  MONTHS: 'MONTHS',
  YEARS: 'YEARS',
};

const Calendar = ({
  className,
  value,
  onChange,
  onClose,
}) => {
  const [mode, setMode] = useState(MODE.DAYS);

  const dateObj = useCallback(getDateObject, [value])(value);

  const getDays = useCallback(() => {
    const handleOnDateClick = (dt) => {
      onChange && onChange(dt);
      onClose && onClose();
    };

    const { year, month, date } = dateObj;
    const valueTime = new Date(year, month, date).getTime();
    const firstMonthDt = new Date(year, month, 1);
    const daysBefore = getDaysFromMonday(firstMonthDt.getDay());
    const first = firstMonthDt.getTime() - daysBefore * 86400000;
    const weeks = [];
    for (let w = 0; w < 6; w++) {
      const days = [];
      for (let d = 0; d < 7; d++) {
        const time = first + (w * 7 + d) * 86400000;
        const dt = new Date(time);
        const isSelected = time === valueTime;
        days.push(
          <span
            key={`${w}-${d}`}
            className={
              classNames('Calendar__day', isSelected && 'Calendar__day__selected')
            }
            onClick={() => {
              handleOnDateClick(dt);
            }}
          >
            {dt.getDate()}
          </span>
        );
      }
      weeks.push(
        <div
          key={w}
          className="Calendar__week"
        >
          {days}
        </div>
      );
    }
    return weeks;
  }, [dateObj, onChange, onClose]);

  const getMonths = useCallback(() => {
    const months = [];
    const { date, month, year } = dateObj;
    for (let m = 0; m < 12; m++) {
      const monthValue = getMonthStringRu(m);
      const isSelected = m === month;
      months.push(
        <div
          key={m}
          className={classNames(
            'Calendar__month',
            isSelected && 'Calendar__month__selected',
          )}
          onClick={() => {
            let lastMothDate = new Date(year, m + 1, 0, 0, 0, 0).getDate();
            const newDate = lastMothDate < date ? lastMothDate : date;
            onChange && onChange(new Date(year, m, newDate, 0, 0, 0));
            setMode(MODE.DAYS);
          }}
        >
          {monthValue}
        </div>
      );
    }
    return months;
  }, [dateObj, onChange]);

  const getYears = useCallback(() => {
    const years = [];
    const { date, month, year } = dateObj;
    for (let y = 1900; y < 2101; y++) {
      const isSelected = y === year;
      years.push(
        <div
          key={y}
          className={classNames(
            'Calendar__year',
            isSelected && 'Calendar__year__selected',
          )}
          onClick={() => {
            const lastMonthDate = new Date(y, month + 1, 0, 0, 0, 0).getDate();
            const newDate = lastMonthDate < date ? lastMonthDate : date;
            onChange && onChange(new Date(y, month, newDate));
            setMode(MODE.DAYS);
          }}
        >
          {y}
        </div>
      );
    }
    return years;
  }, [dateObj, onChange]);

  const _className = classNames(
    'Calendar',
    className && className,
  );

  const _contentClassName = classNames(
    'Calendar__content',
    `Calendar__content__${mode.toLowerCase()}`
  );

  /*
  * {' : '}
   <span key="hours">
   {dateObj.hh}
   </span>
   <span key="minutes">
   {dateObj.mm}
   </span>
   <span key="seconds">
   {dateObj.ss}
   </span>
  * */

  return (
    <div
      className={_className}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div key="header" className="Calendar__header">
        <span
          key="date"
          className={classNames(
            'Calendar__header__control',
            (mode === MODE.DAYS) && 'Calendar__header__control__selected'
          )}
          onClick={() => { setMode(MODE.DAYS) }}
        >
          {dateObj.date}
        </span>
        <span
          key="month"
          className={classNames(
            'Calendar__header__control',
            (mode === MODE.MONTHS) && 'Calendar__header__control__selected'
          )}
          onClick={() => { setMode(MODE.MONTHS) }}
        >
          {getMonthStringRu(dateObj.month, 1)}
        </span>
        <span
          key="year"
          className={classNames(
            'Calendar__header__control',
            (mode === MODE.YEARS) && 'Calendar__header__control__selected'
          )}
          onClick={() => { setMode(MODE.YEARS) }}
        >
          {dateObj.year}
        </span>
      </div>
      <div key="body" className={_contentClassName}>
        { mode === MODE.DAYS && getDays() }
        { mode === MODE.MONTHS && getMonths() }
        { mode === MODE.YEARS && getYears() }
      </div>
    </div>
  )
};

Calendar.propTypes = {
  className: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.objectOf(Date),
  ]),
  onChange: PropTypes.func,
  onClose: PropTypes.func,
};

Calendar.defaultProps = {
  className: '',
  value: '',
  onChange: () => {},
  onClose: () => {},
};

export { Calendar };
