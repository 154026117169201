import { isExists } from '../../../../utils';

export function getDateObject(value) {
  let _value;
  let day = '';
  let date = '';
  let month = '';
  let year = '';
  let hh = '';
  let mm = '';
  let ss = '';
  if (!value) {
    _value = new Date();
    _value.setHours(0);
    _value.setMinutes(0);
    _value.setSeconds(0);
  } else if (value instanceof Date) {
    _value = value;
  } else {
    _value = new Date(value);
  }
  date = _value.getDate();
  day = _value.getDay();
  month = _value.getMonth();
  year = _value.getFullYear();
  hh = _value.getHours();
  mm = _value.getMinutes();
  ss = _value.getSeconds();
  return{ date, month, year, day, hh, mm, ss };
}

export function getMonthStringRu(month, form = 0) {
  const _month = parseInt(month);
  return isExists(_month) && (_month >= 0 && _month <=11)
    ? [
        ['Январь', 'Января'],
        ['Февраль', 'Февраля'],
        ['Март', 'Марта'],
        ['Апрель', 'Апреля'],
        ['Май', 'Мая'],
        ['Июнь', 'Июня'],
        ['Июль', 'Июля'],
        ['Август', 'Августа'],
        ['Сентябрь', 'Сентября'],
        ['Октябрь', 'Октября'],
        ['Ноябрь', 'Ноября'],
        ['Декабрь', 'Декабря'],
      ][month][form]
    : '';
}

export function getDaysFromMonday(day) {
  return day === 0 ? 6 : day - 1;
}

export function getDaysToSunday(day) {
  return day > 0 ? 7 - day : day;
}
